.knowledge-section {
  &__row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2.5rem;
  }

  &__img-col {
    display: flex;

    flex: 0 0 100%;
    max-width: 100%;

    @include media($md) {
      flex: 0 0 48.4%;
      max-width: 48.4%;

      padding-left: 4rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    flex: 0 0 100%;
    max-width: 100%;

    @include media($md) {
      flex: 0 0 51.6%;
      max-width: 51.6%;

      padding-right: 4rem;
    }
  }

  &__content {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }

  &__text {
    font-weight: 600;
    margin-bottom: 2.5rem;
  }

  &__btn {
    align-self: flex-start;
  }

  &__img-wrap {
    width: 100%;
    margin: auto 0;
  }

  &__img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 3.2rem;
  }
}
