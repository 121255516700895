.breeds-section {
  $self: &;

  position: relative;
  margin-bottom: 5rem;
  
  @include media($md) {
    margin-bottom: 8rem;
  }

  &__heading {
    margin-bottom: 3rem;
    
    @include media($md) {
      margin-bottom: 5.5rem;
    }
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }

  &__search {
    width: 100%;
    position: relative;

    .search {
      &__btn {
        background: none;
        padding: 0;
        pointer-events: none;
      }

      &__icon {
        width: 1.6rem;
        height: 1.6rem;
        color: #50585E;
      }
    }

    &.is-suggested {
      .search__body {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__controls {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    flex: 1;

    @include media(360px, false) {
      flex-direction: column;
    }
    
    @include media($md) {
      margin-left: auto;
    }

     .filter-dropdown__dropdown {
       @include media(360px) {
         min-width: 15.6rem;
       }

       @include media($md) {
         min-width: 22rem;
       }
     }
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
  }

  &__catalog {
    width: 100%;

    @include media($md) {
      flex: 0 0 74.2%;
      max-width: 74.2%;
    }

    .ais-Hits-list {
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;

      @include media($md) {
        gap: 2.5rem;
      }

      .ais-Hits-item {
        flex: 0 0 calc((100% / var(--flex-items)) - (((var(--flex-items) - 1) / var(--flex-items)) * var(--flex-gap)));
        max-width: calc((100% / var(--flex-items)) - (((var(--flex-items) - 1) / var(--flex-items)) * var(--flex-gap)));
        --flex-gap: 1.5rem;
        --flex-items: 2;
        margin-bottom: 0;

        @include media($md) {
          --flex-gap: 2.5rem;
          --flex-items: 3;
        }

        .catalog__card {
          flex: none;
          max-width: 100%;
        }
      }
    }
  }

  &__sidebar {
    width: 100%;
    display: none;

    @include media($md) {
      flex: 1;
      min-width: 0;
      display: block;
    }
  }

  &__dropdown-wrap {
    position: relative;
    width: 100%;
    flex: 1;
    text-align: end;

    &.is-opened {
      .filter-dropdown__icon {
        transform: rotate(-180deg);
      }

      .filter-dropdown__dropdown {
        border-color: $primary-color;
      }
    }

    @include media(360px) {
      width: auto;
    }
  }

  &__bottom {
    margin-top: 1.5rem;
    text-align: end;
    
    @include media($md) {
      margin-top: 2.5rem;
    }

    .breeds-section__dropdown-wrap {
      @include media($md) {
        display: inline-block;
      }
    }

    .filter-dropdown__link {
      @include media($md) {
        justify-content: flex-end;
      }
    }
  }

  .search {
    @include media($md) {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
    }
  }

  .blog-section__search-wrap {
    position: relative;
  }

  .blog-section__result {
    font-family: var(--codec-pro-font), Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
  }
}

.filter-card {
  padding: 1.5rem;
  border: 1px solid #F0F2F7;
  border-radius: .8rem;

  &__title {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 2.5rem;
    position: relative;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      background-color: #434261;
      position: absolute;
      transform: translateY(-50%);
      border-radius: 0.1rem;
      top: 50%;
      width: 1.2rem;
      height: 0.15rem;
      right: 0;
      transition: all 0.3s linear;
    }
  }

  &.is-active {
    .filter-card__title {
      &:after {
        transform: rotate(90deg) translateX(-0.1rem);
      }
    }
  }

  &__body {
    margin-top: 1.5rem;
  }

  .cart-count {
    background-color: #F0F2F7;
    color: #434261;
  }
}

.filter-alphabet {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: .5rem;

  input:checked + label {
    background-color: #F0F2F7;
  }

  label {
    border-radius: .4rem;
    transition: all 0.3s linear;
    border: 1px solid transparent;
  }

  &__field {
    position: relative;

    &:before {
      content: '';
      padding-top: 100%;
      display: block;
    }

    &:hover {
      label {
        border: 1px solid #434261;
        background-color: $white;
      }

      input:checked + label {
        background-color: $white;
      }
    }

    label {
      text-transform: uppercase;
      font-size: 1.4rem;
      cursor: pointer;
      font-weight: 600;
      line-height: 1.5;
      width: 100%;
      display: flex;
      color: #434261;
      justify-content: center;
      align-items: center;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.is-selected {
      label {
        background-color: #F0F2F7;
      }

      &:hover {
        label {
          background-color: $white;
        }
      }
    }

    &.is-disabled {
      opacity: 0.5;
      pointer-events: none;

      &:hover {
        border-color: transparent;
      }

      label {
        cursor: default;
      }
    }
  }
}

.filter-select {
  &__field {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.search-filter {
  display: flex;
  gap: 1.5rem;
  position: relative;

  &__result {
    font-family: var(--codec-pro-font), Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
    margin-right: auto;
    flex: none;
    padding-top: .8rem;
  }

  &__btn,
  .ais-ClearRefinements {
    display: none;

    @include media($md) {
      display: block;
    }
  }

  &__btn-trigger {
    padding: .7rem;
    position: relative;

    @include media($md) {
      display: none;
    }
  }

  &__icon-trigger {
    width: 1.6rem;
    height: 1.6rem;
  }

  &__cart-count {
    position: absolute;
    top: -1rem;
    right: -1rem;
  }

  &__body {
    z-index: 102;
    position: fixed;
    left: 0;
    right: 0;
    top: 100%;
    transition: transform .3s ease-in-out;

    &.is-active {
      transform: translate3d(0, -100%, 0);
    }

    @include media($md) {
      display: none;
    }
  }

  &__tags-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-self: center;
  }

  &__tag {
    display: none;

    @include media($md) {
      display: inline-flex;
    }
  }
}

.mob-filter {
  background-color: #fff;
  width: 100%;
  box-shadow: 0 0 5rem 0 #00000008;
  border-radius: 1.6rem 1.6rem 0 0;

  .search-filter__tag {
    display: inline-flex;
  }

  &__content {
    max-height: calc(95vh - 9rem);
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: inherit;
  }

  .filter-card {
    border-radius: 0;
    border-left: none;
    border-right: none;

    &:first-child {
      border-top: none;
    }
  }

  &__top {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 3;
    border-radius: inherit;
    border-bottom: 1px solid #F0F2F7;
  }

  &__tags-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
  }

  &__title {
    font-family: var(--codec-pro-font), Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.3;
  }

  &__btn-clear {
    font-size: 1.3rem;
    padding: .7rem 2rem;
  }

  .filter-card__title {
    font-size: 1.3rem;
  }

  &__bottom {
    padding: 1.5rem;
    box-shadow: 0 0 4rem 0 #00000026;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
  }

  &__btn-view {
    width: 100%;
  }
}

.tag {
  background-color: #F0F2F7;
  font-size: 1.2rem;
  line-height: 1.5;
  padding: .4rem 1.2rem;
  border-radius: 1.2rem;
  align-items: center;

  @include media($md) {
    display: inline-flex;
  }

  &__icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: .7rem;
    cursor: pointer;
  }
}