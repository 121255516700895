.accordion {
  $self: &;

  position: relative;

  &__title {
    cursor: pointer;
    position: relative;
    user-select: none;
    font-weight: 600;
    font-size: 1.4rem;
    padding: 1rem 5rem 1rem 0;
    border-top: 1px solid $air-blue;
    min-height: 5rem;
    display: flex;
    align-items: center;
    margin: 0;

    &.is-active {
      #{$self}__title {
        &::before {
          background-color: $dark-accent--default;
        }

        &::after {
          background-color: $dark-accent--default;
          transform: translateX(-.65rem) rotate(90deg);
        }
      }

      #{$self}__body {
        display: block;
      }
    }

    @include media($md) {
      padding: 1rem 8rem 1rem 0;
      min-height: 5.6rem;
    }

    &::after,
    &::before {
      content: "";
      top: 2.1rem;
      position: absolute;
      background-color: $dark-accent--default;
      transition: all 0.3s linear;
      right: 1rem;
    }

    &::after {
      width: .2rem;
      height: 1.5rem;
      transform: translateX(-.65rem);
    }

    &::before {
      width: 1.5rem;
      height: .2rem;
      transform: translateY(.65rem);
    }
  }
  .show{
    margin-bottom: 1.4rem;
    height: auto;
  }

  &__body {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
    padding-right: 1.5rem;

    > * {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  p {
    font-size: 1.4rem;
    color: $gray;
  }

  h2,
  h3,
  h4 {
    margin-top: 0;
  }

  &.is-active {
    #{$self}__title {
      &::before {
        background-color: $dark-accent--default;
      }

      &::after {
        background-color: $dark-accent--default;
        transform: translateX(-.65rem) rotate(90deg);
      }
    }

    #{$self}__body {
      display: block;
    }
  }

}