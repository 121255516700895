.g-landing-intro {
  $self: &;

  background: #F0F2F7;

  &__main {
    display: grid;
    gap: 3rem;
    align-items: start;
    padding: 0 0 2.5rem;

    @include media($md) {
      padding: 0 0 5rem;
      grid-template-columns: minmax(0, 1.3fr) minmax(0, 1fr);
    }

    @include media($xxl) {
      gap: 7rem;
    }
  }

  &__info {
    text-align: center;

    @include media($md) {
      text-align: left;
    }

    #{$self}__preview {
      display: block;
      margin-top: 2.5rem;

      @include media($md) {
        display: none;
      }
    }
  }

  &__counter {
    margin-bottom: 1.5rem;
  }

  &__heading {
    margin-bottom: 1rem;
    line-height: 1.1;

    font-weight: 600;
    color: #605BFF;
    font-size: 3.2rem;
    font-family: var(--codec-pro-font);

    @include media($md) {
      font-size: 4.6rem;
    }

    @include media($xl) {
      font-size: 6.4rem;
    }
  }

  &__desc {
    font-weight: 300;
    font-size: 1.6rem;
    color: #50585E;

    @include media($md) {
      font-size: 1.8rem;
    }

    @include media($xl) {
      font-size: 2.3rem;
    }
  }

  &__form {
    margin-top: 2rem;

    @include media($md) {
      margin-top: 5rem;
      max-width: 49rem;
    }
  }

  &__preview {
    display: none;

    @include media($md) {
      position: sticky;
      top: 13rem;
      display: block;
    }

    &-img {
      width: 100%;
      height: auto;
      display: block;
      border-radius: 2.4rem;
    }
  }
}

.event-counter {
  box-shadow: 0 0 5rem rgba(0, 0, 0, 0.03);
  background: #F8F9FB;
  border-radius: .8rem;
  padding: .8rem 2rem;
  display: inline-flex;
  line-height: 1.15;
  text-align: center;

  @include media($md) {
    padding: 1.2rem 2.5rem;
  }

  &__item {
    position: relative;

    &:not(:last-child) {
      padding-right: 3rem;

      @include media($md) {
        padding-right: 4rem;
      }

      &::before {
        content: '';
        width: 1px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 2rem;
        background: linear-gradient(to bottom, transparent, rgba(67, 66, 97, 0.15) 50%, transparent)
      }
    }
  }

  &__value {
    font-size: 1.6rem;
    font-weight: 500;
    color: #30414C;
    font-family: var(--codec-pro-font);

    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__measure {
    font-weight: 300;
    font-size: 8px;
    color: #30414C;
  }
}

.event-form {
  box-shadow: 0 0 5rem rgba(0, 0, 0, 0.03);
  border-radius: 1.6rem;
  border: 1px solid #F0F2F7;
  background: #fff;
  padding: 2.4rem;

  &__field {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__err {
    color: #f00;
    font-size: 1.3rem;
    font-weight: 500;
    display: block;
    padding-left: 2.5rem;
    padding-top: .5rem;
    text-align: left;
  }

  &__caption {
    font-weight: bold;
    font-size: 1.4rem;
    color: #222;
  }

  &__radio-buttons {
    margin: 0 0 2rem;
  }

  &__radio-button {
    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }

  &__bottom {
    margin-top: 1.5rem;
  }

  &__btn {
    height: 60px;
    border-radius: 1.2rem;
    font-size: 1.6rem;
  }

  &__privacy {
    margin-top: 2.5rem;
    font-size: 1.2rem;
    color: #30414C;
    opacity: 0.7;

    > *:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  .input,
  .PhoneInputInput {
    border-color: #F0F2F7;
    border-radius: .8rem;
    box-shadow: none;
    font-size: 1.4rem;
    color: #50585E;
    height: 60px;

    &:hover {
      border-color: #e6e6e6;
    }

    &:focus {
      border-color: #605BFF;
    }

    &::placeholder {
      opacity: .7;
    }
  }
}

.phone-control {
  position: relative;

  &__location {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    &-header {
      display: flex;
      align-items: center;
      gap: 1rem;
      height: 100%;
      padding: 0 1.5rem;
      cursor: pointer;
    }
  }

  &__input,
  .PhoneInputInput {
    padding-left: 6.5rem;
  }

  .PhoneInputCountry {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    padding: 0 1.5rem;
  }

  .PhoneInputCountryIcon {
    width: 1.6rem;
    height: 1.2rem;
    border: none;
    box-shadow: none;
  }

  .PhoneInputCountrySelectArrow {
    margin-left: 1rem;
  }

  .PhoneInputCountrySelectArrow {
    color: #50585E;
    opacity: 1;
    margin-top: -.3rem;
  }
}

.g-landing-informer {
  &__main {
    padding: 1.5rem;
    background: #F8F9FB;
    border-radius: 1.6rem;
    gap: 5rem;
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    @include media($md) {
      padding: 6rem 5rem;
      gap: 4rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media($xl) {
      gap: 8rem;
    }
  }

  &__info {
    align-self: center;
  }

  &__heading {
    font-weight: 500;
    font-size: 2.2rem;
    color: #30414C;
    font-family: var(--codec-pro-font);
    margin-bottom: 1rem;
    line-height: 1.1;

    @include media($md) {
      font-size: 3.8rem;
    }

    @include media($xl) {
      font-size: 4.4rem;
    }
  }

  &__content {
    margin-bottom: 6rem;
    width: 100%;
    padding: 2rem 2rem 0 2rem;

    @include media($md) {
      max-width: 48rem;
      margin-left: auto;
      padding: 0;
    }
  }

  &__desc {
    font-weight: 300;
    font-size: 1.6rem;
    color: #50585E;
    margin-bottom: 2.5rem;

    @include media($md) {
      font-size: 1.9rem;
    }

    > *:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    ul {
      line-height: 1.1;
      font-size: 1.6rem;
      color: #50585E;
      font-weight: normal;

      li {
        position: relative;
        padding-left: 2.6rem;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        &::before {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.1592 0.752603C13.572 0.288222 14.283 0.246394 14.7474 0.659177C15.2118 1.07196 15.2536 1.78304 14.8408 2.24742L6.84085 11.2474C6.62736 11.4876 6.32135 11.625 6.00001 11.625C5.67867 11.625 5.37266 11.4876 5.15918 11.2474L1.15918 6.74742C0.746394 6.28304 0.788222 5.57196 1.2526 5.15918C1.71698 4.74639 2.42806 4.78822 2.84085 5.2526L6.00001 8.80666L13.1592 0.752603ZM14.2491 1.21973C14.0944 1.08214 13.8573 1.09608 13.7197 1.25088L6.28029 9.62025C6.20913 9.70031 6.10713 9.74611 6.00001 9.74611C5.8929 9.74611 5.7909 9.70031 5.71973 9.62025L2.28029 5.75088C2.1427 5.59608 1.90567 5.58214 1.75088 5.71973C1.59608 5.85733 1.58214 6.09435 1.71973 6.24915L5.71973 10.7491C5.7909 10.8292 5.8929 10.875 6.00001 10.875C6.10713 10.875 6.20913 10.8292 6.28029 10.7491L14.2803 1.74915C14.4179 1.59436 14.4039 1.35733 14.2491 1.21973Z' fill='%2343BF84'/%3E%3Cpath d='M14.4983 0.939447C14.8079 1.21464 14.8357 1.68869 14.5606 1.99828L6.56056 10.9983C6.41824 11.1584 6.21423 11.25 6 11.25C5.78578 11.25 5.58177 11.1584 5.43945 10.9983L1.43945 6.49828C1.16426 6.18869 1.19214 5.71464 1.50173 5.43945C1.81132 5.16426 2.28537 5.19214 2.56056 5.50173L6 9.3711L13.4394 1.00173C13.7146 0.692144 14.1887 0.664259 14.4983 0.939447Z' fill='%2343BF84'/%3E%3C/svg%3E%0A");
          background-color: transparent;
          border-radius: 0;
          width: 1.6rem;
          height: 1.6rem;
          background-size: contain;
          position: absolute;
          left: 0;
          top: .2rem;
        }
      }
    }
  }

  &__img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    border-radius: 2.4rem;
  }
}
