.payment-terms-modal {
  &__heading {
    font-size: 2.7rem;
    font-weight: 600;
    line-height: 1.2;
    max-width: 100%;
    margin-bottom: 1.5rem;
    padding-right: 3rem;

    @include media($xs) {
      padding-right: 0;
    }
    
    @include media($md) {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
  }

  &__subtitle {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    
    @include media($md) {
      font-size: 1.8rem;
    }
  }

  &__list {
    color: #707070;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    row-gap: 2rem;
  }

  &__close {
    padding: 0.5rem 0;
    align-self: center;
  }

  &__help-text {
    font-size: 1.1rem;
    font-weight: 300;
    margin-bottom: 2rem;

    a {
      text-decoration: underline;
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.5rem 0;
    background-color: $air-blue;
    
    @include media($md) {
      padding: 5rem 0;
    }

    >* {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
  
  &__main-icon {
    width: 8.8rem;
    height: 2.1rem;
    flex: none;
  }

  &__affirm-text {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
  }

  &__affirm-icon {
    width: 4.3rem;
    height: 2.1rem;
    flex: none;
    margin-left: 0.5rem;
  }
}

.payment-terms {
  border-radius: .5rem;
  padding: 1rem;
  background-color: #F5F5F5;
  color: #707070;

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  &__payment {
    display: flex;
    align-items: center;
    font-size: 1.4rem;

    @include media($md) {
      font-size: 1.6rem;
    }

    &:last-child {
      font-size: 1.2rem;
      
      @include media($md) {
        font-size: 1.4rem;
      }
    }

    strong {
      font-size: 1.6rem;
      
      @include media($md) {
        font-size: 1.8rem;
      }
    }
  }

  &__body  {
    display: flex;
    justify-content: space-around;
    gap: 2rem;
  }

  &__detail {
    text-align: center;
  }

  &__title {
    font-size: 1.2rem;
  }

  &__value {
    font-size: 1.5rem;
    
    @include media($md) {
      font-size: 1.7rem;
    }
  }
}

shop-pay-button {
  width: 100%;
}

