.search {
  display: flex;
  align-items: center;
  padding: 0.6rem 1.5rem;
  border-radius: 1.2rem;
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  border: 1px solid $air-blue;
  box-shadow: 0px -0.1rem 0 0 rgba(47, 43, 67, 0.10) inset, 0 0px 0.3rem 0 rgba(47, 43, 67, 0.10);
  transition: all 0.3s linear;
  
  &:focus-within,
  &:hover {
    border-color: #ccc;
  }
  
  @include media($md) {
    border-radius: 1.2rem;
    // padding: 1.3rem 1.5rem;
    margin-bottom: 0;
    position: relative;
  }

  &__input,
  .ais-SearchBox-input {
    width: 100%;
    font-size: 1.4rem;
    padding: 0 1.2rem;

    &::placeholder {
      color: $black;
      opacity: .4;
    }
  }

  &__btn,
  .ais-SearchBox-submit {
    padding: 0.7rem;
    background-color: $primary-color;
    border-radius: 0.8rem;

    display: inline-flex;
    
    @include media($md) {
      padding: 0.9rem;
      border-radius: 1.2rem;
    }

    &:hover {
      .search__icon,
      .ais-SearchBox-submitIcon {
        transform: scale3d(1.1, 1.1, 1);
      }
    }

    .search__icon,
    .ais-SearchBox-submitIcon {
      color: $white;
      fill: $white;
      width: 1.6rem;
      height: 1.6rem;
      
      @include media($md) {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }

  &__icon,
  .ais-SearchBox-submitIcon {
    width: 1.6rem;
    height: 1.6rem;
    transition: all 0.2s linear;
    flex: none;
    color: $gray;
  }

  &__body {
    position: absolute;
    top: calc(100% + 1.2rem);
    left: 0;
    background: #FFFFFF;
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.05);
    padding: 1rem 2rem;
    width: 100%;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s linear;
    max-height: 38rem;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__link {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5;
    color: #30414C;
  }
}

.autocomplete-dropdown {
  position: absolute;
  top: calc(100% + 1.2rem);
  left: 0;
  background: #FFFFFF;
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.05);
  border: 1px solid #F0F2F7;
  z-index: 2;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  font-family: var(--inter-font), Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 1.5rem .5rem 1.5rem 0;

  &__body {
    max-height: 36rem;
    overflow-y: auto;
    padding: 0 .5rem;
  }

  &__item {
    cursor: pointer;
    padding: .5rem 2rem;
  }
}

.ais-SearchBox-form {
  display: flex;
  align-items: center;
}

.ais-SearchBox {
  width: 100%;
}

.ais-SearchBox-reset {
  display: none;
}

.ais-Pagination .ais-Pagination-list--noRefinement {
  display: none;
}