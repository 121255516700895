.file-uploader {
  &__main {
    background: #fff;
    border-radius: 1.2rem;
    padding: 3rem;
    border: .2rem dashed #605BFF;
    cursor: pointer;
    display: block;

    //&.is-active {
    //  border-color: $tertiary-color;
    //}
  }

  &__info {
    text-align: center;
  }

  &__icon {
    margin-bottom: 1rem;
  }

  &__desc {
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: .5rem;
    color: var(--primary-color);
  }
  
  &__info {
    font-size: 1.2rem;
    color: #8B9DA9;
  }

  input[type="file"] {
    display: none;
  }
}

.file-list {
  background: #F8F9FB;
  border-radius: 1.2rem;
  padding: 1.6rem;

  @include media($md) {
    padding: 2.4rem;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

.file-item {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  &__info {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    min-width: 0;
  }

  &__img-wrap {
    width: 4.8rem;
    height: 4.8rem;
    flex: none;

    @include media($md) {
      width: 7.2rem;
      height: 7.2rem;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  &__details {
    min-width: 0;
  }

  &__name {
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--primary-color);
    margin-bottom: .3rem;

    @include trim;
  }

  &__size {
    font-size: 1.2rem;
    color: #8B9DA9;
  }

  &__actions {
    margin-left: auto;
  }

  &__remove {
    display: flex;
    width: 4rem;
    height: 4rem;
    border: 1px solid rgba(67, 66, 97, 0.15);
    border-radius: 12px;
    transition: border-color .3s ease-in-out;

    &:hover {
      border-color: rgba(67, 66, 97, 0.4);
    }

    &-icon {
      margin: auto;
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}