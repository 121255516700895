.conversion-subscribe {
  $self: &;

  background-color: #F8F9FB;
  border-radius: 2.4rem;
  overflow: hidden;

  &__img-wrap {
    flex: none;
  }

  &__img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  &__title {
    font-family: var(--codec-pro-font), Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.3;
    color: $black;
    margin-bottom: .5rem;

    @include media($md) {
      font-size: 2.3rem;
    }
  }

  &__desc {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
  }

  &__form {
    &.is-validated {
      #{$self}__input {
        padding-right: 3rem;

        @include media($md) {
          padding-right: 3.5rem;
        }
      }

      .icon-check {
        display: block;
      }
    }

    &.is-submitting {
      opacity: .3;
      pointer-events: none;

      #{$self}__input {
        background-color: $air-blue;
        padding-right: 3rem;

        @include media($md) {
          padding-right: 3.5rem;
        }

        &::placeholder {
          opacity: .7;
        }
      }

      .icon-check {
        display: block;
        color: #8B9DA9;
      }

      #{$self}__btn {
        min-width: 9.5rem;
      }

      #{$self}__btn-text {
        display: none;
      }

      #{$self}__loadnig {
        display: block;
      }
    }

    &.is-submitted {
      #{$self}__input {
        border-color: rgb(#43BF84, 0.5);
        background-color: rgb(#43BF84, 0.05);
        padding-right: 3rem;

        @include media($md) {
          padding-right: 3.5rem;
        }
      }

      .icon-check {
        display: block;
      }

      #{$self}__btn {
        background-color: #43BF84;
        
        &:hover,
        &:focus {
          border-color: #43BF84;
        }
      }
    }

    &.is-error {
      #{$self}__input {
        border-color: rgb(#FF395A, 0.5);
        background-color: rgb(#FF395A, 0.05);
        padding-right: 3rem;

        @include media($md) {
          padding-right: 3.5rem;
        }
      }

      .icon-error {
        display: block;
      }

      #{$self}__status {
        display: block;

        @include media($md) {
          text-align: left;
        }
      }
    }
  }

  &__input-wrap {
    width: 100%;
    position: relative;
  }

  &__input {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &::placeholder {
      font-size: 1.4rem;
      line-height: 1.5;
      color: $black;
      opacity: .4;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    display: none;

    &.icon-check {
      width: 1.6rem;
      height: 1.3rem;
      color: $success;
    }

    &.icon-error {
      width: 1.4rem;
      height: 1.4ren;
      color: $error;

      @include media($md) {
        width: 1.7rem;
        height: 1.7ren;
      }
    }
  }

  &__btn {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &__loadnig {
    display: none;
  }

  &__status {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 1rem;
    color: $gray;
    display: none;

    @include media($md) {
      padding-left: 1.5rem;
    }
  }

  &.has-icon {
    position: relative;
    padding: 2.5rem;

    @include media($md) {
      padding: 5rem;
      padding-left: 15.2rem;
    }

    #{$self}__img-wrap {
      width: 4.8rem;
      height: 4.8rem;
      padding: .9rem;

      @include media($md, false) {
        margin: 0 auto;
      }

      @include media($md) {
        width: 7.2rem;
        height: 7.2rem;
        padding: 1.3rem;
        position: absolute;
        top: 50%;
        left: 5rem;
        transform: translate3d(0, -50%, 0);
      }
    }

    #{$self}__content {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 2.5rem;

      @include media($md) {
        flex-direction: row;
        align-items: center;
        text-align: start;
      }
    }

    #{$self}__form {
      width: 100%;

      @include media($md) {
        max-width: 54rem;
        margin-left: auto;
      }
    }

    #{$self}__field {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include media($md) {
        flex-direction: row;
      }
    }

    #{$self}__btn {
      margin-top: 1rem;

      @include media($md) {
        margin-left: 1rem;
        margin-top: 0;
      }
    }

    &.is-column {
      padding-left: 2.5rem;

      @include media($md) {
        padding-left: 5rem;
      }

      #{$self}__img-wrap {
        margin: 0 auto 1.5rem;
        position: static;
        transform: translate3d(0, 0, 0);
      }

      #{$self}__content {
        text-align: center;
        flex-direction: column;
        gap: 0;
      }

      #{$self}__form {
        margin-top: 3rem;
        max-width: 100%;
        margin-left: 0;
      }
    }
  }

  &.has-image {
    display: flex;
    flex-direction: column;

    @include media($md) {
      flex-direction: row;
    }

    #{$self}__img-wrap {
      width: 100%;

      @include media($md, false) {
        order: 1;
      }

      @include media($md) {
        max-width: 38.5rem;
      }
    }

    #{$self}__content {
      width: 100%;
      padding: 2.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      @include media($md) {
        padding: 5rem;
      }
    }

    #{$self}__form {
      max-width: 50.5rem;
      margin: 3rem auto 0;
      width: 100%;
    }

    #{$self}__field {
      display: flex;
      flex-direction: column;

      @include media($md) {
        flex-direction: row;
      }
    }

    #{$self}__btn {
      margin-top: 1rem;

      @include media($md) {
        margin-left: 1rem;
        margin-top: 0;
      }
    }

    &.is-column {
      #{$self}__img-wrap {
        @include media($md, false) {
          aspect-ratio: 1 / 1.3;
          max-height: 37rem;
        }

        @include media($md) {
          max-width: 28rem;
          width: 100%;
        }
      }

      #{$self}__field {
        flex-direction: column;
      }

      #{$self}__btn {
        margin-top: 1rem;
        margin-left: 0;
      }
    }
  }

  &.is-bg-blue {
    background-color: #D0E8FF;
  }
}