.section-proposal {
  overflow: hidden;

  &__switcher-wrap {
    text-align: center;
  }

  &__switcher {
    margin-bottom: 5rem;

  }

  .content-section {
    &__header {
      @include media($md) {
        padding: 0 10.2rem;
      }
    }

    .section-heading {
      font-size: 2.2rem;

      @include media($md) {
        font-size: 3.4rem;
      }
    }
  }

  .section-header__more {
    @include media($md, false) {
      padding-top: .8rem;
      padding-bottom: .8rem;
    }
  }

  &__carousel {
    margin-right: -1.5rem;
    overflow: visible;

    @include media($md) {
      margin-right: auto;
      margin-left: auto;
      max-width: 99.5rem;
    }

    .swiper-slide {
      height: auto;
      box-sizing: border-box;
      transition: opacity .3s ease-in-out;

      .product-card {
        height: 100%;
      }

      &:last-child {
        padding-right: 1.5rem;

        @include media($md) {
          padding-right: 0;
        }
      }

      &:not(.swiper-slide-visible) {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .product-card {
    padding: 1.5rem;

    @include media($md) {
      padding: 3.5rem;
    }

    &__main {
      padding: 0;
    }

    &__title {
      max-width: 100%;
    }

    &__subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1rem;
      color: $black;
    }

    &__price {
      margin-top: auto;
    }
  }

  .product-content {
    &__description {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clipRule='evenodd' d='M11.586 0.335647C11.9529 -0.077136 12.585 -0.114317 12.9978 0.252601C13.4106 0.619519 13.4478 1.25159 13.0808 1.66437L5.96972 9.66438C5.77996 9.87786 5.50795 10 5.22231 10C4.93668 10 4.66467 9.87786 4.47491 9.66438L0.91935 5.66437C0.552431 5.25159 0.589612 4.61952 1.00239 4.2526C1.41518 3.88568 2.04725 3.92286 2.41417 4.33565L5.22231 7.49481L11.586 0.335647ZM12.5549 0.750874C12.4173 0.628568 12.2066 0.640962 12.0843 0.778556L5.47145 8.218C5.4082 8.28916 5.31753 8.32988 5.22231 8.32988C5.1271 8.32988 5.03643 8.28916 4.97318 8.218L1.9159 4.77856C1.79359 4.64096 1.5829 4.62857 1.4453 4.75087C1.30771 4.87318 1.29532 5.08387 1.41762 5.22147L4.97318 9.22147C5.03643 9.29263 5.1271 9.33334 5.22231 9.33334C5.31753 9.33334 5.4082 9.29263 5.47145 9.22147L12.5826 1.22147C12.7049 1.08387 12.6925 0.873181 12.5549 0.750874Z' fill='%2330414C'/%3E%3Cpath d='M12.7763 0.501731C13.0515 0.746343 13.0763 1.16772 12.8317 1.44291L5.72058 9.44291C5.59407 9.58524 5.41273 9.66667 5.22231 9.66667C5.03188 9.66667 4.85055 9.58524 4.72403 9.44291L1.16848 5.44291C0.923867 5.16772 0.948654 4.74634 1.22384 4.50173C1.49903 4.25712 1.92041 4.28191 2.16502 4.55709L5.22231 7.99654L11.8351 0.557094C12.0798 0.281906 12.5011 0.257119 12.7763 0.501731Z' fill='%2330414C'/%3E%3C/svg%3E%0A");
        width: 1.2rem;
        height: 1rem;
        background-size: contain;
      }
    }
  }

  .card-price {
    font-family: var(--inter-font), Arial, sans-serif;

    &__old-price {
      font-size: 1.4rem;
      opacity: .7;
      transform: translateY(-.1rem);

      @include media($md) {
        transform: translateY(-.3rem);
        font-size: 1.6rem;
      }
    }

    &__price {
      font-size: 1.6rem;
      font-weight: 600;

      @include media($md) {
        font-size: 2.3rem;
      }
    }
  }
}
