.library-disease {
  width: 100%;

  &__item {
    border-bottom: 1px solid $air-blue;
    
    &:first-child {
      @include media($md, false) {
        border-top: 1px solid $air-blue;
      }
    }
  }
  
  &__link {
    display: flex;
    align-items: center;
    color: $black;
    font-family: 'Codec Pro', Arial, Helvetica, sans-serif;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.3;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 1.8rem 0 1.8rem 0.5rem;
    transition: color 0.3s linear;
    
    &:hover {
      color: $primary-color;
    }
    
    @include media($md) {
      font-size: 2.3rem;
    }
  }

  &__icon {
    width: 2.4rem;
    height: 2.4rem;
    flex: none;
  }
}