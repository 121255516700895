.coupon-area {
  $self: &;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: .5rem 0;
  &__top {
    .coupon-form__btn {
      max-width: 8.2rem;
      font-size: 1.4rem;
    }

    #{$self}__row {
      align-items: stretch;
    }
  }

  &__row {
    display: flex;
    align-items: center;
  }

  &__sub-total,
  &__price {
    font-size: 13px;
    line-height: 1.4;
    
    @include media($md) {
      font-size: 14px;
    }
  }

  &__price {
    margin-left: auto;
  }

  &__text {
    font-size: 1.2rem;
    text-align: center;
  }

  &__btn {
    padding: 1rem 2.3rem;
    font-size: 1.4rem;
    width: 100%;
    
    @include media($md) {
      padding: 1.3rem 2.3rem;
      font-size: 1.6rem;
    }
  }

  &__payments {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.8rem;
  }

  &__payment {
    filter: grayscale(1);
    display: flex;
  }

  &__payment-icon {
    flex: none;
    width: 3rem;
    height: 2rem;
  }

  &.coupon-true {   
    .coupon {
      display: inline-flex;
    }
  }
}

.coupon {
  display: inline-flex;
  padding: 0.7rem 0.9rem;
  border-radius: 0.5rem;
  align-items: center;
  overflow: hidden;

  &__icon {
    flex: none;
    width: 1.4rem;
    height: 1.4rem;
  }

  &__close-wrap {
    display: flex;
  }

  &__close {
    width: 1.8rem;
    height: 1.8rem;
    flex: none;
    fill: $black;
    transition: all 0.3s linear;

    &:hover {
      fill: #000;
    }
  }

  &__text {
    color: $black;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.4;
    margin-left: 0.9rem;
    margin-right: 0.7rem;
    text-transform: uppercase;

    margin-bottom: -0.2rem;

    @include trim;

    span {
      margin-left: 0.3rem;
    }
  }
}

.coupon-form {
  $self: &;

  border-radius: 1.6rem;
  padding: 1.5rem 0;
  
  @include media($md) {
    padding: 2.5rem 0;
  }

  &__form {
    &.error {
      .coupon-form__input-field {
        border: 1px solid rgba(255, 57, 90, 0.50);
        background: linear-gradient(0deg, rgba(255, 57, 90, 0.05) 0%, rgba(255, 57, 90, 0.05) 100%), #FFF;
        box-shadow: 0rem -.1rem 0rem 0rem rgba(47, 43, 67, 0.10) inset, 0rem 0rem .3rem 0rem rgba(47, 43, 67, 0.10);
      }
  
      .coupon-form__desc-error {
        display: flex;
      }
    }
  }

  &__input-wrap {
    display: flex;

    #{$self}__btn {
      max-width: 11rem;
      flex: 1;
    }
  }

  &__input-field {
    position: relative;
    margin-right: 0.8rem;
    flex: 1;
    min-height: 6rem;
    display: flex;
    border-radius: .8rem;
    border: 1px solid $air-blue;
    background: $white;
    transition: border-color 0.3s linear;

    @include media($md) {
      min-height: 6.5rem;
    }

    &:focus-within,
    &:hover {
      border-color: #a5a4a4;
    }
  }

  &__input {
    padding: 1rem 1.5rem;
    width: 100%;
    color: $black;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
    align-self: flex-end;

    &::placeholder {
      color: transparent;
    }

    &:not(:placeholder-shown),
    &:focus {
      ~ #{$self}__label {
        transform: translateY(-1.2rem);
        font-size: 1.2rem;
        color: $black;
        opacity: 1;
      }
    }
  }

  &__label {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    color: #50585E;
    opacity: .7;
    pointer-events: none;

    display: flex;
    align-items: center;

    position: absolute;
    top: 0;
    left: 1.5rem;
    bottom: 0;

    transition: transform .3s linear, font-size .3s linear;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__btn {
    max-width: 100%;
    flex: 1;
    border-radius: .8rem;
  }

  &__desc-error {
    color: $gray;
    font-size: 1.3rem;
    line-height: 1.4;
    margin-top: 1rem;
    gap: 0.5rem;
    max-width: 24rem;
    display: none;

    @include media(385px) {
      max-width: 100%;
    }

    @include media($md) {
      font-size: 1.4rem;
    }
  }

  &__icon-error {
    flex: none;
    width: 1.8rem;
    height: 1.8rem;
  }

  &__coupons {
    display: flex;
    column-gap: 1rem;
    flex-wrap: wrap;
  }

  &__coupon {
    margin-top: 1.5rem;
    border-radius: 0.5rem;
    background: $air-blue;
  }
}