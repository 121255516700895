.features-section__item .features-section__info {
  width: 100%;
}

#__next {
  height: 100%;
}

[id] {
  scroll-margin-top: 7rem;

  @include media($md) {
    scroll-margin-top: 10rem;
  }
}

/**
 * Contacts Section
 */
.contacts-section {
  &__grid {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: minmax(0, 1fr);

    @include media($md) {
      gap: 2.5rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__card {
    &:not(:last-child) {
      margin-bottom: 1.5rem;

      @include media($md) {
        margin-bottom: 2.5rem;
      }
    }
  }
}

.contact-card {
  display: flex;
  gap: 1.5rem;
  padding: 2rem;
  border: .1rem solid $air-blue;
  border-radius: 1.6rem;

  @include media($md) {
    padding: 2.5rem;
    gap: 2.5rem;
  }

  &__img-wrap {
    display: flex;
    width: 3.2rem;
    height: 3.2rem;
    flex: none;

    @include media($md) {
      width: 5.6rem;
      height: 5.6rem;
    }
  }

  &__img {
    max-width: 100%;
    object-fit: contain;
    height: auto;
    margin: auto;
  }

  &__info {
    flex: 1;
    align-self: center;
  }

  &__caption {
    font-family: var(--codec-pro-font), Arial, sans-serif;
    color: $black;
    font-weight: 500;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-bottom: .3rem;

    @include media($md) {
      font-size: 2.3rem;
    }
  }

  &__link {
    color: $primary-color;
    font-weight: 600;
    font-size: 1.3rem;
    border-bottom: .1rem solid rgba($primary-color, .2);
    transition: border-bottom-color .3s ease-in-out;
    padding-bottom: .1rem;

    @include media($md) {
      font-size: 1.4rem;
    }

    &:hover {
      border-bottom-color: rgba($primary-color, 1);
    }
  }

  &__address {
    font-size: 1.4rem;
    color: $gray;

    @include media($md) {
      font-size: 1.6rem;
    }

    .btn-link {
      color: inherit;
      font-weight: 600;
      text-decoration: underline;
      text-decoration-color: #F0F2F7;
      text-underline-offset: 0.5rem;
      font-size: 1.4rem;

      @include media($md) {
        font-size: 1.6rem;
      }
    }
  }

  &__list {
    font-size: 1.4rem;
    color: $gray;

    @include media($sm, false) {
      line-height: 1.5 !important;
      margin-top: 1.5rem;
    }

    @include media($md) {
      font-size: 1.6rem;
    }

    a {
      color: inherit;
      padding-bottom: .1rem;
      border-bottom: .1rem solid $air-blue;
      transition: border-bottom-color .3s ease-in-out;
      font-weight: 600;
      font-size: 1.4rem;

      &:hover {
        border-bottom-color: $gray;
      }

      @include media($sm, false) {
        border-bottom: none;
        display: block;
        text-decoration: underline;
        text-decoration-color: $air-blue;
        text-underline-offset: .5rem;

        + br {
          height: 1rem;
        }
      }

      @include media($md) {
        font-size: 1.6rem;
      }
    }
  }

  &__item {
    margin-top: 1.8rem;
  }

  &.is-accent {
    border: .1rem solid rgba(96, 91, 255, 0.2);
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.05);
  }

  &.is-empty {
    border-color: transparent;

    @include media($md, false) {
      .contact-card {
        &__img-wrap {
          height: auto;
          align-self: flex-start;
        }

        &__img {
          max-width: 100%;
          max-height: none;
          margin: 0;
        }
      }
    }
  }
}

.manage-prefs {
  &__main {
    max-width: 90rem;
  }

  .section {
    &__header {
      text-align: center;
      margin-bottom: 6.5rem;

      @include media($md) {
        text-align: left;
        margin-bottom: 12rem;
      }

      .rte {
        font-weight: 300;
        font-size: 1.6rem;
        margin-top: 1rem;

        @include media($md) {
          font-size: 2.3rem;
          margin-top: 2rem;
        }
      }
    }

    &__body {
      max-width: 69rem;
    }
  }

  .main-heading {
    font-size: 3.2rem;

    @include media($md) {
      font-size: 6.4rem;
    }
  }
}

.manage-form {
  $self: &;

  &__subsection {
    margin-bottom: 5rem;

    @include media($md) {
      margin-bottom: 9.5rem;
    }
  }

  &__header {
    margin-bottom: 3.5rem;
  }

  &__caption {
    font-family: var(--codec-pro-font), Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 2rem;
    line-height: 1;
    color: $black;

    @include media($md) {
      font-size: 2.8rem;
    }
  }

  &__info {
    font-weight: 300;
    font-size: 1.6rem;
    color: #50585E;
    margin-top: .5rem;

    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__desc {
    margin-bottom: 2rem;
    font-size: 1.4rem;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__subtitle {
    color: #30414C;
    margin-bottom: 1rem;
    font-size: 1.6rem;
    font-weight: 600;
    display: block;
  }

  &__subcaption {
    font-weight: 300;
    margin: 3.5rem 0;
    color: #50585E;
    font-size: 1.6rem;

    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__fields-area ~ &__opts {
    margin-top: 3.5rem;
  }

  &__fields-area {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 1.2rem;

    @include media($md) {
      gap: 1.5rem;
    }
  }

  &__field {
    grid-column: 1 / -1;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      @include media($md) {
        grid-column: auto / span 3;
      }
    }

    &:nth-child(5),
    &:nth-child(9) {
      @include media($md) {
        grid-column: 1 / -1;
      }
    }

    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      @include media($md) {
        grid-column: auto / span 2;
      }
    }

    &.has-error {
      #{$self}__input {
        border-color: #FF395A80;
      }
      #{$self}__notification {
        display: flex;
      }
    }
  }

  &__field-main {
    position: relative;
  }

  &__label {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    color: #50585E;
    opacity: 0.7;
    pointer-events: none;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 1.5rem;
    bottom: 0;
    transition: transform 0.3s linear, font-size 0.3s linear;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__input {
    min-height: 6rem;
    border-radius: .8rem;
    border: 1px solid #F0F2F7;
    width: 100%;
    color: #30414C;
    padding: 0 1.4rem;

    @include media($md) {
      min-height: 6.5rem;
    }

    &::placeholder {
      color: transparent;
    }

    &:not(:placeholder-shown),
    &:focus {
      padding-top: 1.2rem;

      ~ #{$self}__label {
        transform: translateY(-1.3rem);
        font-size: 1.2rem;
        color: $black;
        opacity: 1;
        font-weight: 600;
      }

      &.select {
        box-shadow: none;

        &.is-empty {
          padding-top: 0;
          color: rgba(#50585E, .7);

          ~ #{$self}__label {
            opacity: 0;
          }

          option {
            color: #30414C;
          }
        }
      }
    }

    &:hover {
      border-color: #e6e6e6;
    }

    &:focus {
      border-color: #605BFF;
      outline: 1px solid #605BFF;
    }
  }

  &__notification {
    align-items: center;
    padding: 0 1.5rem;
    margin-top: .8rem;
    font-size: 1.2rem;
    gap: .5rem;
    display: none;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      flex: none;
    }
  }

  &__checkbox-button,
  &__radio-button {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .checkbox-label__main {
      padding-left: 3rem;
      min-width: 1.8rem;
      min-height: 1.8rem;
      display: block;
      line-height: 1.5;

      @include media($md) {
        font-size: 1.6rem;
      }
    }

    .checkbox-label__main::before {
      width: 1.8rem;
      height: 1.8rem;
      border-width: .2rem;
      background-size: 1.5rem !important;
      top: .2rem;
      transform: none;
    }

    .checkbox-label__main::after {
      width: 1.8rem;
      height: 1.8rem;
      left: 0;
      top: .2rem;
      transform: none;
    }
  }

  &__checkbox-button,
  &__radio-button {
    &:not(:last-child) {
      margin-bottom: 1.8rem;
    }
  }

  &__alert {
    &:not(:first-child) {
      margin-top: 1.8rem;
    }
  }
}

.manage-alert {
  display: flex;
  font-size: 1.4rem;
  gap: 1rem;

  @include media($md) {
    font-size: 1.6rem;
  }

  &__icon {
    flex: none;
    width: 1.9rem;
    height: 1.9rem;
  }

  &__info {
    flex: 1;

    strong {
      font-weight: 600;
    }
  }
}

body .rte .btn-link-more,
.btn-link-more {
  font-weight: 600;
  color: #605BFF;
  display: inline-flex;
  text-decoration: underline;
  text-underline-offset: 0.5rem;
  text-decoration-color: rgba(96, 91, 255, 0.2);
  transition: text-decoration-color 0.3s ease-in-out;
  padding: 0;

  &:hover {
    text-decoration-color: #605bff;
  }
}

.manage-prefs-success {
  @include media($md) {
    padding: 0 2.5rem;
  }

  &__icon {
    margin-bottom: 3.5rem;
  }

  &__info {
    margin-bottom: 2.5rem;
  }

  &__title {
    font-family: var(--codec-pro-font), Arial, sans-serif;
    color: $black;
    font-weight: 500;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-bottom: .5rem;

    @include media($md) {
      font-size: 2.3rem;
    }
  }

  &__desc {
    font-size: 1.3rem;
    color: #50585E;

    @include media($md) {
      font-size: 1.4rem;
    }
  }
}

/**
 * OneTrust
 */
body #onetrust-consent-sdk .ot-sdk-container {
  padding: 0 !important;
  width: 100% !important;
  /*max-width: 1280px !important;*/
  margin: 0 !important;
}

body #onetrust-consent-sdk #onetrust-banner-sdk {
  padding: 1.5rem;
}

@media (min-width: 768px) {
  body #onetrust-consent-sdk #onetrust-banner-sdk {
    padding: 2.2rem 2.5rem;
  }
}

body #onetrust-consent-sdk #onetrust-banner-sdk [role="dialog"] {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 768px) {
  body #onetrust-consent-sdk #onetrust-banner-sdk [role="dialog"] {
    flex-direction: row;
    gap: 15px;
  }
}

body #onetrust-consent-sdk #onetrust-banner-sdk .onetrust-close-btn-ui {
  padding: 0;
  min-height: 32px;
  min-width: 32px;
  height: 32px;
  width: 32px;
  margin: 0;
  transition: background-color .3s ease-in-out;
  bottom: auto;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  border-radius: 8px;
  background-image: none !important;
  color: #fff;
  line-height: 30px;
  display: flex;
}

@media (min-width: 768px) {
  body #onetrust-consent-sdk #onetrust-banner-sdk .onetrust-close-btn-ui {
    min-height: 40px;
    min-width: 40px;
    height: 40px;
    width: 40px;
    position: static;
    border-radius: 12px;
    line-height: 38px;
  }
}

body #onetrust-consent-sdk #onetrust-banner-sdk .onetrust-close-btn-ui:hover {
  color: #fff;
  background: #524dd9;
  border-color: #524dd9;
  opacity: 1;
}

body #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-policy {
  margin: 0;
}

body #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-close-btn-container {
  position: static;
  transform: none;
  margin: 0 0 0 auto;
}

body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-row {
  display: flex;
  outline: indigo;
  gap: 2rem;
  flex-direction: column;
}

@media (min-width: 576px) {
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-row {
    align-items: center;
  }
}

@media (min-width: 768px) {
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-row {
    flex-direction: row;
  }
}

body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-row::after {
  display: none;
}

body #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-group-container {
  width: auto;
  padding-right: 45px;
  flex: 1;
}

@media (min-width: 768px) {
  body #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-group-container {
    padding-right: 0;
  }
}

body #onetrust-banner-sdk #onetrust-policy-text,
body #onetrust-banner-sdk .ot-dpd-desc, #onetrust-banner-sdk .ot-b-addl-desc {
  font-size: 12px;
}

body #onetrust-banner-sdk #onetrust-button-group-parent {
  position: static !important;
  width: auto !important;
  padding: 0 !important;
  transform: none !important;
}

body #onetrust-consent-sdk #onetrust-button-group {
  white-space: nowrap;
  display: flex !important;
  gap: 15px;
  align-items: center;
  width: 100% !important;
  flex-direction: column;
}

@media (min-width: 576px) {
  body #onetrust-consent-sdk #onetrust-button-group {
    flex-direction: row;
  }
}

body #onetrust-consent-sdk #onetrust-button-group > * {
  border-radius: 8px;
  margin: 0 !important;
  width: 100%;
  text-align: center !important;
  font-size: 13px;
}

@media (min-width: 768px) {
  body #onetrust-consent-sdk #onetrust-button-group > * {
    border-radius: 12px;
    width: auto;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
    font-size: 14px;
  }
}

body #onetrust-consent-sdk #onetrust-button-group > *:not(.cookie-setting-link):hover {
  color: #fff;
  background: #524dd9;
  border-color: #524dd9;
  opacity: 1;
}

body #onetrust-consent-sdk #onetrust-close-btn-container > button:after {
  content: '';
  width: 8px;
  height: 8px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.2955 0.704505C1.85616 0.265165 1.14384 0.265165 0.704505 0.704505C0.265165 1.14384 0.265165 1.85616 0.704505 2.2955L4.40901 6L0.704505 9.7045C0.265165 10.1438 0.265165 10.8562 0.704505 11.2955C1.14384 11.7348 1.85616 11.7348 2.2955 11.2955L6 7.59099L9.7045 11.2955C10.1438 11.7348 10.8562 11.7348 11.2955 11.2955C11.7348 10.8562 11.7348 10.1438 11.2955 9.7045L7.59099 6L11.2955 2.2955C11.7348 1.85616 11.7348 1.14384 11.2955 0.704505C10.8562 0.265165 10.1438 0.265165 9.7045 0.704505L6 4.40901L2.2955 0.704505Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: contain;
  background-position: center;
  margin: auto;
}

@media (min-width: 768px) {
  body #onetrust-consent-sdk #onetrust-close-btn-container > button:after {
    width: 11px;
    height: 11px;
  }
}

body #onetrust-banner-sdk.ot-buttons-fw #onetrust-button-group {
  margin: 0 !important;
}

/**
 * OneTrust Modal
 */
body #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-title {
  font-size: 16px;
  font-weight: 600;
  color: #30414C !important;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-header {
  border-bottom-color: #F0F2F7;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .category-menu-switch-handler {
  border-left-width: 4px;
  border-bottom-color: #F0F2F7;
  border-bottom-width: 2px;
  background: #F8F9FB !important;
  outline: none !important;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .category-menu-switch-handler h3 {
  font-size: 14px;
  color: #30414C;
  font-weight: 600;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-active-menu {
  background: #fff !important;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-grp-desc {
  font-size: 14px;
  color: #30414C;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-link-btn {
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-link-btn:hover {
  text-decoration: none;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-footer {
  border-top-color: #F0F2F7;
}

body .ot-pc-footer button {
  font-size: 13px !important;
}

@media (min-width: 768px) {
  body .ot-pc-footer button {
    font-size: 14px !important;
  }
}

@media (max-width: 1023px) {
  body .onetrust-pc-dark-filter,
  body #onetrust-pc-sdk {
    display: none !important;
  }
}
