.partners {
  &__row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
    column-gap: 3rem;

    @include media($md) {
      row-gap: 3rem;
      column-gap: 3rem;
    }
  }

  .research-tabs__header {
    margin-bottom: 3rem;
  }
}

.partners-section {
  $self: &;

  .section__heading {
    max-width: 26.4rem;
    margin-left: auto;
    margin-right: auto;

    @include media(370px) {
      max-width: 100%;
    }
  }
  
  &__row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 1.8rem;
    row-gap: 1rem;

    &:not(:last-child) {
      margin-bottom: 1.8rem;

      @include media($md) {
        margin-bottom: 4rem;
      }
    }

    @include media($md) {
      column-gap: 3rem;
      row-gap: 1.8rem;
    }
  }

  &__img-wrap {
    position: relative;
    max-width: 9.8rem;
    width: 100%;
    display: inline-block;

    &:hover {
      #{$self}__img {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }

    &::after {
      content: "";
      width: 100%;
      padding-top: 100%;
      display: block;
    }

    @include media($md) {
      max-width: 16rem;
    }
  }

  &__img {
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s linear;
  }
}
