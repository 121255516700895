.about-breeds {
  &.rte {
    font-size: 1.4rem;
    color: $gray;

    @include media($md) {
      font-size: 1.6rem;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 2.5rem;

      @include media($md) {
        margin-bottom: 3rem;
      }
    }

    ol {
      li {
        min-height: 7rem;
        display: flex;
        align-items: center;
        padding-left: 9.5rem;

        &:not(:last-child) {
          margin-bottom: 2.5rem;
        }

        @include media($md) {
          padding-left: 10.2rem;
          min-height: 7.8rem;
        }

        &:before {
          width: 7rem;
          height: 7rem;
          box-shadow: 0 0 3rem 0 #0000000D;
          background-color: $white;
          color: $secondary-color;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 3.6rem;
          padding: .5rem;
          border-radius: 1.6rem;

          @include media($md) {
            width: 7.8rem;
            height: 7.8rem;
            padding: 1rem;
          }
        }
      }
    }

    a {
      font-weight: 600;
      color: $primary-color;
      text-decoration-color: inherit;
    }
  }
}