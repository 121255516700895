.card-widget {
  $self: &;

  background: var(--air-blue, #F0F2F7);
  border-radius: 1.6rem;
  position: relative;

  box-shadow: 0 0 5rem rgba(0, 0, 0, 0.03);

  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 15.6rem);
  min-height: 15.6rem;
  overflow: hidden;

  @include media($sm) {
    min-height: 18rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 18rem);
  }

  &__info {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  &__heading {
    font-weight: 600;
    color: #30414C;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;

    @include trim(3);

    @include media($sm) {
      font-size: 1.6rem;
    }

    &-link {
      color: inherit;
      transition: color .3s ease-in-out;
      display: block;

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  &__desc {
    font-size: 1.3rem;
    color: #50585E;
    margin-bottom: 1.8rem;
    margin-top: -.5rem;

    @include media($sm) {
      font-size: 1.4rem;
    }
  }

  &__prices {
    margin-bottom: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: baseline;
    line-height: 1.1;
  }
  
  &__price-old {
    text-decoration: line-through;
    font-size: 1.2rem;
    opacity: .5;
  }

  &__price {
    font-size: 1.3rem;
    color: #30414C;
    font-weight: 600;

    @include media($sm) {
      font-size: 1.4rem;
    }
  }

  &__btn {
    margin-top: auto;
  }
  
  &__preview {
    position: relative;
    background: #E4E9EF url('/assets/images/image-placeholder.png') no-repeat center / cover;

    &-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  &__badge {
    background: #FDCB4A;
    border-radius: 0 1.6rem 0 1.6rem;
    padding: .8rem 1.5rem;
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5;
    color: #30414C;
  }

  &.is-row {
    display: block;

    #{$self}__info {
      display: block;
      padding-top: 4.5rem;
      padding-bottom: 4.5rem;
    }

    #{$self}__heading {
      font-size: 2rem;
      font-weight: 500;
      font-family: var(--codec-pro-font), sans-serif;
      line-height: 1.3;

      @include media($sm) {
        font-size: 2.4rem;
      }

      @include media($md) {
        font-size: 2.8rem;
      }
    }

    #{$self}__prices {
      margin-bottom: 2.5rem;
    }

    #{$self}__preview {
      aspect-ratio: 16 / 9;
    }
  }

  &.text-center {
    #{$self}__prices {
      justify-content: center;
    }
  }

  &.is-bg {
    z-index: 0;
    aspect-ratio: 2 / 3;

    #{$self}__preview {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      
      &-img {
        object-position: bottom;
      }
    }
  }
}