.content-section {
  $self: &;

  &__row {
    display: grid;
    gap: 4rem;
    align-items: center;

    @include media($md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &.is-grid {
      align-items: start;

      @include media($sm) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include media($md) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }

    &.is-layout {
      align-items: flex-start;
      
      @include media($md) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    .section-header {
      padding: 0 3rem;
      min-width: 0;

      @include media($md) {
        padding: 0;
      }

      .btn {
        white-space: normal;
        text-align: left;
      }
    }
  }

   &__preview {
     order: 1;

     @include media($md) {
       order: 0;
     }
   }

  &__info {
    min-width: 0;
  }

  &__preview {
    &:not(:first-child) {
      #{$self}__img-wrap {
        @include media($md) {
          margin-left: auto;
        }
      }
    }
  }

  &__img-wrap {
    position: relative;
    max-width: 48.6rem;
  }

  &__img {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 1.6rem;
    
    @include media($md) {
      width: 100%;
      border-radius: 3.2rem;
    }

    /*
    @include media($md) {
      width: 100%;
      max-width: 48.6rem;
    }
    */
  }

  &__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    width: 4rem;
    height: 4rem;

    @include media($md) {
      width: 4.8rem;
      height: 4.8rem;
    }

    &:hover {
      padding: 1.7rem;
      
      .content-section__btn-play {
        transform: scale(1.2);
      }
    }
  }

  &__btn-play {
    width: 1.4rem;
    height: 1.4rem;
    flex: none;
    transition: all 0.3s linear;
    backface-visibility: hidden;

    @include media($md) {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 2rem;

      @include media($md) {
        margin-bottom: 3rem;
      }
    }
  }

  .section-header__content {
    font-size: 1.4rem;

    @include media($md) {
      font-size: 1.6rem;
    }
    
    @include media($xl) {
      font-size: 1.8rem;
    }

    > ul > li {
      padding-left: 2rem;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      &::before {
        display: none;
      }

      &::after {
        content: '';
        width: 1.1rem;
        height: 0.8rem;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.86084L4 6.86084L10 0.86084' stroke='%2343BF84' stroke-width='2'/%3E%3C/svg%3E%0A");
        background-size: contain;
        position: absolute;
        top: .6rem;
        left: 0;
      }
    }
  }

  &.is-gray {
    #{$self}__row {
      background: #F8F9FB;
      border-radius: 3.2rem;
      padding: 2.5rem 2.5rem 4rem;

      @include media($md) {
        padding: 2.5rem;
      }
    }

    #{$self}__header {
      padding: 0 2rem;

      @include media($md) {
        padding: 0 8rem;
      }
    }

    #{$self}__img {
      @include media($md) {
        max-width: none;
      }
    }

    .section-heading {
      @include media($md) {
        font-size: 3.4rem;
      }
    }
  }

  &.is-narrow {
    @include media($md) {
      #{$self}__preview {
        width: 100%;
        height: 100%;
        position: relative;
      }

      #{$self}__img-wrap {
        height: 100%;
      }

      #{$self}__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .section-header__btn {
    width: 100%;

    @include media($xs) {
      width: auto;
    }
  }
}

.content-section {
  $self: &;

  &__info {
    > #{$self}__info {
      &:not(:last-child) {
        margin-bottom: 2rem;

        @include media($md) {
          margin-bottom: 3rem;
        }
      }
    }
  }
}