// variables
:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --tertiary-color: #{$tertiary-color};
}

// Basic Definitions
a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

input {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
}

a,
button {
  cursor: pointer;
}

a:focus {
  outline: none;
}

ul,
ol {
  list-style: none;
}

address {
  font-style: normal;
}

::selection {
  background: $primary-color;
  color: $white;
}

// *********************************** Custom Scrollbar
// === Firefox
@supports not selector(::-webkit-scrollbar) {
  .scrollbar {
    scrollbar-width: thin;
    scrollbar-color: $primary-color #F5F5F5;
  }
}

// === Chrome / Edge / Safari
// width
.scrollbar::-webkit-scrollbar {
  width: .5rem;
  height: .5rem;
  background-color: #F5F5F5;
}

// track
.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 1rem;
  background-color: #F5F5F5;
}

// thumb
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: $primary-color;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

html {
  //font-size: 56.25%; // 9px
  font-size: 62.5%; // 10px

  @media (min-width: $md) {
    font-size: 50%; // 8px
  }
  
  @media (min-width: $lg) {
    font-size: 62.5%; // 10px
  }
}

body {
  background: $body-bg;
  height: 100%;
  color: $font-base-color;
  
  @include media($md) {
  }

  //font: #{$font-base-weight} #{$font-base-size} / #{$base-line-height} #{$font-base-stack};
  font: #{$font-base-weight} 1.4rem / #{$base-line-height} #{$font-base-stack};

  text-rendering: optimizespeed;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;

  @include media($md) {
    font-size: 1.6rem;
  }

  @include media($xl) {
    font-size: 1.8rem;
  }

  .tippy-box {
    background-color: $dark-accent--default;
    max-width: 24rem;
    border-radius: .8rem;
  }

  .tippy-content {
    padding: 1rem 1.5rem;
  }

  .tippy-arrow {
    color: $dark-accent--default;
  }

  [data-tippy-root] {
    max-width: 24rem;
  }
}

.container-fluid {
  max-width: $layout-max-width;
  margin: 0 auto;
  width: 100%;
  padding: 0 1.5rem;

  .container-fluid {
    padding: 0;
  }
}

.overlay {
  background: rgba(#222, .6);
  z-index: $overlayZIndex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.lg-backdrop {
  background: rgba($black, .75);
}

.lg-on {
  body {
    overflow: hidden;
  }
}

.lg-outer .lg-item:not(.lg-start-end-progress) {
  background: url('/public/assets/images/loading.gif') no-repeat scroll center center transparent;
}

.lg-outer.lg-grab img.lg-object {
  object-fit: contain;
}
