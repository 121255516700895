.catalog  {
  $self: &;

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    @include media($md) {
      gap: 2.5rem;
    }
  }

  &__card {
    --flex-items: 2;
    --flex-gap: 1.8rem;
    flex: 0 0 calc((100% / var(--flex-items)) - (((var(--flex-items) - 1) / var(--flex-items)) * var(--flex-gap)));
    max-width: calc((100% / var(--flex-items)) - (((var(--flex-items) - 1) / var(--flex-items)) * var(--flex-gap)));
    transition: all 0.3s linear;

    @include media($md) {
      --flex-items: 3;
      --flex-gap: 2.5rem;
    }
  }

  &__bottom {
    text-align: center;
    margin-top: 3rem;
  }

  &__btns {
    margin-top: 5rem;
    text-align: center;
    
    @include media($sm) {
      display: none;
    }
  }

  .media-card {
    height: auto;
    position: relative;

    &__discount {
      position: absolute;
      right: auto;
      left: 0;
    }
  }
}

.section {
  &.catalog  {
    padding: 4rem 0;

    @include media($md) {
      padding: 3rem 0 5rem;
    }
  }
}