.tags {
  &__title {
    color: $gray;
    font-size: 1.4rem;
    line-height: 1.4;
    margin-bottom: 0.8rem;

  }

  &__item {
    text-transform: capitalize;

    &:not(:last-child) {
      margin-bottom: 0.7rem;
    }
    
    @include media($md) {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  &__link {
    cursor: pointer;
  }
}