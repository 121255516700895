.cart-area {
  &__bottom {
    display: flex;
    flex-wrap: wrap;
    padding: 3rem 0;
    gap: 3rem;
    align-items: flex-start;

    .cart-area__conditions {
      display: none;

      @include media($md) {
        display: block;
      }
    }

    @include media($md) {
      flex-wrap: nowrap;
    }
  }

  &__conditions {
    margin-bottom: 1.5rem;
    align-self: center;
    
    @include media($md) {
      margin-bottom: 0;
      max-width: 50%;
    }
  }

  &__summary,
  &__coupon-area {
    width: 100%;
    margin-left: auto;
    
    @include media($md) {
      max-width: 38.5rem;
    }
    
    .cart-area__conditions {
      display: block;
      
      @include media($md) {
        display: none;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    align-items: center;
  }

  &__sub-total {
    font-weight: 300;
    font-size: 1.6rem;
    color: $black;
    
    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__price {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.3;
    color: $black;
    
    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__btn {
    padding: 1rem 2.3rem;
    font-size: 1.4rem;
    width: 100%;
    
    @include media($md) {
      padding: 1.3rem 2.3rem;
      font-size: 1.6rem;
    }
  }

  .section__header {
    margin-bottom: 3rem;
  }

  .section-heading {
    text-align: start;
  }

  &__upsell-widget {
    max-width: 100%;
    
    @include media($md) {
      max-width: 69rem;
    }
  }

  .coupon-form {
    $self: &;

    padding: 1.5rem;
    border-radius: 1.6rem;
    background-color: #f8f9fb;

    @include media($md) {
      padding: 2.5rem;
    }

    &__title {
      color: #30414c;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.4;
      margin-bottom: .8rem;
      display: inline-block;

      @include media($md) {
        font-size: 1.6rem;
      }
    }

    &__form {
      &.error {
        .coupon-form__input {
          border: 1px solid rgba(255, 57, 90, 0.50);
          background: linear-gradient(0deg, rgba(255, 57, 90, 0.05) 0%, rgba(255, 57, 90, 0.05) 100%), #FFF;
          box-shadow: 0rem -.1rem 0rem 0rem rgba(47, 43, 67, 0.10) inset, 0rem 0rem .3rem 0rem rgba(47, 43, 67, 0.10);
        }

        .coupon-form__desc-error {
          display: flex;
        }
      }
    }

    &__input {
      border-radius: .8rem;
      border: 1px solid #f0f2f7;
      background: #fff;
      box-shadow: 0 0 .3rem 0 rgba(47, 43, 67, .1);
      padding: .7rem 1.6rem;
      margin-right: .8rem;
      width: 100%;
      color: #30414c;
      font-size: 1.4rem;
      line-height: 1.4;
      font-weight: 400;
      transition: border-color .3s linear;
      align-self: auto;

      @include media($md) {
        font-size: 1.6rem;
      }

      &::placeholder {
        color: $black;
        opacity: .5;
      }
    }
  }
}

.condition {
  padding: 1.5rem;
  border-radius: 1.2rem;
  background-color: #F8F9FB;

  &__text {
    font-size: 1.3rem;
    color: $black;
    
    @include media($md) {
      font-size: 1.4rem;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    span {
      font-weight: 600;
    }
  }
}

.cart {
  display: grid;

  &__row {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(0, 1fr) minmax(0, 20%) minmax(0, 20%);
    border-bottom: 1px solid $air-blue;
    padding-bottom: 1.5rem;
    margin-top: 1.5rem;
    row-gap: 1rem;
    column-gap: 0.5rem;
    position: relative;
    min-height: 13.3rem;

    &:first-child {
      margin-top: 0;
    }

    @include media(360px) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 16%) minmax(0, 16%);
    }

    @include media($md) {
      grid-template-columns: 1fr 8rem 18rem 8rem;
      row-gap: 0;
      column-gap: 2.5rem;
      margin-top: 2.5rem;
      padding-bottom: 2.5rem;
      min-height: 23rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__empty-icon {
    width: 5rem;
    height: 5rem;

    @include media($md) {
      width: 10rem;
      height: 10rem;
    }
  }

  &__text {
    font-size: 2.2rem;
    margin-top: 2rem;

    @include media($md) {
      font-size: 3rem;
    }
  }

  &.is-empty {
    .empty-cart__content {
      display: block;
    }
  }

  .counter__input {
    padding: 0.7rem 2.5rem;

    @include media($md) {
      padding: 0.9rem 2.5rem;
    }
  }

  .cart-product {
    @include media($md, false) {
      grid-column: 1/4;
    }
    
    &__product {
      @include media($md) {
        flex: 1;
        gap: 2.5rem;
      }
    }

    &__img-wrap {
      @include media($md) {
        max-width: 18rem;
        
        &:hover {
          .cart-product__img {
            transform: scale(1.05);
          }
        }
      }
    }
    
    &__img {
      transition: transform 0.3s linear;
    }

    &__main {
      @include media($md) {
        flex: 1;
      }
    }

    &__top {
      margin-right: 5rem;
    }

    &__title {
      transition: all 0.3s linear;

      &:hover {
        color: $primary-color;
      }
      @include media($md) {
        margin-bottom: 0;
        font-size: 1.6rem;
      }
    }

    &__coupon {
      margin-top: 0.5rem;

      @include media($md) {
        margin-top: 1rem;
      }
    }

    &__cart-btn {
      align-self: center;
      justify-self: center;

      @include media($md, false) {
        position: absolute;
        top: 1rem;
        right: 0;
        // grid-column: 1/1;
      }
    }

    &__counter {
      @include media($md, false) {
        justify-self: start;
        grid-column: 1/1;
        max-width: 7rem;
        margin-left: 8.5rem;
      }
      
      @include media($md) {
        max-width: 10rem;
      }

      .input {
        border-radius: 0.6rem;
      }
    }
  }

  .total-price {
    @include media($md, false) {
      grid-column: -3/-1;
      justify-content: flex-end;
    }

    @include media($md) {
      display: block;
      text-align: end;
    }

    &__old-price {
      @include media($md) {
        margin: 0;
      }
    }
  }
}