.research-program {
  $self: &;

  &__content {
    background: #FFFFFF;
    display: flex;

    border-radius: 1.6rem;
    background: $white;
    box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.05);
    overflow: hidden;

    @include media($md) {
      border-radius: 3.2rem;
    }
  }

  &__col-tabs {
    display: none;

    @include media($md) {
      display: block;
      flex: 0 0 24%;
      max-width: 24%;
      border-right: .1rem solid $air-blue;
    }
  }

  &__col-info {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;

    @include media($md) {
      flex: 0 0 76%;
      max-width: 76%;
      padding: 3rem 5rem 5rem 5rem;
    }

    #{$self}__item {
      display: none;
    }

    .research-program__title {
      font-size: 1.4rem;
      border-bottom: .1rem solid $air-blue;

      @include media($md) {
        display: none;
      }
    }
  }

  &__title {
    font-size: 1.4rem;
    color: $gray;
    padding: 1.5rem 2rem;

    &.is-bold {
      font-weight: 600;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 1.2rem 3.6rem 1.2rem 1rem;
    color: $gray;
    position: relative;
    transition: all 0.3s linear;
    border-right: .2rem solid transparent;

    @include media($md) {
      padding: 1.2rem 2rem;
    }

    &:hover {
      color: $primary-color;
      background: $air-blue;
      // border-right-color: $primary-color;
    }

    &.is-active {
      font-weight: 600;
      color: $primary-color;
      background: $air-blue;
      border-right-color: $primary-color;
    }
    
    &::before,
    &::after {
      content: '';
      background-color: $primary-color;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 1px;
      transition: all 0.2s linear;

      @include media($md) {
        display: none;
      }
    }

    &::before {
      content: '';
      width: 1.1rem;
      height: 0.1rem;
      right: 1.5rem;

      @include media($md) {
        width: 1.6rem;
        right: 1rem;
      }
    }

    &::after {
      width: 0.1rem;
      height: 1.1rem;
      right: 2rem;

      @include media($md) {
        height: 1.6rem;
        right: 1.7rem;
      }
    }
  }

  &__icon {
    margin-right: 1.5rem;
    width: 2.4rem;
    height: 2.4rem;
    display: block;
    padding: 0.4rem;
  }

  &__text {
    font-size: 1.4rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    
    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__accordion-toggle {
    display: block;

    @include media($md) {
      display: none;
    }
  }

  &__accordion-toggle {
    border-bottom: 1px solid $air-blue;
  }

  &__tab-pane {
    &.is-active {
      #{$self}__link {
        font-weight: 600;
        background: $air-blue;
        color: $primary-color;
        border-right-color: $primary-color;

        &::after {
          transform: rotate(90deg);
          top: 39%;
          
          @include media($md) {
            top: 34%;
          }
        }

        &::before,
        &::after {
          background-color: $black;
        }
      }

      #{$self}__accordion-body {
        display: block;
      }
    }
  }

  // &__item {
  //   &:not(:last-child) {
  //     border-bottom: .1rem solid $air-blue;
  //   }
  // }
  
  &__accordion-body {
    display: none;
    padding: 2rem 2.5rem;
    border-bottom: .1rem solid $air-blue;

    @include media($md) {
      border-bottom: none;
      padding: 0;
    }
  }

  &__header {
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-start;

    @include media($md) {
      margin-bottom: 1.5rem;
    }
  }

  &__heading {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 1.2;
    color: $black;
    margin-right: 1.5rem;

    @include media($md) {
      font-size: 4rem;
    }
  }

  &__icon-info {
    width: 4.8rem;
    height: 4.8rem;
    display: none;
    flex: none;
    transform: translateY(-.4rem);
    margin-left: auto;

    @include media($md) {
      display: block;
    }
  }

  &__info-description {
    font-size: 1.4rem;

    @include media($md) {
      font-size: 1.6rem;
      padding-right: 2rem;
    }

    @include media($xl) {
      font-size: 1.8rem;
    }

    > *:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__props {
    margin-top: 2.5rem;
  }

  &__disclaimer {
    font-size: 1.3rem;
    padding: 1.5rem;
    background: #F8F9FB;
    border-radius: .8rem;
    margin-top: 2rem;

    @include media($md) {
      font-size: 1.4rem;
      margin-top: 3rem;
    }
  }

  &__info-controls {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @include media($md) {
      justify-content: flex-end;
      margin-top: 2.5rem;
    }
  }

  &__info-caption {
    font-size: 1.4rem;
    margin-bottom: 1rem;

    @include media($md) {
      margin-right: 1.5rem;
      margin-bottom: 0;
      font-size: 1.6rem;
    }
  }

  &__info-btn {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    
    @include media($md) {
      &:not(:last-child) {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
  }

  &.section {
    padding: 1rem 0;
  }
}

.props {
  &__row {
    display: flex;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    @include media($md) {
      &:not(:last-child) {
        margin-bottom: 2.5rem;
      }
    }
  }

  &__col-left {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 1rem;

    @include media($md) {
      flex: 0 0 22.5%;
      max-width: 22.5%;
      margin-bottom: 0;
    }
  }
  
  &__col-right {
    flex: 0 0 100%;
    max-width: 100%;

    @include media($md) {
      flex: 1;
      min-width: 0;
    }
  }

  &__condition {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    display: inline-block;
  }

  &__title {
    font-weight: 600;
    font-size: 1.4rem;
    padding-left: 2.1rem;
    color: $dark-accent--default;
    position: relative;

    @include media($xl) {
      font-size: 1.6rem;
    }
  }

  &__icon {
    width: 1.4rem;
    height: 1.4rem;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    flex: none;
    color: #1F6DB2;
    margin-top: .1rem;
  }

  &__description {
    font-size: 1.3rem;
    color: $gray;

    @include media($md) {
      font-size: 1.4rem;
    }

    @include media($xl) {
      font-size: 1.6rem;
    }
  }
}

.condition {
  &__name {
    font-weight: 600;
    font-size: 1.2rem;
    padding: 0.2rem 1rem;
    background: $air-blue;
    border-radius: 0.4rem;
    color: $black;

    @include media($xl) {
      font-size: 1.4rem;
    }
  }
}

.is-success {
  color: $success;
}

.is-error {
  color: $error;
}
