.mini-cart {
  $self: &;

  background: $white;

  overflow-y: auto;

  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    align-items: center;
    border-bottom: 1px solid $air-blue;

    @include media($md) {
      padding: 2.5rem;
    }
  }

  &__title {
    font-family: var(--codec-pro-font), Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 1.8rem;

    @include media($md) {
      font-size: 2.3rem;
    }
  }

  &__close {
    color: #30414C;
    line-height: 1;

    &:hover {
      color: $black;
    }

    &-icon {
      width: 2rem;
      height: 2rem;

      @include media($md) {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }

  &__main {
    flex: 1;
    overflow-y: auto;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    padding-left: 1.5rem;
    min-height: 18rem;

    @include media($md) {
      min-height: 20rem;
      padding-right: 2rem;
      padding-left: 2.5rem;
    }
  }

  &__product {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    opacity: 0;

    &:not(:last-child) {
      border-bottom: 1px solid $air-blue;
    }

    @include media($md) {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }

  .cart-product__product {
    align-items: flex-start;
  }

  .cart-product__counter {
    max-width: 8.3rem;
    margin-right: auto;
  }

  .counter__btn--remove {
    left: 0.8rem;
  }

  .counter__btn--add {
    right: 0.8rem;
  }

  .counter__btn-icon {
    width: 1.6rem;
    height: 1.6rem;
  }

  &__empty {
    margin-top: 4rem;
    text-align: center;
    padding: 7rem;
  }

  &__summary {
    margin-top: auto;
    box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    color: $black;
    z-index: 1;

    @include media($md) {
      padding: 2.5rem;
    }
  }

  &__upsell-widget {
    &.upsell-widget {
      border: 1px solid #605BFF33;
      border-radius: 1.6rem;
      padding: 1rem 1.6rem 1.5rem;
      margin-bottom: 1.5rem;

      @include media($xs) {
        padding: 1.6rem;
      }

      .upsell-widget__tag {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1rem;
        border-radius: 1.4rem 0;
        padding: .6rem .8rem;
      }

      .upsell-widget__img-wrap {
        position: relative;
        align-self: flex-start;
        max-width: 7rem;
        width: 100%;
        flex: auto;

        &:before {
          content: '';
          padding-top: 100%;
          display: block;
        }

        @include media($xs) {

        }
      }

      .upsell-widget__img {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .upsell-widget__info {
        flex: 1;
        max-width: 100%;
        padding-left: 1.5rem;
        padding-top: 0;
        text-align: start;
      }

      .upsell-widget__row {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
      }

      .upsell-widget__title {
        font-family: var(--inter-font), Arial, Helvetica, sans-serif;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.5;
      }

      .upsell-widget__subtitle {
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.5;

        @include media($xs) {
          font-size: 1.4rem;
        }
      }

      .upsell-widget__price-wrap {
        margin-top: 1rem;
      }

      .upsell-widget__price-old,
      .upsell-widget__price-new {
        line-height: 1.5;
      }

      .upsell-widget__price-old {
        font-size: 1.2rem;
        font-weight: 400;
        text-decoration: line-through;
        opacity: .5;
        margin-right: 1rem;
      }

      .upsell-widget__price-new {
        font-size: 1.3rem;
        font-weight: 600;

        @include media($xs) {
          font-size: 1.4rem;
        }
      }

      .upsell-widget__btn {
        align-self: flex-start;
      }
    }

    opacity: 0;
    transform: translate3d(0, 1.5rem, 0);

    @at-root .is-cart-opened {
      .mini-cart {
        &__product {
          animation: 1s fadeIn .25s forwards;
        }

        &__upsell-widget {
          animation: 1s upsellIn forwards;
        }
      }
    }

    &:nth-child(1) {
      animation-delay: .2s;
    }

    &:nth-child(2) {
      animation-delay: .5s;
    }

    &:nth-child(3) {
      animation-delay: 1s;
    }

    &:nth-child(3) {
      animation-delay: 1.5s;
    }

    &:nth-child(4) {
      animation-delay: 2s;
    }

    &:nth-child(5) {
      animation-delay: 2s;
    }
  }

  &__props {
    padding-bottom: 2rem;
    border-top: 1px solid #C7CBCE;
    border-bottom: 1px solid #C7CBCE;
  }

  &__prop {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
  }

  &__icon {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 0.5rem;
    flex: none;

    @include media($md) {
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 1rem;

    }
  }

  &__label-wrap {
    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__label {
    font-size: 1.4rem;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  .counter {
    border-radius: 0;
  }

  .input {
    padding: 0.7rem 2.5rem;
    font-size: 1.3rem;
    border-radius: 0.6rem;

    @include media($md) {
      font-size: 1.4rem;
      padding: 1rem 3rem;
      border-radius: 0.8rem;
    }
  }
}

.total-price {
  display: flex;
  align-items: center;

  &__old-price {
    font-size: 1.2rem;
    line-height: 1.4;
    text-decoration: line-through;
    margin-right: 1.2rem;
    opacity: 0.5;
    color: $black;
    margin-top: 0.2rem;
  }

  &__new-price {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.4;

    @include media($md) {
      font-size: 1.6rem;
    }
  }
}

.cart-product {
  &__product {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  &__img-wrap {
    max-width: 7rem;
    width: 100%;
    position: relative;
    flex: none;
  }

  &__img {
    width: 100%;
    height: auto;
    display: block;
  }

  &__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;
    min-width: 0;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    // @include media($md) {
    //   gap: 2.4rem;
    // }
  }

  &__title,
  &__quantity {
    font-size: 1.3rem;
    font-weight: 600;
    color: $black;

    @include media($md) {
      font-size: 1.4rem;
    }
  }

  &__title {
    margin-bottom: 0.5rem;
  }

  &__quantity {
    color: $gray;
    font-weight: 400;
  }

  &__subtitle {
    display: none;

    @include media($md) {
      font-size: 1.4rem;
      color: #6D7880;
    }
  }

  &__price {
    font-weight: 600;
    font-size: 1.4rem;
    color: $black;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;
    flex: 1;

    @include media($md) {
      gap: 2.4rem;
    }
  }

  &__cart-btn {
    align-self: flex-start;
  }

  &__icon {
    width: 1.8rem;
    height: 2rem;
    color: #6D7880;
  }

  .coupon {
    padding: 0;

    .coupon__text {
      white-space: normal;

      span {
        font-weight: 400;
      }
    }
  }

  .total-price__new-price {
    font-size: 1.3rem;

    @include media($md) {
      font-size: 1.4rem;
    }
  }
}

.summary {
  &__summary-info {
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;
  }

  &__info {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.4;
    align-self: flex-start;
    display: flex;
    align-items: center;

    @include media($md) {
      font-size: 1.4rem;
    }

    &:first-child {
      margin-right: .7rem;
    }

    + .coupon {
      padding-left: 0;
    }
  }

  &__icon {
    width: 1.1rem;
    height: 1.1rem;
    flex: none;
    color: $black;
    margin-left: 1rem;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5rem;

    &:last-child {
      border-top: 1px solid $air-blue;
      padding-top: 1.2rem;
    }
  }

  &__discount {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-width: 0;
  }

  &__coupon {
    margin-top: -.6rem;
  }

  &__total-price-wrap {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }


  &__controls {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
  }

  &__btn,
  .ais-ClearRefinements-button {
    font-size: 1.4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    @include media($md) {
      font-size: 1.6rem;
      padding-top: 2.1rem;
      padding-bottom: 2.1rem;
    }
  }
}

.empty-cart {
  &__content {
    text-align: center;
    padding: 5rem 0;
    border-bottom: 1px solid #F0F2F7;
    display: none;
  }

  &__empty-title {
    color: $black;
    font-family: var(--codec-pro-font), Arial, Helvetica, sans-serif;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.3;

    @include media($md) {
      font-size: 2.8rem;
    }
  }

  &__empty-text {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.4;
    margin-top: .5rem;

    @include media($md) {
      font-size: 1.4rem;
    }
  }

  &__empty-controls {
    margin-top: 2.5rem;
  }

  &__empty-btn {
    padding: 1rem 2.6rem;

    @include media($md) {
      padding: 1.2rem 3.2rem;
    }
  }

  &__empty-icon {
    width: 14.4rem;
    height: auto;
    margin-top: 2.4rem;
  }
}

.is-cart-opened {
  overflow: hidden;
}

@keyframes upsellIn {
  from {
    opacity: 0;
    transform: translate3d(0, 1.5rem, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
