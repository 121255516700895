/**
 * Icons
 * =================================
 */
.icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  vertical-align: middle;
  fill: currentColor;

  &[class*="stroked"] {
    stroke: currentColor;
    fill: none;
  }
}
