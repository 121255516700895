//============ Order ============//
//============  ============//
.ellipse-bottom-height {
  @include media($xl) {
    height: 15rem;
  }
}
//============  ============//

//============  ============//
.translate-top {
  transform: translateY(-50%);
}

.translate-bottom {
  transform: translateY(50%);
}
//============  ============//

//============  ============//
.bg-decor {
  position: absolute;
  width: 100%;
  z-index: 0;
  pointer-events: none;
  
  &__decor-svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  &.has-shadow {
    filter: drop-shadow(0 .5rem .5rem rgba(0, 0, 0, 0.03));
  }
}

.bg-decor--top {
  top: 0;
  left: 0;
}

.bg-decor--bottom {
  bottom: -1px;
  left: 0;
}

.bg-decor--right {
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(50%);
}

.bg-decor--left {
  top: 0;
  left: 0;
  bottom: 0;
  transform: translateX(-50%);
}
//============  ============//

//============ Z-Index ============//
.is-index-1 {
  z-index: 1;
}
//============ Z-Index ============//
