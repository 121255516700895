.hero-section {
  --hero-padding-top: calc(var(--announcementBarHeight, 0px) + 6rem + 2.5rem);

  background-color: $air-blue;
  position: relative;
  z-index: 0;
  overflow: hidden;

  padding-top: var(--hero-padding-top);

  margin-bottom: 2.5rem;
  
  @include media($md) {
    --hero-padding-top: calc(var(--announcementBarHeight, 0px) + 4rem + 7.5rem);

    margin-bottom: 3rem;
  }
  
  @include media($xl) {
    margin-bottom: 4.5rem;
  }
  
  @include media($xxl) {
    min-height: 78rem;
  }

  .container-fluid {
    position: relative;

    @include media($md) {
      padding-bottom: 10rem;
    }
  
    @include media(900px) {
      padding-bottom: 15rem;
    }
  }

  &__caption {
    background: $white;
    padding: .75rem 1.2rem;
    border-radius: .6rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    display: inline-flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: center;
    gap: .5rem;
    border: .1rem solid $air-blue;

    @include media($sm) {
      font-size: 1rem;
    }

    @include media($md) {
      font-size: 1.2rem;
    }

    &-text {
      margin-right: .5rem;
    }

    &-more {
      font-size: inherit;
    }

    .btn--rte-link {
      text-decoration: underline;
      color: $dark-accent--default;
      text-decoration-color: rgba($dark-accent--default, .15);
      text-underline-offset: .3rem;
      transition: text-decoration-color .3s ease-in-out;

      &:hover {
        text-decoration-color: rgba($dark-accent--default, 1);
      }
    }
  }

  &__content {
    margin-bottom: 2.5rem;
    width: 100%;
    
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 59rem;

    padding-bottom: 0.5rem;

    position: relative;
    z-index: 1;

    @include media($sm) {
      padding-bottom: 0.1rem;
    }

    @include media($md) {
      margin-bottom: 0;
      max-width: 100%;
      min-height: 38rem;
      margin-left: 0;
      margin-right: 0;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    @include media(90rem) {
      min-height: 53rem;
    }

    @include media($xl) {
      min-height: 53rem;
    }

    &.is-wide {
      max-width: 52rem;

      @include media($md) {
        max-width: 68rem;
      }
    }
  }

  &__suptitle {
    font-family: var(--codec-pro-font), Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    text-transform: uppercase;
    
    @include media($md) {
      font-size: 1.8rem;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 1.1;
    font-family: var(--codec-pro-font), Arial, Helvetica, sans-serif;
    margin-bottom: 1rem;
    color: $black;
    //color: $white;

    @include media($sm) {
      font-size: 5.8rem;
    }

    @include media($md) {
      font-size: 6.4rem;
      transform: none;
      max-width: var(--mw, 57rem);
    }
  }

  &__desc {
    font-weight: 300;
    font-size: 1.8rem;
    margin-bottom: 2.4rem;
    color: $gray;
    //color: $white;
    max-width: 35rem;
    margin-left: auto;
    margin-right: auto;

    @include media($md) {
      font-size: 2.3rem;
      max-width: var(--mw, 44rem);
      margin-bottom: 3rem;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__cover-wrap {
    margin: 3rem -1.5rem 0;
    
    @include media($md) {
      width: 85%;
      margin: 0;

      position: absolute;
      bottom: 0;
      display: flex;
      align-items: flex-end;
      z-index: -1;
      left: auto;
      right: 0;
      transform: translateX(14.5rem);
      //transform: translateX(11rem);
    }

    @include media(900px) {
      transform: translateX(5rem);
    }

    @include media($lg) {
      width: 90%;
      transform: translateX(11rem);
    }

    @include media($xl) {
      transform: translateX(16.5rem);
      width: 1200px;
      //width: 1220px;
      right: -3rem;
      //right: -1.5rem;
    }

    @include media($xxl) {
      transform: translateX(10.5rem);
      right: -10rem;
    }

    @include media(1600px) {
      transform: none;
      right: -20rem;
      //right: -12rem;
    }

    picture {
      width: 100%;
      height: 100%;
    }
  }

  &__cover {
    width: 100%;
    display: block;
    margin: 0 auto;
    object-fit: contain;

    height: 100%;
    object-position: bottom right;

    @include media($xl) {
      padding-top: 0;
    }
  }

  &__btn {
    padding: 1rem 2rem;
    background-color: $primary-color;

    @include media($md) {
      left: auto;
      transform: none;
      padding: 1.5rem 3rem;
    }
  }
}