.blog-categories-navbar {
  $self: &;

  flex: 1;
  min-width: 0;

  &__content {
    display: flex;
    border-radius: 1.6rem;
    width: 100%;
    background: $air-blue;
    padding: 1rem;
    gap: .5rem;
    text-align: center;

    @include media($md) {
      position: sticky;
      top: 12rem;
      min-width: 0;
      flex-direction: column;
      text-align: left;
    }
  }

  &__option {
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 1rem 1rem;
    transition: background-color .3s linear;
    flex: 1;

    @include media(360px) {
      padding: 1.2rem 1.6rem;
    }

    @include media($md) {
      flex-direction: row;
      gap: 1rem;
    }

    &:hover {
      background: $white;
    }
  }

  &__selected {
    background: $white;
    box-shadow: 0 0 .3rem 0 rgba(47, 43, 67, .1);
  }

  &__icon {
    width: 3.6rem;
    height: 3.6rem;
    display: block;
    margin-bottom: .5rem;

    @include media($md) {
      margin-bottom: 0;
    }
  }
}

.blog-section {
  $self: &;

  overflow: hidden;

  @include media($md) {
    overflow: visible;
  }

  &__results {
    margin-top: 2.5rem;
  }

  &__header {
    padding: 2.5rem 0 0;

    @include media($md) {
      padding: 4rem 0 0;
    }
  }

  &__breadcrumbs-search-container {
    display: flex;
    gap: 2.5rem;
    flex-direction: column;

    @include media($md) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 2.5rem;
      min-height: 4.8rem;

      .child:last-child {
        margin-bottom: 0; // Modify the margin of the last child as needed
      }
    }

    .breadcrumbs__items {
      display: inline-flex;

      .icon {
        transform: translateY(-.2rem);
      }
    }

    #{$self}__search-wrap {
      position: static;
    }
  }

  &__main,
  &__main-new {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    row-gap: 6.5rem;
    padding-top: 6.5rem;
    position: relative;

    @include media($md) {
      //padding-top: calc(var(--announcementBarHeight, 0px) + 10.5rem);
      padding-top: 0;
      flex-wrap: nowrap;
    }

    &:not(:last-child) {
      margin-bottom: 10rem;
    }
  }

  &__main-new {
    padding-top: 1.5rem;
    gap: 2.5rem;
    row-gap: 1.5rem;

    @include media($md) {
      padding-top: 0;
      row-gap: 6.5rem;
    }

    .blog-section__content {
      background-image: linear-gradient(to bottom, #F0F2F7, transparent);
      border-radius: 2.4rem;
      padding: 1px;
    }

    .filter {
      box-shadow: none;
      background-image: linear-gradient(to bottom, #F0F2F7 60%, transparent 100%);
      border-radius: 2.4rem;
      padding: 1px;
      border: none;

      &__main {
        background: #fff;
        padding: 2.4rem;
        border-radius: inherit;
      }
    }

    .article-card {
      border: none;

      background: #fff;

      @include media($md) {
        border-radius: 2.4rem;
        height: 100%;
      }

      &__info {
        padding-top: 1.5rem;

        @include media(360px) {
          padding-top: 2.5rem;
        }
      }

      &__suptitle {
        gap: 1.5rem;
        margin-bottom: 2.5rem;
        margin-top: 0;
      }

      &__caption-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem;
      }

      &__img-wrap {
        max-height: 100%;
        margin-top: 0;

        @include media(360px) {
          margin: 2.4rem;
          margin-top: 0;
        }

        &:before {
          padding-top: 56%;
        }
      }

      &__title {
        font-size: 2.5rem;

        @include media($md) {
          font-size: 4rem;
        }
      }

      &__description {
        //margin-bottom: 2.5rem;
        color: $black;

        + .article-card__date {
          margin-bottom: 2rem;
          margin-top: -.2rem;
        }
      }
    }

    .section-suptitle,
    .section-caption {
      font-size: 1.2rem;
      padding: .4rem .8rem;
      border-radius: .6rem;
    }
  }

  &__content {
    width: 100%;

    //background-image: linear-gradient(to bottom, #F0F2F7, transparent);
    //border-radius: 2.4rem;
    //padding: 1px;

    @include media($md) {
      flex: 0 0 66%;
      max-width: 66%;
    }

    .blog-section__search-wrap {
      position: static;
      margin-top: -6.5rem;

      @include media($md) {
        margin-top: 0;
      }
    }

    .ais-Hits-list {
      .ais-Hits-item:not(:last-child) {
        margin-bottom: 3rem;

        @include media($md) {
          margin-bottom: 2.5rem;
        }
      }
    }
  }

  &__article {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    @include media($md) {
      &:not(:last-child) {
        margin-bottom: 5rem;
      }
    }
  }

  &__sidebar {
    width: 100%;

    @include media($md) {
      flex: 1;
      min-width: 0;
    }

    .card-widget {
      @include media(360px, false) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 12rem);
      }

      @include media($md) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 12rem);
      }

      @include media($xl) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 18rem);
      }
    }
  }

  &__search-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @include media($md) {
      position: relative;
    }

    .search {
      padding: 0.4rem 0.4rem 0.4rem 1.5rem;

      input {
        font-family: Arial, sans-serif;
        letter-spacing: .001em;
      }
    }
  }

  &__search-w {
    @include media($md) {
      min-height: 5rem;
    }
  }

  &__result {
    margin-top: 1rem;
    color: #50585E;
    font-size: 1.4rem;
    line-height: 1.4;

    @include media ($md) {
      font-size: 1.6rem;
      margin-top: 1.5rem;
    }
  }

  &__pagination {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    @include media($md) {
      margin-top: 5rem;
    }
  }

  &.is-search {
    .blog-section__article:not(:last-child) {
      margin-bottom: 3rem;

      @include media($md) {
        margin-bottom: 2.5rem;
      }
    }
  }

  .section-heading {
    font-size: 2.2em;

    @include media($md) {
      font-size: 3.4rem;
    }
  }
}

.article-card-sm {
  $self: &;

  &__container {
    border-bottom: 1px solid $air-blue;
    padding-bottom: 2.5rem;

    .featured-media__articles {
      overflow: visible;

      @include media($md) {
        overflow: hidden;
      }
    }
  }

  &__article-card {
    width: 100%;
    height: 100%;

    @include media($md) {
      flex: 0 0 33%;
      max-width: 33%;
    }
  }

  &__btn-sm {
    align-self: flex-start;
    border-bottom: 1px solid $gray--medium;
    font-weight: 600;
    font-size: 1.2rem;
    border-bottom: 1px solid #e6e6e6;
    color: #605BFF;
  }

  &__img-wrap {
    position: relative;
    overflow: hidden;
    border-radius: .8rem;
    display: block;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    aspect-ratio: 16 / 9;
  }

  &__views-wrap {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.4rem;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 0.2rem;
    z-index: 1;
  }

  &__views-icon {
    color: $primary-color;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.5rem;
  }

  &__views {
    font-weight: 600;
    font-size: 1.4rem;
    color: $black;
  }

  &__info {
    padding-top: 1.5rem;
    position: relative;
    z-index: 2;
  }

  &__subtitle {
    display: flex;
    margin-bottom: 2rem;
  }

  &__author {
    padding-right: 3.5rem;
    position: relative;
    font-weight: 600;

    &::before {
      content: "";
      width: 1rem;
      height: 1rem;
      background-color: $light-green;
      border-radius: 50%;
      position: absolute;
      right: 1.1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__title {
    font-family: var(--codec-pro-font), Arial, sans-serif;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 1rem;
    color: $black;
    display: block;
    font-size: 1.6rem;

    @include media($md) {
      font-size: 1.9rem;
    }

    .matching {
      background-color: $light--blue;
      color: $white;
    }
  }

  &__date {
    font-size: 1.4rem;
    display: block;
    color: #50585E;
    margin-bottom: 1rem;
  }
}

.article-card {
  $self: &;
  border-radius: 2.4rem;
  border: 1px solid $air-blue;

  @include media($md) {
    border-radius: 3.2rem;
  }

  &__img-wrap {
    position: relative;
    border-radius: 1.6rem;
    display: block;
    overflow: hidden;
    margin: 1.6rem;


    &::before {
      content: '';
      display: block;
      padding-top: 51%;

      @include media($md) {
        padding-top: 43%;
      }
    }

    @include media($md) {
      max-height: 32rem;
      margin: 2.4rem;
      border-radius: 2.4rem;
    }

  }

  &__read-more {
    font-size: 1.3rem;
    font-weight: 600;
    color: $primary-color;
    margin-top: .5rem;
    display: inline-flex;
    text-decoration: underline;
    text-underline-offset: .5rem;
    text-decoration-color: rgba($primary-color, .2);
    transition: text-decoration-color .3s ease-in-out;

    @include media($md) {
      font-size: 1.4rem;
    }

    &:hover {
      text-decoration-color: rgba($primary-color, 1);
      text-decoration: underline;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__views-wrap {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.4rem;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 0.2rem;
    z-index: 1;
  }

  &__views-icon {
    color: $primary-color;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.5rem;
  }

  &__views {
    font-weight: 600;
    font-size: 1.4rem;
    color: $black;
  }

  &__info {
    padding: 0 1.5rem 1.5rem;
    position: relative;
    z-index: 2;

    @include media(360px) {
      padding: 0 2.4rem 2.4rem;
    }
  }

  &__suptitle {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    margin-top: 2.5rem;
    gap: 1rem;
  }

  &__suptitle-sm {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    gap: 1rem;
  }

  &__subtitle {
    display: flex;
    margin-bottom: 2rem;
  }

  &__author,
  &__date {
    font-family: var(--inter-font), Arial, sans-serif;
    font-size: 1.4rem;
    line-height: 1;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__author {
    padding-right: 3.5rem;
    position: relative;
    font-weight: 600;

    &::before {
      content: "";
      width: 1rem;
      height: 1rem;
      background-color: $light-green;
      border-radius: 50%;
      position: absolute;
      right: 1.1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__date.is-date {
    display: block;
    font-size: 1.4rem;
    color: #50585E;
  }

  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 1.3;
    margin-bottom: 1rem;
    color: $black;
    display: block;

    @include media($md) {
      font-size: 3.4rem;
    }

    .matching {
      background-color: $light--blue;
      color: $white;
    }
  }

  &__title-link {
    color: inherit;
    transition: all 0.3s linear;
  }

  &__description {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;

    @include media($md) {
      font-size: 1.6rem;
    }

    p {
      @include trim(6);
    }

    .matching {
      background-color: $light--blue;
      color: $white;
    }
  }

  &__btn {
    align-self: flex-start;
  }

  &.is-sm {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 2.4rem;
    box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.05);
    border: 1px solid transparent;
    transition: all 0.3s linear;

    &:hover {
      border: 1px solid $purple;
      box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.1);

      #{$self}__img {
        transform: scale(1.1);
      }

      #{$self}__title-link {
        color: $primary-color;
      }
    }

    #{$self}__img {
      transition: all 0.3s linear;
    }

    #{$self}__img-wrap {
      border-radius: 1.2rem;
    }

    #{$self}__info {
      padding: 0 2rem 2rem;
      display: flex;
      flex-direction: column;
      flex: 1;

      @include media($md) {
        padding: 0 2.5rem 2.5rem;
      }
    }

    #{$self}__title {
      font-size: 1.8rem;

      @include media($md) {
        font-size: 2.3rem;
      }
    }

    #{$self}__description {
      font-size: 1.4rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      margin-bottom: 3rem;

      @include media($md) {
        margin-bottom: 5rem;
      }
    }

    #{$self}__btn {
      margin-top: auto;
    }
  }

  &__category-small {
    order: -1;

    @include media($md) {
      margin-left: auto;
      order: 1;
    }
  }
}

.story-card {
  $self: &;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  @include media($md) {
    gap: 3rem;
  }

  &__col-info {
    padding: 0 3.3rem 3rem 3.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;

    @include media($md) {
      padding: 3rem 6.9rem 3rem 0;
    }
  }

  &__col-info {
    &.is-order-1 {
      @include media($md) {
        padding: 0 0 0 9.9rem;
      }
    }
  }

  &__col-img,
  &__col-video {
    position: relative;
  }

  &__img-wrap,
  &__video-wrap {
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  &__video-wrap {
    &::before {
      content: '';
      padding-top: 75%;
      display: block;

      @include media($md) {
        display: none;
      }
    }

    &.is-active {
      #{$self}__btn-play {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__btn-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__btn-play-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 2;

    &:hover {
      #{$self}__btn-play {
        transform: translate(-50%, -50%) scale(1.12);
      }
    }
  }

  &__suptitle {
    margin-bottom: 1rem;
  }

  &__suptitle,
  &__subtitle {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.2;
    color: $black;

    @include media($md) {
      font-size: 1.8rem;
    }
  }

  &__subtitle {
    margin-bottom: 2rem;
  }

  &__title {
    font-family: var(--codec-pro-font);
    font-size: 2.7rem;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 1.5rem;

    @include media($md) {
      font-size: 3.6rem;
    }
  }

  &__description {
    font-size: 1.4rem;
    line-height: 1.3;
    font-weight: 400;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    @include media($md) {
      font-size: 1.6rem;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    strong {
      font-weight: 600;
    }
  }
}

.blog-pagination,
.ais-Pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include media(390px, false) {
    gap: 1rem;
  }

  &__list,
  .ais-Pagination-list {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    @include media(370px) {
      margin-bottom: 0;
    }
  }

  &__item,
  .ais-Pagination-item {
    &:not(:last-child) {
      margin-right: 0.8rem;
    }

    @include media(390px) {
      &:first-child {
        margin-left: 0.8rem;
      }

      &:last-child {
        margin-right: 0.8rem;
      }
    }
  }

  &__link,
  &__prev-wrap,
  &__next-wrap {
    &:hover,
    &:active {
      color: $dark-accent--default;
      border-color: $dark-accent--default;
    }

    &:focus {
      border-color: transparent;
    }
  }

  &__prev-wrap,
  &__next-wrap {
    order: 1;
    width: 48%;
    color: $dark-accent--default;

    @include media(390px) {
      order: unset;
      width: auto;
    }
  }

  &__next-wrap {
    @include media(390px) {
      justify-content: flex-end;
    }
  }

  &__prev-wrap,
  &__next-wrap {
    padding: 0.7rem;
    border-color: rgba(67, 66, 97, 0.15);
    min-width: 3.2rem;
    min-height: 3.2rem;

    @include media($md) {
      padding: 1.1rem;
      min-width: 4rem;
      min-height: 4rem;
    }

    &:hover {
      background-color: transparent;
      border-color: rgba(67, 66, 97, 1);
    }
  }

  &__link,
  .ais-Pagination-link {
    font-family: var(--codec-pro-font);
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.2;

    padding: 0rem 0.95rem;
    color: $gray;

    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__link,
  &__ellipsis {
    border: 1px solid rgba(67, 66, 97, 0.15);
    padding: 0.7rem;
    font-size: 1.3rem;
    font-family: var(--inter-font), Arial, sans-serif;
    font-weight: 600;
    min-width: 3.2rem;
    min-height: 3.2rem;

    @include media($md) {
      font-size: 1.4rem;
      padding: 1.05rem 1rem;
      min-width: 4rem;
      min-height: 4rem;
    }

    &:hover {
      border-color: rgba(67, 66, 97, 1);
      background-color: transparent;
      color: $dark-accent--default;
    }
  }

  &__ellipsis {
    color: $dark-accent--default;
  }

  &__link--active,
  .ais-Pagination-link--selected {
    color: $black;
  }

  &__link--active {
    background-color: $air-blue;
    border-color: transparent;

    &:hover {
      border-color: transparent;
      background-color: $air-blue;
      cursor: default;
    }
  }

  .btn-link__icon {
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media($md) {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}

.carousel-pagination {
  .js-carousel-responsive .swiper-wrapper {
    @include media($md) {
      margin: 0;
      column-gap: 6rem;
      row-gap: 6.5rem;
    }
  }

  .js-carousel-responsive .swiper-slide {
    @include media($md) {
      margin-bottom: 0;
      padding: 0;
      flex: 1 1 calc((100% / 3) - 6rem);
    }
  }

  .swiper-pagination-bullets {
    margin-top: 3.5rem;
  }

  .swiper-pagination-bullets .swiper-pagination-bullet {
    border: 1px solid $primary-color;
    width: 1rem;
    height: 1rem;
  }

  .swiper-pagination-bullets .swiper-pagination-bullet-active::after {
    width: 1rem;
    height: 1rem;
  }

  .js-carousel-responsive .swiper-wrapper {
    @include media($md) {
      margin: 0;
      column-gap: 6rem;
      row-gap: 6.5rem;
    }
  }
}

.section-header__offer-wrap {
  margin-top: 6.5rem;
}

.offers {
  padding: 2.5rem 0;

  @include media($md) {
    padding: 5rem;
    box-shadow: 0 0 5rem 0 #00000008;
    border: 1px solid $air-blue;
    border-radius: 3.2rem;
  }

  &__heading {
    margin-bottom: 2.5rem;
    text-align: start;

    @include media($md) {
      margin-bottom: 3.5rem;
      text-align: center;
    }
  }

  &__title {
    font-family: var(--codec-pro-font), arial, sans-serif;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.3;
    color: #30414C;
    margin-top: 1.5rem;

    @include media($md) {
      font-size: 2.8rem;
    }
  }

  .media-card {
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }

  .catalog__card {
    --flex-items: 1;
  }

  .catalog__content {
    margin-bottom: 8rem;
    margin-right: -1.5rem;

    @include media($md) {
      margin-right: 0;
    }
  }
}

.blog-section-product {
  $self: &;

  &__results {
    margin-top: 2.5rem;
  }

  &__editor-container {
    border: none;
    padding-top: 6.5rem;

    @include media($md) {
      box-shadow: 0 2.4rem 2.4rem 0 #00000008;
      padding: 2.4rem 2.4rem 5rem 2.4rem;
      display: flex;
      gap: 2rem;
    }

    @include media($lg) {
      gap: 5rem;
    }

    .featured-media__articles {
      overflow: visible;

      @include media($md) {
        overflow: hidden;
      }
    }
  }

  &__editor-picks {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.8rem;
    width: 100%;
    margin-bottom: 2.5rem;

    @include media($md) {
      max-width: 18.5rem;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      margin-bottom: 0;
      padding: 0 2.4rem;
      gap: 1rem;
    }
  }

  &__icon {
    width: 2.4rem;
    height: 2.4rem;
    transform: translateY(-.3rem);

    @include media($md) {
      width: 3.6rem;
      height: 3.6rem;
      transform: none;
    }
  }

  &__editor-title {
    font-family: var(--codec-pro-font), Arial, sans-serif;
    color: $black;
    font-weight: 500;
    font-size: 2.2rem;

    @include media($md) {
      font-size: 3.4rem;
    }
  }

  &__img-wrap {
    position: relative;
    margin: 1.5rem;

    @include media($md) {
      margin: 2.5rem;
    }

    &::before {
      content: '';
      width: 100%;
      padding-top: 100%;
      display: block;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    image-rendering: -webkit-optimize-contrast;
  }

  &__btn {
    gap: 1rem;
    display: flex;
    margin-left: auto;
    padding: .5rem .5rem;
    border-radius: .8rem;
    font-size: 1.3rem;
    min-width: 3.2rem;
    min-height: 3.2rem;

    @include media($md) {
      padding: .7rem 1.5rem;
      min-width: 10rem;
      min-height: 3.2rem;
    }

    &-text{
      display: none;

      @include media($md){
        display: block;
      }
    }

    &-icon {
      width: 0.7rem;
      height: 1.5rem;
      display: block;
      flex: none;
    }
  }

  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 1.3;
    color: $black;
    display: block;

    @include media($md) {
      font-size: 3.4rem;
    }

    .matching {
      background-color: $light--blue;
      color: $white;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 2rem;

    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    &__icon {
      display: none;

      @include media($md) {
        display: block;
      }
    }
  }

  &__header-icon {
    width: 5.6rem;
    height: 5.6rem;
    flex: none;
    display: none;

    @include media($md) {
      display: block;
    }
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    row-gap: 5rem;
    position: relative;

    @include media($md) {
      flex-wrap: nowrap;
    }
  }

  &__content {
    width: 100%;

    @include media($md) {
      flex: 0 0 78.5%;
      max-width: 78.5%;
    }

    .blog-section__search-wrap {
      position: static;
      margin-top: -6.5rem;

      @include media($md) {
        margin-top: 0;
      }
    }

    .ais-Hits-list {
      .ais-Hits-item:not(:last-child) {
        margin-bottom: 3rem;

        @include media($md) {
          margin-bottom: 2.5rem;
        }
      }
    }

    .article-card {
      border: none;

      @include media($md) {
        box-shadow: 0 2.4rem 2.4rem 0 #00000008;
        padding: 2.5rem;
      }
    }
  }

  &__article {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    @include media($md) {
      &:not(:last-child) {
        margin-bottom: 5rem;
      }
    }
  }

  &__sidebar {
    width: 100%;

    @include media($md) {
      flex: 1;
      min-width: 0;
    }
  }

  &__search-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 2.5rem;

    @include media($md) {
      position: relative;
    }

    .search {
      padding: 0.4rem 0.4rem 0.4rem 1.5rem;
    }
  }

  &__result {
    margin-top: 1rem;
    color: #50585E;
    font-size: 1.4rem;
    line-height: 1.4;

    @include media($md) {
      font-size: 1.6rem;
      margin-top: 1.5rem;
    }
  }

  &__pagination {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    @include media($md) {
      margin-top: 5rem;
    }
  }

  &.is-search {
    .blog-section__article:not(:last-child) {
      margin-bottom: 3rem;

      @include media($md) {
        margin-bottom: 2.5rem;
      }
    }
  }

  .section-heading {
    font-size: 2.2em;

    @include media($md) {
      font-size: 3.4rem;
    }
  }
}
