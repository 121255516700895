.lib-section {
  &__aside {
    align-self: start;
    position: sticky;
    z-index: 11;

    top: calc(var(--announcementBarHeight, 0px) + 5rem);

    @include media($md) {
      top: calc(var(--announcementBarHeight, 0px) + 8rem);
    }
  }

  &__grid {
    display: grid;
    gap: 3rem;
    grid-template-columns: minmax(0, 1fr);

    @include media($md) {
      grid-template-columns: minmax(30rem, 0) minmax(0, 1fr);
    }
  }
}

.lib-areas {
  &__item {
    &:not(:last-child) {
      margin-bottom: 4.8rem;
    }
  }
}

.lib-area {
  $self: &;

  @include media($md) {
    padding: 2.5rem;
    box-shadow: 0 2.4rem 2.4rem rgba(0, 0, 0, 0.03);
    border-radius: 2.4rem;
  }

  &__header {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    gap: 2.5rem;

    @include media($md) {
      margin-bottom: 2.5rem;
      padding: 0 0 0 1.5rem;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    line-height: 1.1;
    font-size: 2.2rem;
    font-weight: 500;

    @include media($md) {
      font-size: 3.4rem;
    }

    &-img {
      flex: none;
      width: 4.5rem;
      height: auto;
      display: none;

      @include media($md) {
        display: block;
      }
    }

    &-text {
      @include media($md) {
        transform: translateY(.3rem);
      }
    }
  }

  &__sort {
    margin-left: auto;
    flex: none;
  }

  &__item {
    font-size: 1.3rem;
    font-weight: 600;
    color: #30414C;

    @include media($md) {
      font-size: 1.4rem;
    }

    a {
      transition: color .3s ease-in-out;

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  &__link {
    display: block;
    position: relative;
    color: inherit;
    padding: 1rem 0;

    @include media($md) {
      padding: 1.5rem;
    }

    &::after {
      content: '';
      width: 1rem;
      height: 1rem;
      position: absolute;
      right: .5rem;
      top: 1.4rem;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.195262 9.47136C-0.0650874 9.21101 -0.0650874 8.7889 0.195262 8.52855L3.72386 4.99996L0.195262 1.47136C-0.0650878 1.21101 -0.0650878 0.788904 0.195262 0.528555C0.455611 0.268205 0.877721 0.268205 1.13807 0.528555L5.13807 4.52855C5.39842 4.7889 5.39842 5.21101 5.13807 5.47136L1.13807 9.47136C0.877722 9.73171 0.455612 9.73171 0.195262 9.47136Z' fill='%2330414C'/%3E%3C/svg%3E%0A");


      @include media($md) {
        right: 1.5rem;
        top: 1.9rem;
      }
    }
  }

  &__more-trigger {
    display: inline-flex;
    padding: 1rem 0;
    align-items: center;
    gap: 2rem;
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--primary-color);

    @include media($md) {
      padding: 1.5rem;
      font-size: 1.4rem;
    }

    &.is-active {
      svg {
        transform: rotate(-180deg);
      }
    }

    &:hover {
      span {
        border-bottom-color: rgba(96, 91, 255, 1);
      }
    }

    svg {
      flex: none;
      width: 1rem;
      height: 1rem;
      transition: transform .3s ease-in-out;
    }

    span {
      border-bottom: .1rem solid rgba(96, 91, 255, 0.2);
      transition: border-bottom-color .3s ease-in-out;
      padding: .2rem 0;
    }
  }

  &[id] {
    @include media($md, false) {
      scroll-margin-top: 20.5rem;
    }
  }
}

.sort-switcher {
  $self: &;

  background: #F0F2F7;
  border-radius: 1.6rem;
  padding: .8rem;
  font-weight: 600;
  font-size: 1.2rem;
  color: #50585E;
  
  &__label {
    cursor: pointer;
  }

  &__input {
    &:checked {
      + #{$self}__sorts {
        &::before {
          transform: translate3d(100%, 0, 0);
        }
      }
    }
  }

  &__sorts {
    display: inline-flex;
    gap: .8rem;
    position: relative;
    z-index: 0;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 50%;
      background: #fff;
      box-shadow: 0 0 .3rem rgba(47, 43, 67, 0.1), inset 0 -.1rem 0 rgba(47, 43, 67, 0.1);
      border-radius: .8rem;
      z-index: -1;
      transition: transform .3s ease-in-out;
    }
  }

  &__sort-abc {
    line-height: 1;
    padding: .8rem 1.6rem;
    transition: background-color .3s ease-in-out, box-shadow .3s ease-in-out;
    border-radius: .8rem;
  }
}