.footer {
  &__area {
    border-top: 1px solid $air-blue;

    &:first-child {
      margin-top: 6.5rem;

      @include media($md) {
        margin-top: 9.5rem;
      }
    }
  }
  
  &__main {
    padding: 5rem 0 0;
    
    @include media($md) {
      display: flex;
      flex-wrap: wrap;
      padding: 8rem 0 5rem 0;
    }
  }

  &__logo-col {
    border-bottom: 1px solid $air-blue;
    padding-bottom: 2.5rem;
    margin-bottom: 2.5rem;

    @include media($md) {
      max-width: 20rem;
      width: 100%;
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
    }
    
    @include media(1140px) {
      max-width: 41.2rem;
    }
  }

  &__logo {
    max-width: 13rem;
  }

  &__navs {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    margin: 0 -1.5rem;
    max-width: 54rem;
    padding: 0 1rem;

    @include media($md) {
      padding: 0;
      max-width: none;
    }
  }

  &__nav {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 1rem;
    margin-bottom: 2.5rem;
    
    @include media($md) {
      flex: 0 0 25%;
      max-width: 25%;
      margin-bottom: 0;
    }
  }
  
  &__row {
    display: flex;
    flex-wrap: wrap;
    padding: 2.5rem 0;
    border-top: 1px solid #F0F2F7;
    border-bottom: 1px solid #F0F2F7;
    gap: 2.5rem;

    @include media($md) {
      gap: 2rem;
    }
  }

  &__soc-col {
    width: 100%;
    
    @include media($md) {
      width: auto;  
    }
  }

  &__agreement {
    gap: 1.5rem;
    flex-direction: column;
    
    @include media($md) {
      flex-direction: row;
      gap: 11rem;
      margin-left: auto;
      margin-right: 10rem;
    }
  }

  &__subscribe {
    margin: 6.5rem 0;
    
    @include media($md) {
      margin: 9.5rem 0;
    }
  }

  .subscribe__form-wrap {
    @include media($md) {
      margin-left: auto;
    }
  }

  .subscribe__field-wrap {
    @include media($md) {
      max-width: 50rem;
      margin-left: auto;
    }
  }

  .subscribe__title {
    font-size: 2rem;
    margin-bottom: 1rem;
    
    @include media($md) {
      max-width: 46rem;
      font-size: 2.8rem;
    }
  }
}

.footer-nav {
  &__title {
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: .6rem;
    color: $black;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: .6rem;

      @include media($md) {
        margin-bottom: 0.5rem;
      }
    }
  }

  &__link {
    font-size: 1.2rem;
    color: $gray;

    @include media($md) {
      font-size: 1.4rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.soc-icon {
  display: flex;
  align-items: center;
  column-gap: 2.3rem;
  row-gap: 1rem;
  
  @include media($md) {
    column-gap: 2.7rem;
  }
  
  &__icon-wrap {
    color: $primary-color;

    &:hover {
      .soc-icon__icon {
        opacity: 0.5;
      }
    }
  }

  &__icon {
    width: 1.4rem;
    height: 1.4rem;
    transition: all 0.3s linear;
    
    @include media($md) {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}

.agrement {
  display: flex;

  @include media($md) {
    align-items: center;
  }

  &__link {
    color: $black;
    font-size: 1.2rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

.branding {
  padding: 2.5rem 0 5rem;
  max-width: 89.5rem;
  line-height: 1.7;

  @include media($md) {
    padding: 4rem 0 5rem;
  }

  &__text {
    font-size: 1.2rem;
    opacity: 0.7;
    color: $black;

    &:not(:last-child) {
      margin-bottom: .5rem;

      @include media($md) {
        margin-bottom: 1rem;
      }
    }
  }
}