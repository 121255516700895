.filter {
  padding: 2.5rem;
  position: relative;
  border-radius: 1.6rem;
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.05);

  @include media($md) {
    border: 1px solid $air-blue;
  }

  &__box {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }

  &__date {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.3;
    margin-bottom: 1.5rem;
    color: $black;

    @include media($md) {
      font-size: 2.3rem;
    }
  }

  &__list-new {
    &:not(:first-child) {
      margin-top: 2.5rem;
    }
  }

  &__item {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 1.7rem;

      @include media($md) {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__item-new {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $air-blue;
    padding: 1.5rem 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__btn {
    margin-top: 1rem;
    max-width: 100%;

    .btn-link__text {
      min-width: 0;

      @include trim;
    }
  }

  &__link-new {
    display: flex;
    align-items: center;
    color: $black;
    font-family: 'Codec Pro', Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.3;
    justify-content: space-between;
    gap: 1.5rem;
    transition: color 0.3s linear;

    &:hover {
      color: $primary-color;
    }

    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__link {
    font-size: 1.4rem;
    color: $black;
    display: inline-block;
    position: relative;
    transition: all 0.3s linear;
    
    @include media($md) {
      font-size: 1.6rem;
    }

    &::before {
      content: "";
      width: 100%;
      height: 0.1rem;
      background-color: $black;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all 0.3s linear;
      opacity: 0;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  .total-details {
    color: $primary-color;

    &:hover {
      &::before {
        background-color: $primary-color;
      }
    }
  }
}

.category-small {
  color: $light--blue;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1;
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 1rem;
    width: 1.3rem;
    height: 1.3rem;
    flex: none;
  }
}

.popular-post {
  &__link {
    @include trim;
  }
}