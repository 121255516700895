.reviews-section {
  overflow: hidden;

  .section__header {
    @include media($md) {
      margin-bottom: 5rem;
    }
  }

  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;
  }
}

.review-card {
  border-radius: 2.4rem;
  border: .1rem solid $air-blue;
  padding: 2.5rem;
  height: 100%;

  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    line-height: 1.25;
    margin-bottom: 1rem;
    font-size: 2rem;
    color: $black;
    
    @include media($md) {
      font-size: 2.3rem;
      min-height: 5.5rem;
    }
  }

  &__description {
    font-size: 1.4rem;
    color: $gray;
    line-height: 1.6;

    @include media($md) {
      font-size: 1.6rem;
    }

    @include media($xl) {
      font-size: 1.8rem;
    }

    > *:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    line-height: 1.1;

    @include media($md) {
      margin-bottom: 3rem;
    }
  }

  &__stars-wrap {
    margin-right: 1.5rem;
  }

  &__auhor {
    font-weight: 600;
    margin-right: 0.5rem;
    font-size: 1.4rem;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__platform {
    font-size: 1.4rem;
    color: $black;

    @include media($md) {
      font-size: 1.6rem;
    }
  }
}

.stars-rating {
  position: relative;
  display: inline-flex;
  
  &__active,
  &__inactive {
    background-repeat: repeat-x;
    background-size: 2.2rem 2rem;
    background-position: left center;
  }
  
  &__inactive {
    width: 11rem;
    height: 1.6rem;
    flex: 1 1 auto;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Icon/Functional/star-empty'%3E%3Cpath id='Icon' fill-rule='evenodd' clip-rule='evenodd' d='M9.00006 5.63253L8.19014 7.37641C7.85835 8.09081 7.17714 8.57968 6.39412 8.66533L4.51599 8.87075L5.87373 10.0809C6.47561 10.6173 6.74445 11.4348 6.57846 12.2238L6.1998 14.0236L7.90846 13.0756C8.58744 12.6989 9.41267 12.6989 10.0917 13.0756L11.8003 14.0236L11.4216 12.2238C11.2557 11.4348 11.5245 10.6173 12.1264 10.0809L13.4841 8.87075L11.606 8.66533C10.823 8.57968 10.1418 8.09081 9.80997 7.37641L9.00006 5.63253ZM9.68027 3.53607C9.41147 2.95729 8.58864 2.95729 8.31984 3.53607L6.8297 6.74458C6.71911 6.98271 6.49204 7.14567 6.23103 7.17422L2.67647 7.56301C2.03062 7.63365 1.77398 8.43616 2.259 8.86845L4.87569 11.2007C5.07631 11.3795 5.16593 11.652 5.1106 11.915L4.39386 15.3217C4.26155 15.9506 4.92972 16.4437 5.49166 16.1319L8.63619 14.3873C8.86252 14.2617 9.13759 14.2617 9.36392 14.3873L12.5085 16.1319C13.0704 16.4437 13.7386 15.9506 13.6063 15.3217L12.8895 11.915C12.8342 11.652 12.9238 11.3795 13.1244 11.2007L15.7411 8.86845C16.2261 8.43616 15.9695 7.63365 15.3236 7.56301L11.7691 7.17422C11.5081 7.14567 11.281 6.98271 11.1704 6.74458L9.68027 3.53607Z' fill='%23FDCB4A'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
  
  &__active {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    height: 1.6rem;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Icon/Functional/star-filled'%3E%3Cpath id='Vector' d='M8.09304 4.02425C8.45145 3.25254 9.54855 3.25254 9.90696 4.02425L11.111 6.61666C11.2584 6.93417 11.5612 7.15145 11.9092 7.18951L14.7648 7.50185C15.6259 7.59604 15.9681 8.66606 15.3214 9.24244L13.2325 11.1043C12.965 11.3427 12.8455 11.706 12.9193 12.0567L13.4935 14.786C13.6699 15.6245 12.779 16.282 12.0298 15.8663L9.48515 14.4545C9.18339 14.2871 8.81661 14.2871 8.51484 14.4545L5.97023 15.8663C5.22098 16.282 4.33009 15.6245 4.50649 14.786L5.08072 12.0567C5.1545 11.706 5.03501 11.3427 4.76751 11.1043L2.67859 9.24244C2.0319 8.66605 2.37409 7.59604 3.23523 7.50185L6.09081 7.18951C6.43882 7.15145 6.74158 6.93417 6.88904 6.61666L8.09304 4.02425Z' fill='%23FDCB4A'/%3E%3C/g%3E%3C/svg%3E");
  }
}
