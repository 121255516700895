.tab-links {
  display: inline-flex;
  align-items: center;
  padding: 1rem;
  background-color: $air-blue;
  border-radius: 1.6rem;
  justify-content: center;
  gap: 1rem;

  @include media($sm, false) {
    width: 100%;
  }

  &__item {
    padding: .4rem .3rem 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-radius: .8rem;
    flex: 1;

    @include media($sm) {
      flex-direction: row;
      padding: .8rem 1.5rem;
      flex: auto;
      max-width: 100%;
    }

    &.is-active {
      box-shadow: 0 0 .3rem 0 #2F2B431A;
      background-color: $white;
    }
  }

  &__icon-wrap {
    width: 3.6rem;
    height: 3.6rem;
    position: relative;
    flex: none;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;

    @include media($sm) {
      margin-left: .4rem;
      text-align: start;
    }
  }
}