.stages-disease {
  .section__header {
    max-width: 26.4rem;
    margin: 0 auto 3rem;

    @include media($xs) {
      max-width: 100%;
    }
  }

  .section-description {
    max-width: 92rem;
    letter-spacing: 0.05rem;
    line-height: 1.3;
  }

  &__wrap {
    max-width: 92rem;
    margin: 0 auto;
  }
}

.stage-card {
  // max-width: 20.7rem;

  &__img-wrap {
    position: relative;
    width: 100%;

    &::after {
      content: '';
      width: 100%;
      padding-top: 100%;
      display: block;
    }
    
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(48, 65, 76, 0.176) 0%, rgba(0, 0, 0, 0) 0.01%, rgba(48, 65, 76, 0.1) 29.17%, rgba(48, 65, 76, 0.32) 65.62%, rgba(48, 65, 76, 0.4) 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__info {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    z-index: 2;
  }

  &__title {
    font-weight: 600;
    color: $white;
  }

  &__subtitle {
    font-size: 12px;
    color: $white;
  }
}

.section {
  &.stages-disease {
    padding-top: 1.5rem;

    @include media($md) {
      padding-top: 2.5rem;
    }
  }
}