.announcement-bar {
  $self: &;

  min-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: $primary-color;
  text-align: center;
  font-size: 1.4rem;
  padding: 1rem 0.5rem;
  color: $white;

  @include media(360px) {
    font-size: 1.6rem;
  }

  @include media($sm) {
    flex-direction: row;
  }

  &__counter {
    flex: none;
    margin-bottom: 1rem;

    @include media($sm) {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }

  &__main {
    width: 100%;

    @include media($sm) {
      max-width: 40rem;
    }
  }

  &__content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media($sm) {
      text-align: left;
      justify-content: flex-start;
    }

    a {
      color: inherit;
      font-weight: 600;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .btn-link {
    &.link--sm {
      font-size: 1.2rem;

      @include media(360px) {
        font-size: 1.4rem;
      }
    }
  }

  .swiper {
    margin: 0;
  }

  .swiper-slide {
    height: auto;
  }
}

.counter-announcement {
  display: flex;

  &__col {
    padding: 0 1.2rem;
    position: relative;

    &::before {
      content: '';
      width: 0.1rem;
      height: 100%;
      background: linear-gradient(270deg, rgba(67, 66, 97, 0.00) 0%, rgba(67, 66, 97, 0.15) 46.35%, rgba(67, 66, 97, 0.00) 100%);
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__time {
    font-family: var(--codec-pro-font);
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1;

    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__desc {
    font-size: .8rem;
    font-weight: 300;
  }
}
