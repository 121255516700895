.referral-section {
  &.has-extra-side-gaps {
    @include media(360px, false) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    align-items: center;

    @include media($md) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }
}

.referral-form {
  &__top {
    margin-bottom: 2.5rem;
    
    @include media($md) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 1.3;
    color: $black;

    @include media($md) {
      font-size: 4rem;
    }
  }

  &__subtitle {
    font-weight: 300;
    font-size: 1.8rem;
    margin-top: 1rem;

    @include media($md) {
      font-size: 2.3rem;
    }
  }

  &__middle {
    margin-bottom: 2.5rem;
    
    @include media($md) {
      margin-bottom: 3rem;
    }
  }

  &__fields {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1.5rem;

    @include media($md) {
      flex-direction: row;
    }
  }

  &__field {
    flex: 1;
  }

  &__label {
    color: $black;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
    display: inline-block;
    cursor: pointer;
    
    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__input {
    border-radius: 0;
    padding: 1rem 2rem;
    font-size: 1.4rem;
    border-radius: 1.2rem;
    // font-weight: 300;
    // line-height: 1.1;
    // border: 1px solid $air-blue;
    // box-shadow: 0 -.1rem 0 0 rgba(47, 43, 67, 0.10) inset, 0 0 3rem 0 rgba(47, 43, 67, 0.10);

    &:hover {
      border-color: #ccc;
    }
    
    &:focus-within {
      border-color: #ccc;
    }
    
    &::placeholder {
      color: $black;
      opacity: 0.4;
    }
    
    @include media($md) {
      padding: 1.3rem 2rem;
      font-size: 1.6rem;
    }
  }

  &__btn {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    // display: flex;
    margin-top: 2.5rem;
    width: 100%;
    font-size: 1.4rem;
    
    @include media($md) {
      margin-top: 3rem;
      font-size: 1.6rem;
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
    }
  }

  &__terms {
    font-size: 1.2rem;
    color: $gray;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__img {
    width: 100%;
    height: auto;
    display: block;
    max-width: 48.6rem;
    border-radius: 3.2rem;
    margin-left: auto;
    margin-right: auto;
   
    @include media($md) {
      margin-right: 0;
    }
  }
}