.program-section {
  $self: &;

  &__main {
    border: 1px solid #F0F2F7;
    box-shadow: 0 0 5rem rgba(0, 0, 0, 0.03);
    border-radius: 1.6rem;

    min-height: 48rem;

    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    @include media($md) {
      flex-direction: row;
    }
  }

  &__content {
    padding: 3.6rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media($md) {
      padding: 6rem 8rem;
      flex: 1;
    }

    @include media($xl) {
      padding: 7rem 10rem;
    }
  }

  &__preview {
    position: relative;
    background: #E4E9EF url('/assets/images/image-placeholder.png') no-repeat center / cover;
    aspect-ratio: 1 / 1;

    @include media($md) {
      aspect-ratio: auto;
      max-width: 48rem;
      width: 100%;
    }

    &-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__heading {
    color: #30414C;
    font-family: 'Codec Pro', Arial, Helvetica, sans-serif;
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 1.15;
    margin-bottom: 1rem;

    @include media($md) {
      font-size: 3.4rem;
    }
  }

  &__rte {
    font-size: 1.4rem;
    margin-bottom: 2.5rem;
    color: #50585E;

    @include media($md) {
      font-size: 1.6rem;
    }

    > * {
      margin-bottom: 1.5rem;

      @include media($md) {
        margin-bottom: 2.5rem;
      }
    }

    ul {
      li {
        display: flex;
        padding-left: 2.5rem;
        position: relative;

        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.9766 0.648438C13.332 0.976562 13.332 1.55078 12.9766 1.87891L5.97656 8.87891C5.64844 9.23438 5.07422 9.23438 4.74609 8.87891L1.24609 5.37891C0.890625 5.05078 0.890625 4.47656 1.24609 4.14844C1.57422 3.79297 2.14844 3.79297 2.47656 4.14844L5.34766 7.01953L11.7461 0.648438C12.0742 0.292969 12.6484 0.292969 12.9766 0.648438Z' fill='%2343BF84'/%3E%3C/svg%3E%0A");
          content: '';
          width: 1.3rem;
          height: 1.3rem;
          position: absolute;
          top: 0.6rem;
          left: 0;
          background-color: transparent;
          border-radius: 0;
        }

        &:not(:last-child) {
          margin-bottom: .5rem;
        }
      }
    }
  }

  &__prices {
    margin-bottom: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: baseline;
    line-height: 1.1;
  }

  &__price-old {
    text-decoration: line-through;
    font-size: 1.2rem;
    opacity: .5;
  }

  &__price {
    font-size: 1.4rem;
    color: #30414C;
    font-weight: 600;

    @include media($sm) {
      font-size: 1.6rem;
    }
  }

  &__btn {
    margin-top: auto;
    min-width: 13rem;
    min-height: 4rem;
  }
  
  &__badge {
    background: #FDCB4A;
    border-radius: 0 1.6rem 0 1.6rem;
    padding: .4rem 1.5rem;
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5;
    color: #30414C;

    @include media($md) {
      padding: .8rem 1.5rem;
    }
  }

  &.is-reverse {
    #{$self}__main {
      @include media($md) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1.5fr);
      }
    }

    #{$self}__preview {
      order: -1;
    }
  }
}


.program-section-new {
  $self: &;

  &__categories-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    padding: 2.5rem 0;

    @include media($md) {
      padding: 4rem 0 1.5rem;
    }
  }

  &__promo-item-wrap {
    display: block;
    
    &:hover {
      #{$self}__promo-item {
        text-decoration: underline;
      }
    }
  }

  &__promo-item {
    font-family: var(--inter-font), Arial, sans-serif;
    font-weight: 600;
    line-height: 1.3;
    font-size: 1.2rem;
    color: $black;
    display: flex;
    align-items: center;
    gap: 1rem;

    @include media($md) {
      font-size: 1.4rem;
    }
  }

  &__main {
    box-shadow: 0 0 5rem rgba(0, 0, 0, 0.03);
    background: $air-blue;
    border-radius: 2.4rem;
    position: relative;
    overflow: hidden;
    display: grid;
    margin-top: .5rem;
    margin-bottom: 5rem;

    @include media($md) {
      margin-bottom: 0;
      align-items: center;
      grid-template-columns: minmax(0, 36rem) minmax(0, 1fr);
      margin-top: 2.5rem;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    align-items: center;
    padding: 3.5rem;
    width: 100%;

    @include media($xl) {
      flex-wrap: nowrap;
    }
  }

  &__info {
    width: 100%;
  }

  &__preview {
    position: relative;
    background: #E4E9EF url('/assets/images/image-placeholder.png') no-repeat center / cover;
    overflow: hidden;

    @include media($md, false) {
      aspect-ratio: 1 / .34;
    }

    @include media($md) {
      max-width: 36rem;
      width: 100%;
      height: 100%;
    }

    &-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  &__heading {
    color: $black;
    font-family: 'Codec Pro', Arial, Helvetica, sans-serif;
    font-size: 1.9rem;
    font-weight: 500;
    line-height: 1.15;

    @include media($md) {
      font-size: 2.2rem;
    }
  }

  &__rte {
    font-size: 1rem;
    margin-bottom: 2.5rem;

    @include media($md) {
      font-size: 1.6rem;
    }

    > * {
      margin-bottom: 1.5rem;

      @include media($md) {
        margin-bottom: 2.5rem;
      }
    }

    ul {
      li {
        display: flex;
        padding-left: 2.5rem;
        position: relative;

        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.9766 0.648438C13.332 0.976562 13.332 1.55078 12.9766 1.87891L5.97656 8.87891C5.64844 9.23438 5.07422 9.23438 4.74609 8.87891L1.24609 5.37891C0.890625 5.05078 0.890625 4.47656 1.24609 4.14844C1.57422 3.79297 2.14844 3.79297 2.47656 4.14844L5.34766 7.01953L11.7461 0.648438C12.0742 0.292969 12.6484 0.292969 12.9766 0.648438Z' fill='%2343BF84'/%3E%3C/svg%3E%0A");
          content: '';
          width: 1.3rem;
          height: 1.3rem;
          position: absolute;
          top: 0.6rem;
          left: 0;
          background-color: transparent;
          border-radius: 0;
        }

        &:not(:last-child) {
          margin-bottom: .5rem;
        }
      }
    }
  }

  &__btn {
    min-width: 13rem;
    min-height: 4rem;
  }

  &.is-reverse {
    #{$self}__main {
      @include media($md) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1.5fr);
      }
    }

    #{$self}__preview {
      order: -1;
    }
  }
}