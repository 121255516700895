h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: $headings-weight;
  font-family: var(--codec-pro-font), Arial, sans-serif;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1 {
  font-size: 2.8rem;

  @include media($md) {
    font-size: 4.8rem;
  }
}

h2, .h2 {
  font-size: 2.2rem;
  
  @include media($md) {
    font-size: 3.4rem;
  }
}

h3, .h3 {
  font-size: 2rem;
  
  @include media($md) {
    font-size: 2.8rem;
  }
}

h4, .h4 {
  font-size: 1.8rem;
  
  @include media($md) {
    font-size: 2.3rem;
  }
}

h5, .h5 {
  font-size: 1.6rem;
  
  @include media($md) {
    font-size: 1.9rem;
  }
}

h6, .h6 {
  font-size: 1.4rem;
}

//.section-heading {}

.secondary-heading {
  position: relative;
  margin-bottom: 2.5rem;
  font-family: var(--codec-pro-font);
  text-transform: uppercase;
  color: $primary-color;
  font-size: 2rem;
}

.tertiary-heading {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-family: var(--codec-pro-font);
  color: $primary-color;
  position: relative;
  line-height: 1.2;
  padding-bottom: 1.5rem;

  @media (min-width: $md) {
    font-size: 4rem;
  }
}

.lead {
  font-size: 1.8rem;
}

.small {
  font-size: 1.4rem;
}

/* ---------- Post Typography ---------- */
.rte {
  margin: 0;
  line-height: 1.4;
  color: $gray;
  font-size: 1.4rem;

  @include media($md) {
    font-size: 1.6rem;
  }

  @include media($xl) {
    font-size: 1.8rem;
  }

  > * {
    margin: 0 0 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  h2 {
    margin-top: 3.5rem;

    @include media($md) {
      // margin-bottom: 1rem;
      margin-top: 6.5rem;
    }
  }

  h3, h4, h5 {
    margin-top: 3.5rem;
  }

  h2 + * {
    margin-top: 0;
  }

  a:not(.btn, .plants-item__link) {
    text-decoration: underline;
    color: inherit;
    text-decoration-color: rgba(#50585E, .6);
    text-underline-offset: .2rem;
    line-height: inherit;
    font-size: inherit;
    border: none;
    transition: text-decoration-color .3s ease-in-out;
    font-weight: inherit;

    &:hover {
      text-decoration-color: transparent;
    }
  }

  strong,
  b {
    font-weight: 600;
  }

  em,
  i {
    font-style: italic;
  }

  ul {
    list-style: none;

    li {
      padding-left: 2rem;
      position: relative;
      
      @include media($md) {
        padding-left: 2.5rem;
      }

      // &:not(:first-child) {
      //   margin-top: 0.7rem;
      // }

      &::before {
        content: "";
        width: 0.4rem;
        height: 0.4rem;
        background-color: $gray;
        border-radius: 50%;
        position: absolute;
        left: 0.7rem;
        top: 1rem;
        // font-size: 3.6rem;
        // line-height: 0.6;
        
        @include media($md) {
          left: 1rem;
        }
      }
    }

    ul,
    ul ul {
      margin: 0.7rem 0 0;
    }
  }

  ol {
    counter-reset: item-counter;

    > li {
      counter-increment: item-counter;
    }

    li {
      padding-left: 2rem;
      position: relative;

      @include media($md) {
        padding-left: 2.5rem;
      }
      
      // &:not(:first-child) {
      //   margin-top: 0.7rem;
      // }
      
      &::before {
        content: counter(item-counter);
        font-size: 1.4rem;
        color: $gray;
        font-family: var(--codec-pro-font);
        position: absolute;
        left: 0.5rem;
        top: 0.2rem;
        line-height: 1.2;
        
        @include media($md) {
          left: 0.7rem;
          font-size: 1.6rem;
        }
      }
    }

    ol,
    ol ol {
      margin: 0.7rem 0 0;
    }

    ul {
      color: #9a9a9a;
      margin-top: 2rem;

      li {
        padding-left: 4rem;
        font-weight: 400;

        &::before {
          content: "·";
          background: transparent;
          color: $primary-color;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  blockquote {
    font-style: normal;
    color: $dark--medium;
    border-left: 0.3rem solid $primary-color;
    padding-left: 3rem;

    > * {
      margin: 0;
    }

    cite {
      display: block;
      margin-top: 1rem;
    }
  }

  cite {
    font-size: smaller;
    font-style: normal;
  }

  figcaption {
    color: $gray;
    font-size: 1.2rem;
    margin-top: 1.5rem;
  }

  mark {
    background: yellow;
    padding: .2rem .4rem;
    border-radius: .3rem;
  }

  code {
    background-color: rgba(68, 68, 68, 0.2);
    padding: 0.2rem 0.4rem;
    border-radius: 0.3rem;
  }

  pre {
    border: 0.1rem solid rgba(68, 68, 68, 0.2);
    border-radius: 0.3rem;
    padding: 2rem;
    overflow-x: auto;
    background: #444;
    color: #fff;

    code {
      background: transparent;
    }
  }

  hr {
    // margin-bottom: 2rem;
    margin-bottom: 4.5rem;
    margin-top: 4.5rem;
    opacity: 0.3;
  }

  img:not([class]) {
    max-width: 100%;
    display: block;
    height: auto;
    margin: 2.5rem 0 1.5rem;
    border-radius: 1.6rem;
  }

  table {
    width: 100%;
    text-align: left;
    font-size: 1.6rem;
    border-collapse: collapse;
  }

  thead {
    border-bottom: 1px solid #e6e6e6;
  }

  tr {
    &:not(:first-child) {
      border-top: 1px solid #e6e6e6;
    }

    &:hover {
      td {
        background: #fafafa;
      }
    }
  }

  th,
  td {
    padding: 1rem;
  }

  th {
    font-weight: bold;
  }

  td {
    transition: all .3s;
  }

  .float-left {
    float: left;
    margin: 1.5rem 1.5rem 1.5rem 0;
  }

  .float-right {
    float: right;
    margin: 1.5rem 0 1.5rem 1.5rem;
  }

  .callout {
    padding: 2rem;
    background: #f0eedf;
    color: #110b04;
    position: relative;
    border-radius: .5rem;
  }
}
/* ---------- Post Typography ---------- */
