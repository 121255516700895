.products-section {
  position: relative;
  overflow: hidden;

  &__leading-product {
    margin: 0 auto 2rem auto;
    position: relative;
    overflow: hidden;

    @include media($md) {
      margin: 5rem 0 2.5rem 0;
    }

    @include media($lg) {
      min-height: 34rem;

      .product-card__controls {
        margin-top: auto;
      }
    }
  }

  &__content {
    .products-section__card-wrap {
      &:not(:last-child) {
        margin-bottom: 1.5rem;

        @include media($md) {
          margin-bottom: 2.5rem;
        }
      }
    }
  }

  &__carousel {
    margin-right: -1.5rem;
    overflow: visible;

    @include media($md) {
      margin-right: 0;
    }

    .swiper-slide {
      height: auto;
      box-sizing: border-box;

      &:not(.swiper-slide-visible) {
        opacity: 0;
        visibility: hidden;
      }

      .product-card {
        height: 100%;
      }

      &:last-child {
        padding-right: 1.5rem;

        @include media($md) {
          padding-right: 0;
        }
      }
    }
  }

  &.has-full-width-card {
    padding: 0 0 1.5rem 0;

    @include media($md) {
      padding: 0 0 2.5rem 0;
    }
  }
}

.margin-bottom {
  margin-bottom: 5rem;

  @include media($md) {
    margin-bottom: 6rem;
  }
}


