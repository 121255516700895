.featured-product {
  padding: 2rem;
  border-left: .4rem solid #D1EACA;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.05);

  @include media($sm) {
    padding: 3rem;
  }

  &__heading {
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.4;
    color: #30414C;
    margin-bottom: .5rem;

    @include media($sm) {
      font-size: 1.4rem;
    }
  }

  &__desc {
    color: #50585E;
    font-size: 1.3rem;

    @include media($sm) {
      font-size: 1.4rem;
    }
  }

  &__bottom {
    margin-top: 1.5rem;
    display: flex;
    gap: 2rem;
    align-items: center;

    .btn--primary-reverse {
      border-color: rgba(96, 91, 255, 0.2);
    }
  }

  &__btns {
    display: flex;
    gap: 1rem;
    flex: 1;
    flex-direction: column;

    @include media($sm) {
      flex-direction: row;
    }
  }

  &__btn {
    width: 100%;

    @include media($sm) {
      width: auto;
    }
  }

  &__img-wrap {
    flex: 0 0 calc(40% - 1rem);
    max-width: calc(40% - 1rem);

    @include media($sm) {
      flex: 0 0 12rem;
      max-width: 12rem;
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: auto;
    max-height: 7rem;
    object-fit: contain;
  }
}