.related-articles {
  padding: 1.5rem 0 1.5rem 1.5rem;

  @include media($md) {
    padding: 1.5rem;
  }

  &__title {
    font-size: 2.3rem;
    font-weight: 500;
    font-family: var(--codec-pro-font), Arial, sans-serif;
    color: $black;
    margin-bottom: 2.5rem;
  }

  &__card {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
}

.related-card {
  display: block;
  height: 100%;

  &__main {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__img-wrap {
    width: 100%;
    max-width: 100%;
    border-radius: .8rem;
    display: block;
  }

  &__img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    aspect-ratio: 16 / 9.5;
    border-radius: inherit;
  }

  &__content {
    margin-top: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__captions {
    margin-bottom: 1.2rem;
    display: inline-flex;
    max-width: 100%;
    row-gap: .5rem;
    position: relative;
    padding-right: 3rem;

    @include media($md) {
      flex-wrap: wrap;
    }

    &.is-expanded {
      flex-wrap: wrap;

      .is-trigger {
        display: none;
      }
    }
  }

  &__caption {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    &.is-trigger {
      position: absolute;
      top: 0;
      right: 0;

      @include media($md) {
        display: none;
      }
    }
  }

  .section-caption {
    padding: 0.4rem 0.8rem;
    border-radius: 0.6rem;
    font-size: 1.2rem;
    white-space: nowrap;
  }

  &__title {
    font-family: var(--codec-pro-font), Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    color: $black;
    margin-bottom: 1rem;
    line-height: 1.4;
    transition: color .3s linear;
    display: block;

    &:hover {
      color: $primary-color;
    }

    @include media($md) {
      font-size: 1.9rem;
    }
  }

  //&__bottom {
  //  display: flex;
  //  flex-wrap: wrap;
  //  row-gap: 1rem;
  //
  //  > * {
  //    &:not(:last-child) {
  //      position: relative;
  //      padding-right: 2.5rem;
  //
  //      &::after {
  //        content: "";
  //        width: .7rem;
  //        height: .7rem;
  //        background-color: #D1EACA;
  //        border-radius: 50%;
  //        position: absolute;
  //        right: .8rem;
  //        top: 50%;
  //        transform: translateY(-50%);
  //      }
  //    }
  //  }
  //}

  //&__author,
  //&__data {
  //  font-family: var(--inter-font), Arial, sans-serif;
  //  font-size: 1.2rem;
  //  color: $black;
  //
  //  @include media($md) {
  //    font-size: 1.4rem;
  //  }
  //}
  //
  //&__author {
  //  font-weight: 600;
  //}

  //&__btn {
  //  margin-top: 1.5rem;
  //}

  &__data.is-date {
    font-size: 1.4rem;
    color: #50585E;
    display: block;
    margin-bottom: 1.3rem;
  }

  &__btns {
    margin-top: auto;
  }
}

.js-carousel-responsive {
  .swiper-wrapper {
    @include media($md) {
      display: block;
    }
  }

  .swiper-slide {
    @include media($md) {
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }
  }
}