.upsell-widget {
  display: flex;
  flex-wrap: wrap;
  border-radius: 2.4rem;
  background: #F8F9FB;
  justify-content: center;
  padding: 2.5rem 1rem;
  box-shadow: 0 0 5rem 0 #00000008;

  @include media($xs) {
    padding: 2.5rem 1.5rem 2.5rem 2.5rem;
    justify-content: start;
  }

  &__info {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    padding-top: 1.5rem;
    
    @include media($xs) {
      padding-top: 0;
      text-align: start;
      flex: 0 0 63%;
      max-width: 63%;
    }
  }

  &__row {
    display: flex;
  }

  &__title {
    color: $black;
    font-family: var(--codec-pro-font), Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.3;

    @include media($xs) {
      font-size: 1.9rem;
    }
  }

  &__btn {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    
    @include media($sm) {
      padding-top: 1.1rem;
      padding-bottom: 1.1rem;
    }
  }

  &__offertag {
    margin-bottom: 0.8rem;
    transform: none;
    
    @include media($xs) {
      margin-bottom: 1.2rem;
    }
  }

  &__img-wrap {
    max-width: 20rem;
    order: -1;
    
    @include media($xs) {
      order: 0;
      flex: 0 0 37%;
      max-width: 37%;
    }
  }

  &__img {
    width: 100%;
    height: auto;
    display: block;
    max-height: 20rem;
    object-fit: contain;
    image-rendering: -webkit-crisp-edges;
  }
}