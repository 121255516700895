.disease-card {
  border-radius: 2.4rem;
  background: #F8F9FB;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 35rem;

  &__title {
    color: $black;
    font-family: 'Codec Pro', Arial, Helvetica, sans-serif;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2.5rem;
    
    @include media($md) {
      font-size: 2.8rem;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__link,
  &__more {
    color: #50585E;
    padding-left: 2.5rem;
    position: relative;
    font-size: 1.4rem;
    line-height: 1.4;
    transition: color 0.3s linear;

    @include trim(1);

    &:hover {
      color: $primary-color;
    }
    
    @include media($md) {
      font-size: 1.6rem;
    }

    &.is-more {
      font-weight: 600;
    }
  }

  &__more {
    text-align: start;
    font-weight: 600;
    margin-top: 1rem;
  }

  &__icon {
    width: 1.8rem;
    height: 1.8rem;
    flex: none;
    position: absolute;
    top: 50%;
    left: 0.3rem;
    transform: translateY(-50%);
    color: #1F6DB2;
  }

  &__controls {
    margin-top: auto;
  }

  &__btn {
    display: block;
    margin-top: 4rem;
  }
}