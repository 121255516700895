.mob-panel {
  position: fixed;
  width: 100vw;
  bottom: 0;
  transform: translate3d(-100%, 0, 0);
  top: 0;
  left: 0;
  background: $white;
  transition: transform .3s ease-in-out;
  padding-top: 7.1rem;

  &__main {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    background: inherit;
  }

  &__nav {
    flex: 1;
  }

  &__bottom {
    padding:  0 1.5rem;
    margin-top: auto;
    position: sticky;
    background: inherit;
    bottom: 0;
    padding-bottom: 1.5rem;
    z-index: 2;
  }
  
  &__btns-reg {
    padding: 2rem 0;
    display: flex;

    .header-controls__btn {
      flex: 1;
    }
  }

  &__btn {
    display: block;
    flex: 1;
    padding: 1rem 2rem;
    font-size: 1.4rem;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  @include media($md) {
    display: none;
  }
  
  .is-dropdown {
    display: block;
  }

  .nav-dropdown {
    display: block;
  }

  .nav-dropdown__col {
    &:first-child {
      margin-top: 1.5rem;
    }
  }

  .nav-dropdown__link {
    font-size: 1.6rem;
    padding: 2rem 0;
    width: 100%;
    font-family: var(--codec-pro-font);
    
    &.promo-card-mob {
      padding: 1.2rem 0;
      position: relative;

      .discount {
        font-size: 1.2rem;
        padding: 0.4rem 0.8rem;
        border-radius: 0.8rem 0 0.8rem 0;
        z-index: 2;
      }
    }
  }

  .promo-card-mob__img-wrap {
    width: 9rem;
    position: relative;
    flex: none;
  }

  .promo-card-mob__img {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    z-index: 1;
  }

  .nav-dropdown__title {
    margin-bottom: 1.2rem;
    margin-top: 1.5rem;
  }
}

.mob-nav {
  $self: &;

  &__item {
    display: flex;
    align-items: center;
    padding: 1.9rem 1.5rem;
    border-top: 1px solid #F0F2F7;

    &:last-child {
      border-bottom: 1px solid #F0F2F7;
    }

    &.is-active {
      #{$self}__icon {
        transform: rotate(-180deg);
      }
    }

    &.is-active {
      .mob-nav__link {
        color: $primary-color;
      }
      
      .navbar__icon {
        transform: rotate(-180deg);
      }
    }
  }

  &__link {
    font-weight: 500;
    font-family: var(--codec-pro-font);
    font-size: 1.6rem;
    line-height: 1.3;
    color: $black;
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-left: 1rem;
    width: 1rem;
    height: 1rem;
    transition: all 0.3s linear;
  }

  &__badge {
    margin-left: 1rem;
  }
}


