.feature-item {
  display: flex;

  &__icon-wrap {
    width: 5rem;
    margin-right: 1.5rem;
    flex-shrink: 0;

    @include media($md) {
      width: 6rem;
      margin-right: 3rem;
    }
  }

  &__icon {
    width: 100%;
    height: auto;
    display: block;
  }

  &__desc-wrap {
    min-width: 0;
  }

  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;

    @include media($md) {
      font-size: 2.1rem;
    }
  }

  &__description {
    font-size: 1.3rem;
    color: #6D7880;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    @include media($md) {
      font-size: 1.4rem;
    }
  }
}