// ************************
// | Template main colors |
// ************************
// Brand Color Palette
$primary-color: #605BFF !default;
$secondary-color: #8EC6F8 !default;
$tertiary-color: #FF227C !default;

// Useful colors
$white: #ffffff !default;
$black: #30414C !default;
$dark: #333333 !default;
$dark--medium: #666 !default;
$dark--light: #999 !default;
$dark--thin: #aaa !default;
$light--blue: #8EC6F8;
$purple: rgba(96, 91, 255, 0.20); 
$dark-accent--default: #434261;

$salmon: #FFC4AC;
$air-blue: #F0F2F7;
$cocoa: #D27F7F;
$light-green: #D1EACA;
$yellow: #FFD400;

// Useful gray colors
$gray: #50585E !default;
$gray--medium: #e6e6e6 !default;
$gray--light: #f7f7f7 !default;
$gray--thin: #fafafa !default;

// Alert statusses colors
$error: #FF395A; // изменил
$error--muted: #FFBABA;
$success: #43BF84; // изменил
$success--muted: #DFF2BF;
$warning: #FDCB4A; // изменил
$warning--muted: #FEEFB3;
$info: #31708f;
$info--muted: #d9edf7;

// ***************
// | Base styles |
// ***************
$body-bg: #ffffff !default;
$layout-max-width: 1230px !default;

$spacer: 1rem;

// z-indexes
$baseZIndex: 3 !default;
$flowZIndex: 11 !default;
$dropdownZIndex: 55 !default;
$dropdownAboveZIndex: 77 !default;
$overlayZIndex: 101 !default;
$overlayAboveZIndex: 111 !default;

// Breakpoints
$breakpoints: (
  xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$xs--mw: 479px;
$sm--mw: 575px;
$md--mw: 767px;
$lg--mw: 991px;
$xl--mw: 1199px;
$xxl--mw: 1399px;

// **************
// | Typography |
// **************
// Base fonts stack
$font-base-weight: 400 !default;
$font-base-stack: var(--inter-font), Arial, sans-serif !default;
$font-base-size: 1.6rem !default;
$font-base-color: $gray !default;
$base-line-height: 1.4 !default;

// Headings
$headings-weight: 500 !default;
$headings-fonts-stack: var(--codec-pro-font), Arial, sans-serif !default;
$headings-color: $black !default;
$headings-line-height: 1.3 !default;

$subheading-color: #343434 !default;

// link colors
$link-color-normal: #4AA6E3 !default;
$link-color-visited: #9964C7 !default;
$link-color-hover: $primary-color !default;

// *********
// | Forms |
// *********
$input-font-size: 1.6rem;

$input-placeholder-color: #BDBEB7;
$input-typing-color: $font-base-color;

$input-border-normal: #EFEFEF;
$input-border-hover: $primary-color;
$input-border-focus: $primary-color;

$input-border-radius: .5rem;

$input-focus-shadow: 0 0 1.5rem rgba($input-border-focus, 0.2);