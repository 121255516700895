.media-grid {
  $self: &;

  &__header {
    text-align: center;
    margin-bottom: 2.5rem;

    .table-responsive {
      margin-bottom: 3rem;
      padding-bottom: .7rem;
    }
  }

  &__switchers {
    display: inline-flex;
    white-space: nowrap;
  }

  &__pane {
    &:not(.is-active) {
      display: none;
    }
  }

  &__main {
    display: grid;
    gap: 1.5rem;

    @include media($md) {
      gap: 2.5rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &.is-col-3 {
      @include media($md) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    &.is-col-6 {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      @include media(480px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @include media($sm) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      @include media($md) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }
    }
  }

  &__bottom {
    text-align: center;
    margin-top: 3rem;
  }
}

.partner-card {
  $self: &;

  display: flex;
  border-radius: 1.6rem;
  background: $white;
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.05);
  
  &:hover {
    #{$self}__btn {
      opacity: 1;
      visibility: visible;
    }
  }
  
  &__img-wrap {
    position: relative;
    max-width: 9.5rem;
    width: 100%;
    flex: none;

    @include media($md) {
      max-width: 14.5rem;
    }
  }

  &__info {
    padding: 1.5rem;
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media($md) {
      padding: 2.5rem;
    }
  }

  &__img {
    object-fit: contain;
    max-width: 75%;
    max-height: 75%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__heading {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.15;
    margin-bottom: 0.5rem;
    color: $black;

    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__project {
    font-size: 1.3rem;
    line-height: 1.2;
    color: $gray;
    margin-bottom: 1rem;

    @include media($md) {
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }
  }

  &__btn {
    margin-top: auto;
    color: $dark;

    .btn-link__icon {
      width: 1.4rem;
      height: 1.4rem;
      
      // @include media($md) {
      //   width: 1.4rem;
      //   height: 1.2rem;
      // }
    }

    @include media($md) {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__arrow {
    width: 1.2rem;
    height: 1.2rem;
    color: $black;
    margin-left: 0.5rem;
  }
}

.partner-logo {
  $self: &;

  position: relative;

  &:hover {
    #{$self}__img {
      transform: translate3d(-50%, -50%, 0) scale(1.1);
    }
  }

  &::before {
    content: '';
    display: block;
    // padding-top: 75%;
    padding-top: 88%;
  }

  &__img {
    transition: transform .3s ease-in-out;
    transform: translate3d(-50%, -50%, 0);
    object-fit: contain;
    width: 70%;
    height: 70%;
    position: absolute;
    left: 50%;
    top: 50%;
  }
}