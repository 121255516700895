.stars-rating {
  $self: &;
  display: inline-flex;
  position: relative;

  &__inactive,
  &__active {
    display: flex;
    align-items: center;
  }

  &__inactive {
    #{$self}__icon {
      fill: #FEE4BE;
    }
  }

  &__active {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    overflow: hidden;

    #{$self}__icon {
      fill: $yellow;
    }
  }

  &__icon {
    &:not(:last-child) {
      margin-right: 0.3rem;
    }
    
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }
} 