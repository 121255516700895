.sidebar {
  > *:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.subscribe {
  &.subscribe--sm {
    padding: 1.6rem;
    border-radius: 1.6rem;
    border: 1px solid $air-blue;
    background-color: $white;
    
    @include media($md) {
      padding: 2.4rem;
    }

    .subscribe__badge {
      align-self: center;

      @include media(361px) {
        align-self: flex-start;
      }
    }
    
    .subscribe__title {
      margin-bottom: 1rem;
      font-size: 1.6rem;
      
      @include media($md) {
        font-size: 1.9rem;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 2rem;
      }
    }

    .subscribe__form-wrap {
      @include media($md) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    
    .subscribe__form {
      @include media(361px) {
        flex-wrap: nowrap;
        text-align: start;
      }
    }

    .subscribe__input {
      @include media(361px) {
        text-align: start;
      }
    }

    .subscribe__btn {
      @include media(361px) {
        width: auto;
      }
    }

    .subscribe__title {
      @include media(361px) {
        text-align: start;
      }
    }

    .subscribe__description-subscribe {
      @include media(361px) {
        text-align: start;
        flex-direction: row;
        align-items: normal;
      }
    }
  }
}