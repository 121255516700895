// ****************
// * Fixed Footer *
// ****************
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  &__main {
    flex-grow: 1;

    > *:not(.reviews-section):last-child,
    > .section:last-child {
      padding-bottom: 0;
    }

    > .featured-media:last-child,
    > .products-section:last-child {
      padding-bottom: 2rem;
    }

    > .features-section:last-child {
      padding-bottom: 6.5rem;

      @include media($md) {
        padding-bottom: 8.5rem;
      }
    }

    > .error-section:last-child {
      padding-bottom: 9.5rem;

      // @include media($md) {
      //   padding-bottom: 12rem;
      // }
    }

    > .sitemap-section:last-child {
      padding-bottom: 9.5rem;
    }

    > .terms-service:last-child {
      padding-bottom: 9.5rem;
    }
  }
}