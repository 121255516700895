.modal-win {
  $self: &;

  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transition: opacity .3s, transform .3s;
  z-index: $overlayAboveZIndex;
  overflow: hidden;

  background: rgba($black, .5);

  + .modal-win {
    background: rgba($black, .35);
  }

  &__main {
    padding: 3rem 1.5rem;
    background: $white;
    margin: 3rem auto;
    position: relative;
    width: 92%;
    border-radius: .8rem;
    // max-height: 92vh;
    // overflow-x: hidden;

    opacity: 0;
    transition: opacity 1s;
    animation: fadeOut .25s;

    @include media($xs) {
      padding: 3rem;
    }

    @media (min-width: $sm) {
      max-width: 43rem;
      width: 100%;
    }

    &.is-modal-win-opened  {
      animation: .3s scaleIn forwards;
      opacity: 1;
    }

    &--wide {
      max-width: 115rem;
    }
  }

  &__close {
    position: absolute;
    padding: 0;
    border: none;
    background: none;
    width: 2rem;
    height: 2rem;
    top: 2rem;
    right: 2rem;
    transition: all .3s;
    cursor: pointer;

    &:hover {
      transform: rotate(90deg);
    }

    &:hover &-icon {
      fill: $dark;
    }

    &-icon {
      fill: $gray;
      max-width: 1.6rem;
      max-height: 1.6rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all .3s;
      cursor: pointer;
    }
  }

  [data-modal-win][hidden] {
    display: block !important;
  }

  &--product-detail {
    //transition: none;
    animation: none;

    &.is-hidden {
      pointer-events: none;
      opacity: 0;
      //visibility: hidden;

      transition: opacity 0.3s linear;
    }

    &.is-active {
      #{$self}__main {
        transition: none;
        animation: slideDown .3s forwards;
      }
    }

    &.is-modal-win-opened {
      #{$self}__main {
        animation: slideUp .3s forwards;
      }

      #{$self}__main--invitation {
        animation-duration: 1.5s;
      }
    }
  }
}

body.is-modal-opened {
  overflow: hidden;
}

.modal-content {
  &__header {
    margin-bottom: 3rem;
  }

  &__heading {
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: bold;
    color: $black;
    line-height: 1.4;
    font-family: $headings-fonts-stack;
  }
}

.modal-win {
  .product-detail-carousel__img {
    position: static;
    //transform: none;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: auto;
    display: block;
    margin: auto;
    transition: transform 0.3s ease;
    backface-visibility: hidden;
    transform-origin: center center;
    transform: scale(1);
  }


  .product-detail-carousel__img-wrap {
    -webkit-user-drag: none;
    user-select: none;
    touch-action: none;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    cursor: zoom-in;
  }

  .product-detail-carousel__img-wrap::before {
    display: none;
  }
  .image-enlarge-on-hover:hover {
    transform: scale(2);
  }

  .product-detail__pane {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    pointer-events: none;
    user-select: none;
    
    @include media($md) {
      left: 2rem;
      right: 2rem;
    }

    // display: none;

    // @include media($md) {
    //   display: block;
    // }
  }
}