.discount {
  padding: 1.2rem 1.6rem;
  color: $black;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.4;
  align-self: flex-start;
  border-radius: 2.4rem 0 2.4rem 0;
  z-index: 1;
  font-family: var(--inter-font), Arial, sans-serif;
  
  @include media($md) {
    font-size: 1.4rem;
  }
}