.breed-detail {
  &__btn-back {
    margin-bottom: 2.5rem;

    @include media($md) {
      margin-bottom: 5rem;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;

    @include media($md, false) {
      gap: 5rem;
    }
  }

  &__col-img {
    flex: 0 0 100%;
    max-width: 100%;

    @include media($md) {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }

  &__col-info {
    flex: 1;
    
    @include media($md) {
      margin-left: 12.5rem;
    }
  }

  &__img-wrap {
    position: relative;
    border-radius: 12px;
    border: 0.481px solid $air-blue;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);

    &::before {
      content: "";
      padding-top: 100%;
      display: block;
    }

    @include media($md, false) {
      max-width: 48rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__header {
    margin-bottom: 2rem;
    
    @include media($md) {
      margin-bottom: 2.5rem;
    }
  }

  &__suptitle {
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    color: $black;
    padding: 0.3rem 1.5rem;
    background-color: $air-blue;
    border-radius: 0.8rem;
    margin-bottom: 1rem;
    display: inline-block;
    
    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 1.3;
    margin-bottom: 1rem;
    color: $black;
    
    @include media($md) {
      font-size: 4.8rem;
    }
  }

  &__subtitle {
    font-weight: 300;
    font-size: 1.6rem;

    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    
    @include media($md) {
      font-size: 1.4rem;
      margin-bottom: 3rem;
    }
    
    @include media($xl) {
      font-size: 1.6rem;
    }
  }

  &__main-info {
    margin-bottom: 2.5rem;
    
    @include media($md) {
      margin-bottom: 3.5rem;
    }
  }
}

.main-info {
  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.3;
    margin-bottom: 1.5rem;
    
    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: minmax(0, 29%) 1fr;
    align-items: center;
    column-gap: 2.5rem;
    row-gap: 1rem;
    
    @include media($md) {
      grid-template-columns: minmax(0, 40%) 1fr;
    }
  }

  &__condition,
  &__value {
    font-size: 1.3rem;
    
    @include media($md) {
      font-size: 1.4rem;
    }
  }

  &__condition {
    color: $gray;
  }

  &__value {
    font-weight: 600;    
  }
}

.health-issues {
  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.3;
    margin-bottom: 1.5rem;
    
    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__icon {
    width: 2.4rem;
    height: 2.4rem;
    
    @include media($md) {
      width: 3.2rem;
      height: 3.2rem;
    }
  }

  &__text {
    font-weight: 600;
    font-size: 1.3rem;
    margin-left: 1rem;
    
    @include media($md) {
      font-size: 1.4rem;
      margin-left: 0.8rem;
    }
  }
}