.section {
  padding: 3.5rem 0;
  position: relative;

  @include media($md) {
    padding: 4.2rem 0;
  }

  &__header {
    margin-bottom: 3rem;
    
    @include media($md) {
      margin-bottom: 4rem;
    }
  }
  &.intro-section {
    padding: 0;
  }

  &.products-section {
    padding: 2rem 0;

    @include media($md) {
      padding-top: 4rem;
      padding-bottom: 3rem;
    }
  }

  &.blog-section {
    @include media($md) {
      padding-top: 4rem;
      padding-bottom: 2.5rem;
    }
  }

  &.contacts {
    padding-top: 6.5rem;

    @include media($md) {
      padding-top: 8.5rem;
    }
  }

  &.section-approach {
    padding-top: 3rem;
    padding-bottom: 2rem;

    @include media($md) {
      padding-top: 5rem;
      padding-bottom: 4rem;
    }
  }

  &.has-extra-side-gaps {
    padding-left: 3rem;
    padding-right: 3rem;

    @include media($md) {
      padding-left: 0;
      padding-right: 0;
    }

    .report-section {
      &__decor {
        margin-left: -3rem;
        margin-right: -3rem;

        @include media($md) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  &.product-info {
    padding-bottom: 5rem;
    
    @include media($md) {
      padding-bottom: 8rem;
    }
  }
}

.section-header {
  max-width: 99rem;
  margin-left: auto;
  margin-right: auto;

  &__caption {
    margin-bottom: 1.5rem;
    display: inline-block;
  }

  &__heading {
    margin-bottom: 0;
  }
  
  &__desc,
  &__content {
    margin-top: 0.2rem;
    color: $gray;
    font-weight: 300;
    font-size: 1.6rem;

    @include media($md) {
      max-width: 79rem;
      margin-left: auto;
      margin-right: auto;
    }

    @include media($xxl) {
      font-size: 1.9rem;
      // margin-top: 1.5rem;
    }

    > *:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &.is-limit {
      max-width: 79rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__more,
  &__btns {
    margin-top: 2rem;
  }

  &__btns {
    display: inline-flex;
    gap: 1.5rem;
    flex-wrap: wrap;
  }

  &__more {
    @include media($md, false) {
      padding: 1.2rem 2rem;
    }

    @include media($md) {
      &.btn--purple-reverse {
        font-size: 1.6rem;
        padding: 1.3rem 3.2rem;
      }
    }
  }
}

//============ Section Heading ============//
.section-suptitle,
.section-caption {
  padding: 0.5rem 1.5rem;
  background-color: $air-blue;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.4;
  border-radius: 0.8rem;
  align-self: center;
  color: $black;
  
  @include media($md) {
    font-size: 1.4rem;
  }
}

.section-caption-sm {
  padding: 0.5rem 1.5rem;
  background-color: $air-blue;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.4;
  border-radius: 0.8rem;
  align-self: center;
  color: $black;

  @include media($md) {
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
  }
}

.section-heading {
  position: relative;
  font-family: var(--codec-pro-font), Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 1.3;

  @include media($md) {
    font-size: 4.8rem;
  }
}

.section-description {
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-top: 2.5rem;
  max-width: 73rem;
  text-align: center;

  @include media($md) {
    font-size: 1.9rem;
    line-height: 1.4;
  }
}

.section-heading--sm {
  font-family: var(--codec-pro-font), Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 1.3;

  @include media($md) {
    font-size: 3.4rem;
  }
}

.section-description--sm {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 1rem;

  @include media($md) {
    font-size: 1.6rem;
  }
}

//============ Section Heading ============//

//============ Main Heading ============//
.main-heading {
  font-family: var(--codec-pro-font), Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 1;
  color: $black;
  
  @include media($md) {
    font-size: 7.2rem;
  }

  &.is-sm {
    font-size: 3rem;
    
    @include media($md) {
      font-size: 4.8rem;
    }
  } 
}
//============ Main Heading ============//

//============ Order ============//
.is-order-1 {
  @include media($md) {
    order: 1;
  }
}

.is-order-2 {
  @include media($md) {
    order: 2;
  }
}
//============ Order ============//

//============ Color ============//
.is-air-blue {
  background: $air-blue;
}

.is-white {
  background-color: $white;
}

.is-fill-air-blue {
  fill: $air-blue;
}

.is-fill-white {
  fill: $white;
} 

.is-color-white {
  color: $white;
}

.is-color-air-blue {
  color: $air-blue;
}
//============ Color ============//

//============ Column ============//
.col-6 {
  flex: 0 0 100%;
  max-width: 100%;

  @include media($md) {
    --flex-items: 2;
    --flex-gap: 3rem;

    flex: 0 0 calc((100% / var(--flex-items)) - (((var(--flex-items) - 1) / var(--flex-items)) * var(--flex-gap)));
    max-width: calc((100% / var(--flex-items)) - (((var(--flex-items) - 1) / var(--flex-items)) * var(--flex-gap)));
  }
}

.col-4 {
  flex: 0 0 calc((100% / var(--flex-items)) - (((var(--flex-items) - 1) / var(--flex-items)) * var(--flex-gap)));
  max-width: calc((100% / var(--flex-items)) - (((var(--flex-items) - 1) / var(--flex-items)) * var(--flex-gap)));
  --flex-items: 1;
  --flex-gap: 3rem;

  @include media($md) {
    --flex-items: 3;
    --flex-gap: 3rem;
  }
}
//============ Column ============//

section {
  &.section-bottom-wrap {
    padding-bottom: 0;
    padding-top: 1.5rem;

    @include media($md) {
      padding-top: 4rem;
    }
  }
}

.gray-area {
  background: $air-blue;
  position: relative;

  padding-top: 6rem;

  @include media($sm) {
    padding-top: 10rem;
  }

  @include media($md) {
    margin-top: -6rem;
    padding-top: 12rem;
  }

  @include media($xl) {
    margin-top: -10rem;
    padding-top: 20rem;
  }
}

.is-negative-margin {
  margin-top: -4rem;
  
  @include media($md) {
    margin-top: -5rem;
  }
}

.is-white {
  background-color: $white;
}

.is-light-blue {
  background-color: $light--blue;
}

.is-salmon {
  background-color: $salmon;
}

.offertag {
  padding: 0.7rem 1.2rem 0.6rem;
  background-color: #FDCB4A;
  color: $black;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  display: inline-block;

  transform: translateY(-2px);
  
  // &__text {
  //   white-space: nowrap;
  // }

  strong,
  b {
    font-weight: 600;
  }
}

.is-yellow {
  background: #FDCB4A;
}

.CookieConsent {
  margin: 0 0 1.2rem 1.2rem;

  @include media($sm, false) {
    width: auto !important;
  }

  @include media($sm) {
    font-size: 1.4rem !important;
    padding: 2.4rem !important;
    width: 100%;
  }

  @include media($md, false) {
    border-radius: 16px !important;
  }

  div {
    &:first-child {
      flex: 0 1 auto !important;
      margin: 0 0 1.2rem 0 !important;
      max-width: 22rem;
    }
  }

  .btn--primary {
    transform: translateX(14.5rem);

    @include media($sm) {
      transform: translateX(15.7rem);
    }

    &:hover {
      background-color: #524dd9 !important;
      border-color: #524dd9 !important;
    }

    @include media($sm) {
      padding: 1rem 2.4rem !important;
      font-size: 1.4rem !important;
    }
  }

  &__link {
    position: absolute !important;
    bottom: 1.6rem;
    left: 1.5rem;

    @include media($sm) {
      padding: 1rem 2.4rem !important;
      font-size: 1.4rem !important;
      bottom: 2.4rem;
      left: 2.5rem;
    }
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    width: 4rem;
    height: 4rem;
    display: flex;
    color: #30414C;
    transition: color .3s ease-in-out;

    &:hover {
      color: rgb(96, 91, 255);
    }

    &-icon {
      margin: auto;
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}

//.CookieConsent > div {
//  margin: 0 !important;
//  flex: inherit !important;
//}