.testimonial-section {
  &__main {
    border-radius: 3.2rem;
    border: 1px solid $air-blue;
    background: $white;

    padding: 3.2rem 1.5rem 4.5rem;

    box-shadow: 0 0 5rem 0 rgba($black, 0.03);

    @include media($md) {
      padding: 6.5rem 3rem;
    }
  }
}

.testimonial {
  max-width: 79rem;
  margin: 0 auto;
  text-align: center;

  &__description {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.3;
    font-family: var(--codec-pro-font);
    
    @include media($md) {
      font-size: 2.3rem;
    }

    > *:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__card {
    margin-top: 2.5rem;
    flex-direction: column;

    @include media($md) {
      margin-top: 3rem;
    }
  }

  .team-card {
    box-shadow: none;
    align-items: center;
    padding: 0;
    background: none;
    border-radius: 0;
    display: block;
    
    &__img-wrap {
      width: 4.2rem;
      height: 4.2rem;
      margin: 0 auto;
      margin-bottom: 1rem;

      @include media($md) {
        width: 6.4rem;
        height: 6.4rem;
      }
    }

    &__info {
      align-items: center;
    }

    &__name {
      font-size: 1.4rem;
      
      @include media($md) {
        font-size: 1.6rem;
      }
    }

    &__position {
      font-size: 1.3rem;
      
      @include media($md) {
        font-size: 1.4rem;
      }
    }
  }
}

// .partners-logo {
//   max-width: 38rem;
//   margin-left: auto;
//   margin-right: auto;

//   &__items {
//     display: flex;
//     justify-content: space-between;
//     border-top: 2px solid $air-blue;
//     margin: 0 1.4rem;

//     @include media($md) {
//       margin: 0;
//     }
//   }

//   &__item {
//     position: relative;

//     &.is-active {
//       &::before {
//         content: "";
//         width: 100%;
//         height: 0.2rem;
//         background-color: $primary-color;
//         position: absolute;
//         top: -0.2rem;
//         left: 0;
//       }
//     }

//     &:not(.is-active) {
//       cursor: pointer;
//     }
//   }

//   &__img-wrap {
//     width: 6rem;
//     padding: 1.7rem 0.5rem 1rem 0.5rem;
//     display: block;

//     @include media($xs) {
//       padding: 3.2rem 1rem 2rem;
//       width: 10rem;
//     }
//   }

//   &__img {
//     width: 100%;
//     height: auto;
//     display: block;
//     cursor: pointer;
//   }

//   &__pagination {
//     margin-top: 0;
//     margin-bottom: 1rem;
//     display: flex;
//     width: 100%;
//     height: 0.2rem;
//     background-color: #F0F2F7;;
//   }

//   .swiper-pagination-bullet {
//     width: 6.5rem;
//     height: 0.2rem;
//     border-radius: 0;
//     border: none;
//     margin: 0;
//     flex: 1;

//   }

//   .swiper-pagination-bullet.swiper-pagination-bullet-active {
    
//     &::after {
//       width: 6.5rem;
//       height: 0.2rem;
//       background-color: $primary-color;
//       position: absolute;
//       border-radius: 0;
//       left: 0;
//       top: 50%;
//       transform: translate(0, -50%);
//     }
//   }
// }
