.video_player_block {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  .video_player {
    min-width: 800px;
    position: relative;
    height: 100%;
    margin: auto;

    iframe {
      width: 100%;
      height: 450px;
    }
  }
}

.is-video-showed {
  overflow: hidden;
}