.navbar {
  $self: &;

  display: none;
  align-items: center;
  flex: 1;

  @include media($md) {
    display: flex;
  }

  /* First Level */
  &__list {
    display: flex;
    font-size: 1.6rem;
    height: 100%;
    line-height: 1.1;
    flex-grow: 1;

    @include media($md) {
      align-items: center;
      justify-content: center;
    }

    @include media($lg) {
      margin-left: 4.5rem;
      justify-content: flex-start;
    }
  }

  &__item {
    height: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid $air-blue;

    &:last-child {
      border-bottom: 1px solid $air-blue;
    }

    @include media($md) {
      border-top: none;

      &:last-child {
        border-bottom: none;
      }

      &:not(:last-child) {
        margin-right: 2.5rem;
      }
    }

    @include media($xl) {
      &:not(:last-child) {
        margin-right: 3.5rem;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: $white;
      z-index: -1;
      transition: opacity 0.3s ease-in-out;

      visibility: hidden;
      opacity: 0;
    }

    // &:hover,
    &.is-hovered {
      &::after {
        opacity: 1;
        visibility: visible;
      }

      &::before {
        content: '';
        width: 100%;
        height: 3.5rem;
        position: absolute;
        bottom: -0.1rem;
        left: 0;
      }

      #{$self}__icon {
        transform: rotate(-180deg);
      }

      #{$self}__link {
        color: $primary-color;
      }

      #{$self}__dropdown {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
      }
    }
  }

  &__link {
    color: $black;
    display: flex;
    padding: 0.5rem 1.5rem;
    transition: all 0.3s;
    position: relative;
    align-items: center;
    line-height: 1.3;
    font-weight: 600;
    cursor: pointer;

    @include media($md) {
      padding: 0.8rem 0;

      &:hover {
        //color: $primary-color;

        &::before {
          background: $primary-color;
        }

        // #{$self}__icon {
        //   fill: $primary-color;
        // }

        .navbar__dropdown {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &__text {
    display: inline-block;
    vertical-align: middle;
  }

  &__icon {
    // fill: $black;
    width: 1rem;
    height: auto;
    margin-left: 1rem;
    transition: all 0.3s;
  }
  /* First Level */

  &__dropdown {
    position: absolute;
    top: calc(100% + 0.1rem);
    left: 0;
    background-color: $white;
    width: 100%;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 1rem 1rem -1rem rgba(48, 65, 76, 0.3);

    .container-fluid {
      padding: 0 1.5rem;
    }
  }

  &__dropdown-content {
    padding: 4rem 0 4rem 0;
    // display: flex;
    gap: 0 3rem;

    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));

    &.is-padding {
      padding: 1.5rem 0 4rem 0;
    }
  }

  &__dropdown-bottom {
    border-top: 1px solid $air-blue;
    padding: 3.7rem 0 6rem;
  }
}

.nav-dropdown {
  $self: &;

  &__col {
    @include media($md) {
      flex: 1;
      min-width: 0;
      max-width: 38rem;
    }
  }

  &__products-wrap-col {
    grid-row: 1 / 5;
    grid-column: span 7;

    // ~ #{$self}__library {
    //   flex-grow: 0;
    //   margin-left: auto;
    // }
  }

  &__products-wrap {
    // padding: 2.4rem;
    padding: 2.4rem 3.6rem;
    padding-left: 3.4rem;
    border-radius: 3.2rem;
    background-color: #f8f9fb;
    // flex: 0 0 68%;
    // max-width: 68%;
    display: inline-block;
  }

  &__products {
    display: flex;

    .nav-dropdown__title {
      opacity: 1;
    }
  }

  // &__col-left {
  //   margin-right: auto;
  // }

  &__promo-card-wrap {
    display: flex;
    // flex-direction: column;
    // margin-top: 3.5rem;
    column-gap: 2.5rem;

    // > *:not(:last-child) {
    //   margin-bottom: 4.3rem;
    // }
  }

  &__title {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.4;
    color: $gray;
    margin-bottom: 2.5rem;
    opacity: 0.5;
  }

  &__nav-merch {
    margin-left: auto;
  }

  &__item {
    @include media($md) {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__link {
    font-family: var(--codec-pro-font);
    display: inline-flex;
    font-weight: 500;
    font-size: 1.9rem;
    line-height: 1.3;
    color: $dark-accent--default;
    transition: all 0.3s linear;
    align-items: center;
    align-self: flex-start;

    &:hover {
      color: $primary-color;
    }

    &.is-disabled {
      &:hover {
        color: $dark-accent--default;
      }

      .nav-dropdown__text {
        opacity: 0.5;
      }
    }

    // &.promo-card {
    //   margin-left: 1rem;
    // }

    // &.promo-card--column {
    //   margin-top: 1.5rem;
    // }
  }

  &__icon {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 0.7rem;

    @include media($md) {
      width: 1.8rem;
      height: 1.8rem;
      margin-left: 1rem;
    }
  }

  .nav-dropdown__products-wrap-col ~ .nav-dropdown__library {
    padding-top: 2.4rem;
    // padding-right: 2rem;
    // min-width: 21rem;
    // padding-left: 7rem;
    
    @include media($md) {
      grid-column: span 3;
      padding-left: 2rem;

      + .nav-dropdown__library {
        margin-top: 2rem;
      }
    }
    
    @include media($lg) {
      padding-left: 3rem;
    }
    
    @include media($xl) {
      padding-left: 6rem;
    }
  }

  &__library {
    // flex-grow: 1;
    
    @include media($md) {
      grid-column: span 2;
    }

    &:first-child {
      max-width: 38rem;
      padding-top: 2.5rem;

      ~ #{$self}__library {
        max-width: 38rem;
        padding-top: 2.5rem;
      }
    }
  }

  &__library-item {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__badge {
    align-self: center;
    margin-left: 1.5rem;
  }

  &__footer {
    margin-top: 3.35rem;
  }
}

// .nav-merch {
//   max-width: 28rem;
//   width: 100%;
//   background-color: $white;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   border-radius: 2.4rem;
//   padding: 2.5rem;
//   transition: all 0.3s linear;

//   &:hover {
//     box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
//   }

//   &__img-wrap {
//     position: relative;
//     max-width: 12rem;
//     margin: 0 auto 3rem;
//     width: 100%;
//     // margin-bottom: 3rem
//     // flex: 1;

//     &::before {
//       content: '';
//       padding-top: 100%;
//       display: block;
//     }
//   }

//   &__img {
//     width: 100%;
//     height: 100%;
//     object-fit: contain;
//     position: absolute;
//     top: 0;
//     left: 0;
//   }
// }

.promo-card {
  position: relative;

  &__img-wrap {
    max-width: 8rem;
    position: relative;
    margin-right: 1rem;
    width: 100%;
  }

  &__img {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    z-index: 1;

    // @include media($md) {
    //   transform: scale(1.3);
    // }
  }

  &.promo-card--column {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;

    .promo-card__img-wrapper {
      border-radius: 1.6rem;
      background: $white;
      padding: 2.5rem;
      
      @include media($md) {
        min-height: 18rem;
        display: flex;
        align-items: center;
      }
    }

    .promo-card__img-wrap {
      // margin: 0 2rem;
      max-width: 18rem;
      margin-right: 0;
    }

    .promo-card__text {
      font-size: 1.6rem;
      
      @include media($md) {
        font-family: var(--inter-font), Arial, sans-serif;
        margin-top: 1.5rem;
        font-weight: 600;
      }
    }
  }

  .discount {
    font-size: 1.2rem;
    padding: 0.8rem 1.6rem;
    border-radius: 1.6rem 0 1.6rem 0;
  }
}

/* ----------- Navbar Toggle ----------- */
.navbar-trigger {
  $self: &;
  min-width: 7rem;
  width: 1.4rem;
  height: 1.2rem;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  position: relative;
  margin-left: 0.5rem;

  @include media($md) {
    display: none;
  }

  &::before {
    content: '';
    height: 0.2rem;
    width: 1.3rem;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    background: $black;
    border-radius: 0.1rem;
  }

  &__line {
    position: absolute;
    height: 0.2rem;
    background: $black;
    transition: all 0.5s ease-in-out;
    left: 0;
    border-radius: 0.1rem;
    width: 1.3rem;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      bottom: 0;
    }
  }

  @at-root .is-navbar-opened {
    .navbar-trigger {
      &::before {
        display: none;
      }

      #{$self}__line:nth-child(1),
      #{$self}__line:nth-child(2) {
        left: 1.2rem;
        top: 50%;
      }

      #{$self}__line:nth-child(1) {
        transform: translate(-100%, -100%) rotate(45deg);
      }

      #{$self}__line:nth-child(2) {
        transform: translate(-100%, -100%) rotate(-45deg);
      }
    }
  }
}
/* ----------- Navbar Toggle ----------- */

body.is-navbar-opened {
  @include media($md, false) {
    overflow: hidden;

    .header {
      margin-top: 0;
      transition: none;

      margin-top: calc(-1 * var(--announcementBarHeight));

      &__main {
        position: relative;
        z-index: 12;
        margin-top: 0;
      }

      &__announce {
        // display: none;
      }
    }
  }
}

.is-navbar-opened {
  .mob-panel {
    transform: translate3d(0, 0, 0);
  }
}

/**
 * First Level Extension
 */
.navbar__link > .nav-dropdown__link {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
