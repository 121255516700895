/**
 * Buttons
 * =================================
 */
// Btn Base
$btn-radius: 1.2rem !default;

// Btn Primary
$btn-primary-color: $white !default;
$btn-primary-bg: $primary-color !default;
$btn-primary-hover: mix(black, $primary-color, 15%) !default;
$btn-primary-active: mix(black, $primary-color, 25%) !default;

// Btn Secondary
$btn-secondary-color: $white !default;
$btn-secondary-bg: $secondary-color !default;
$btn-secondary-hover: mix($black, $secondary-color, 15%) !default;
$btn-secondary-active: mix($black, $secondary-color, 25%) !default;

// Btn Tertiary
$btn-tertiary-color: $white !default;
$btn-tertiary-bg: $tertiary-color !default;
$btn-tertiary-hover: mix($black, $tertiary-color, 15%) !default;
$btn-tertiary-active: mix($black, $tertiary-color, 25%) !default;

// btn dark
$btn-dark-color: $white !default;
$btn-dark-bg: $black !default;
$btn-dark-hover: mix($black, $tertiary-color, 15%) !default;
$btn-dark-active: mix($black, $white, 15%) !default;

//btn dark-reverse
$btn-dark-reverse-color: $dark-accent--default !default;
$btn-dark-reverse-bg: $white !default;
$btn-dark-reverse-hover: mix($black, $tertiary-color, 15%) !default;
$btn-dark-reverse-active: mix($black, $tertiary-color, 15%) !default;

// Btn Gray
$btn-gray-color: $white !default;
$btn-gray-bg:  rgba(67, 66, 97, 0.15) !default;
$btn-gray-hover: $dark-accent--default !default;
$btn-gray-active: #e6e6e6 !default;

// Btn Grad
$btn-grad-bg: linear-gradient(to right, #ff5e4a 0%, #ffc544 100%) !default;
$btn-grad-color: $white !default;
$btn-grad-hover: #ff5e4a !default;

// Btn Danger
$btn-danger-bg: $error !default;
$btn-danger-color: $white !default;
$btn-danger-hover: mix(black, $btn-danger-bg, 15%) !default;
$btn-danger-active: mix(black, $btn-danger-bg, 25%) !default;

.btn,
.ais-ClearRefinements-button {
  $self: &;
  
  background: transparent;
  color: $primary-color;
  border: .1rem solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 1.8rem;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: inherit;
  line-height: 1.3;
  cursor: pointer;
  user-select: none;
  outline: none;
  border-radius: 0.8rem;
  white-space: nowrap;
  transition: all .3s ease-in-out;
  position: relative;
  text-align: center;
  gap: .8rem;

  @include media($md) {
    font-size: 1.4rem;
    padding: 1rem 2.4rem;
    border-radius: $btn-radius;
  }

  &__text {
    //transition: inherit;

    + #{$self}__icon {
      margin-left: 1rem;
    }
  }
  
  &__icon {
    fill: currentColor;
    width: 1.6rem;
    height: 1.6rem;
    flex: none;

    @include media($md) {
      width: 1.8rem;
      height: 1.8rem;
    }

    + #{$self}__text {
      margin-left: 1rem;
    }
  }

  &.has-icon {
    padding-left: 1.6rem;
    padding-right: 2rem;
  }

  &:not([class*="btn-"]) {
    &:hover {
      text-decoration: underline;
    }
  }

  /*
   *
   * Buttons Disabled
   *
   */
  &[disabled],
  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: .3;
  }

  /*
   *
   * Buttons Types 
   *
   */
  &--primary {
    color: $btn-primary-color;
    background: $btn-primary-bg;

    &:hover {
      background: $btn-primary-hover;
      border-color: $btn-primary-hover;
    }

    &:focus {
      box-shadow: 0 0 0 .2rem #21D7FF;
      border-color: $btn-primary-bg;
    }

    // &:active {
      // background: $btn-primary-active;
    // }

    &-reverse {
      color: $btn-primary-bg;
      border-color: $btn-primary-bg;

      &:hover {
        color: $btn-primary-color;
        background: $btn-primary-bg;
        border-color: $btn-primary-bg;
      }

      &:focus {
        box-shadow: 0 0 0 .2rem #21D7FF;
        border-color: $btn-primary-bg;
      }

      &:active {
        background: $btn-primary-color;
        border-color: $btn-primary-active;
        color: $btn-primary-bg;
      }
    }
  }

  &--secondary {
    color: $btn-secondary-color;
    background: $btn-secondary-bg;

    &:hover,
    &:focus {
      background: $white;
      color: $secondary-color;
      border: 1px solid $secondary-color;
    }

    &:active {
      background: $btn-secondary-active;
    }

    &-reverse {
      color: $btn-secondary-bg;
      border-color: $btn-secondary-bg;

      &:hover,
      &:focus {
        color: $btn-secondary-color;
        background: $btn-secondary-bg;
        border-color: $btn-secondary-bg;
      }

      &:active {
        background: $btn-secondary-active;
        border-color: $btn-secondary-active;
      }
    }
  }

  &--dark {
    color: $btn-dark-color;
    background: $btn-dark-bg;

    &:hover,
    &:focus {
      background: $white;
      border-color: $btn-dark-bg;
      color: $dark;
    }

    &:active {
      background: $btn-primary-active;
    }

    &-reverse {
      color: $btn-dark-reverse-color;
      background: $btn-dark-reverse-bg;
      border-color: $btn-dark-reverse-color;

      &:hover {
        background: $btn-dark-reverse-color;
        color: $white;
      }

      &:focus {
        box-shadow: 0 0 0 .2rem #21D7FF;
        color: $btn-dark-reverse-color;
        background: $btn-dark-reverse-bg;
      }
    }
  }

  &--gray {
    background: $btn-gray-bg;
    color: $btn-gray-color;

    &:hover,
    &:focus {
      background: $btn-gray-hover;
    }

    &:active {
      background: $btn-primary-active;
    }

    &-reverse {
      color: $dark-accent--default;
      border-color: $btn-gray-bg;

      &:hover {
        border-color: $btn-gray-hover;
      }

      &:focus {
        box-shadow: 0 0 0 .2rem #21D7FF;
        border-color: transparent;
      }

      // &:active {
      //   background: transparent;
      //   color: $white;
      // }
    }
  }

  &--purple {
    background-color: $purple;
    border-color: $purple;

    &-reverse {
      border-color: $purple;

      &:hover {
        border-color: $primary-color;
      }

      &:focus {
        box-shadow: 0 0 0 .2rem #21D7FF;
        border-color: transparent;
      }
    }
  }
  
  /*
   *
   * Button Link
   *
   */
  &-link {
    $self: &;

    font-weight: 600;
    line-height: 1.2;
    border: 1.5px solid transparent;
    font-size: 1.4rem;
    transition: all 0.3s linear;
    display: inline-flex;
    align-items: center;

    &__icon {
      width: 1.4rem;
      height: 1.4rem;
      flex: none;
      
      @include media($md) {
        width: 1.6rem;
        height: 1.6rem;
      }
      
      + #{$self}__text {
        margin-left: .7rem;
      }
    }

    &__text {
      + #{$self}__icon {
        margin-left: .7rem;
      }
    }

    &__icon {
      margin-right: .7rem;
    }
  }

  &--primary-link {
    color: $primary-color;
    border-bottom-color: rgba(96, 91, 255, 0.20);

    &:hover {
      border-bottom-color: $primary-color;
    }
  }

  &--purple-link {
    color: $dark-accent--default;
    border-bottom-color: rgba(67, 66, 97, 0.15);
    
    &:hover {
      border-bottom-color: $dark-accent--default;
    }
  }

  &--gray-link {
    color: $gray;
    border-bottom-color: $air-blue;
    
    &:hover {
      border-bottom-color: $gray;
    }
  }

  /*
   *
   * Buttons Sizes
   *
   */
  &--xs {
    padding: 0.7rem;
    
    @include media($md) {
      padding: 1rem;
    }
  }

  &--sm {
    font-size: 1.4rem;
    padding: .8rem 1rem;

    #{$self}__icon {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  &--lg {
    font-size: 1.4rem;
    padding: 1rem 2.6rem;
    
    @include media($md) {
      font-size: 1.6rem;
      padding: 1.3rem 3.2rem;
    }
  }

  /*
   *
   * Buttons extra
   *
   */
  &--block {
    width: 100%;
    padding-left: .5rem;
    padding-right: .5rem;

    & + & {
      margin-top: 1.5rem;
    }
  }

  &--tertiary-more,
  &--secondary-more {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &:hover {
      &::after {
        visibility: hidden;
        opacity: 0;
      }
    }

    &::after {
      content: '';
      width: 100%;
      height: 0.1rem;
      position: absolute;
      bottom: 0.3rem;
      left: 0;
      transition: all 0.3s linear;
    }
  }

  &--tertiary-more {
    color: $tertiary-color;
    &::after {
      background-color: $tertiary-color;
    }
  }

  &--dark-more {
    max-width: 100%;
    color: $black;

    padding-left: 0;
    padding-right: 0;

    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    text-decoration: underline;
    transition: all .3s;
    text-underline-offset: .5rem;
    text-decoration-color: currentColor;

    &:hover {
      text-decoration: underline;
      text-decoration-color: transparent;
    }
  }

  &--secondary-more {
    color: $secondary-color;

    &::after {
      background-color: $secondary-color;
    }
  }

  &--secondary-more {
    color: $secondary-color;

    &::after {
      background-color: $secondary-color;
    }
  }
}

.ais-ClearRefinements-button {
  border-color: $purple;

  &:hover {
    border-color: $primary-color;
    text-decoration: none !important;
  }

  &:focus {
    box-shadow: 0 0 0 .2rem #21D7FF;
    border-color: transparent;
  }
}

.link--sm {
 font-size: 1.2rem;
}

.link--md {
  font-size: 1.3rem;
}

.link--lg {
  font-size: 1.3rem;
  
  @include media($md) {
    font-size: 1.4rem;
  }
}

.link--xl {
  font-size: 1.4rem;
  
  @include media($md) {
    font-size: 1.6rem;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
  pointer-events: none;
}

.radio-button {
  $self: &;

  &__input {
    position: absolute;
    left: -9999px;
    top: -9999px;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    width: 1px;
    height: 1px;
  }

  &__label {
    position: relative;
    padding-left: 3rem;
    cursor: pointer;
    font-size: 1.4rem;
    display: inline-block;

    &::before {
      content: "";
      width: 1.8rem;
      height: 1.8rem;
      border: 2px solid $black;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @include media($md) {
        width: 2rem;
        height: 2rem;
      }
    }

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__input {

    &:checked {

      +#{$self}__label {

        &::before {
          background-color: $black;
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.9766 0.648438C13.332 0.976562 13.332 1.55078 12.9766 1.87891L5.97656 8.87891C5.64844 9.23438 5.07422 9.23438 4.74609 8.87891L1.24609 5.37891C0.890625 5.05078 0.890625 4.47656 1.24609 4.14844C1.57422 3.79297 2.14844 3.79297 2.47656 4.14844L5.34766 7.01953L11.7461 0.648438C12.0742 0.292969 12.6484 0.292969 12.9766 0.648438Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
          background-position: center;
          background-size: 1.2rem;
        }
      }
    }
  }
}

.btn-play {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include media($md) {
    width: 6rem;
    height: 6rem;
  }

  &__icon {
    width: 2.1rem;
    height: 2.4rem;
    color: $white;
    transform: translateX(1px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    
    @include media($md) {
      width: 2.6rem;
      height: 3rem;
    }
  }
}

