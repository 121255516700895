.breadcrumbs {
  $self: &;

  font-size: 1.4rem;
  padding: 8rem 0 3rem;
  white-space: nowrap;

  @include media($md) {
    padding: 11rem 0 4rem;        
  }

  &__items {
    display: flex;
    align-items: center;
    row-gap: 1rem;
    overflow: hidden;
  }

  &__item {
    position: relative;
    min-width: 0;
    flex: none;
    text-transform: capitalize;

    &:not(:last-child) {
      margin-right: 3rem;
    }

    &:first-child {
      #{$self}__link {
        border-bottom: none;
      }
    }

    &:last-child {
      flex: 1;

      #{$self}__link {
        color: $black;
      }
    }

    &:not(:last-child) {
      &::before {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.96967 11.0303C0.676777 10.7374 0.676777 10.2626 0.96967 9.96967L4.93934 6L0.96967 2.03033C0.676776 1.73744 0.676776 1.26256 0.969669 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L6.53033 5.46967C6.82322 5.76256 6.82322 6.23744 6.53033 6.53033L2.03033 11.0303C1.73744 11.3232 1.26256 11.3232 0.96967 11.0303Z' fill='%2350585E'/%3E%3C/svg%3E%0A");
        width: 1rem;
        height: 1rem;
        position: absolute;
        top: 50%;
        right: -2rem;
        transform: translateY(-50%);
      }
    }
  }

  &__link {
    @include trim;
  }

  svg {
    transform: translateY(-.2rem);
  }
}

.breadcrumbs + .section {
  padding-top: 0 !important;
}