.media-card {
  $self: &;
  display: flex;
  flex-direction: column;
  transition: all 0.3s linear;
  border: 1px solid transparent;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.05);
  height: 100%;

  &:hover {
    box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.1);
    border: 1px solid $purple;

    #{$self}__title,
    #{$self}__family,
    #{$self}__breed {
      color: $primary-color;
    }

    #{$self}__icon {
      opacity: 1;
    }
  }

  &__img-wrap {
    position: relative;
    margin: 1.5rem;
    
    @include media($md) {
      margin: 2.5rem;
    }

    &::before {
      content: '';
      width: 100%;
      padding-top: 100%;
      display: block;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    image-rendering: -webkit-optimize-contrast;
  }

  &__description {
    text-align: center;
    padding: 0 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    @include media($md) {
      padding: 0 2.5rem 2.5rem;
    }    
  }

  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.3;
    margin-bottom: 1rem;
    color: $black;
    align-self: center;
    transition: all 0.3s linear;

    display: -webkit-box;
    -webkit-box-orient: vertical;     
    -webkit-line-clamp: 2;
    overflow: hidden;
    
    @include media($md) {
      font-size: 2.3rem;
    }
  }

  &__family,
  &__breed {
    text-align: start;
    transition: all 0.3s linear;
  }

  &__family {
    font-size: 1.3rem;
    color: $gray;
    margin-bottom: 0.5rem;

    @include media($md) {
      font-size: 1.4rem;
    }
  }

  &__breed {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    line-height: 1.3;
    font-size: 1.6rem;
    color: $black;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    
    @include media($md) {
      font-size: 1.9rem;
    }
  }


  &__old-price {
    color: $gray;
    font-size: 1.6rem;
    font-weight: 500;
    text-decoration: line-through;

    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__price {
    margin: 1rem;
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 1.3;
    color: $primary-color;
    margin-top: auto;
    font-family: var(--codec-pro-font);
    
    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__icon {
    width: 1.6rem;
    height: 1.6rem;
    color: $primary-color;
    transition: all 0.3s linear;
    opacity: 0;
    flex: none;
  }

  .discount {
    border-radius: 1.6rem 0 1.6rem;
  }
}
.section {
  &.featured-media {
    padding: 2.5rem 0 5rem;
    
    @include media($md) {
      padding: 4rem 0 4.5rem;
    }
  }
}