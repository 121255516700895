::placeholder {
  color: $black;
}

.product-option {
  @include media($xs) {
    flex: 1;
  }

  &__title {
    font-size: 1.4rem;
    font-weight: 600;
    color: $black;
    margin-bottom: 1rem;
    display: inline-block;
    cursor: pointer;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  .select {
    cursor: pointer;

    &:focus {
      border-color: var(--primary-color);
    }
  }
}

.input-field {
  &__input {
    position: relative;
  }

  &.error {
    .input,
    .textarea {
      border-color: $error;
      padding-right: 5rem;
    }

    .input-notification {
      color: $error--muted;

      &__icon {
        fill: $error--muted;
      }
    }
  }
  
  &.warning {
    .input,
    .textarea {
      border-color: $warning;
      padding-right: 5rem;
    }

    .input-notification {
      color: $warning--muted;

      &__icon {
        fill: $warning--muted;
      }
    }
  }

  &.success {
    .input,
    .textarea {
      border-color: $success;
      padding-right: 5rem;
    }

    .input-notification {
      color: $success--muted;

      &__icon {
        fill: $success--muted;
      }
    }
  }

  .input-notification {
    font-size: 1.5rem;
    display: block;
    margin-top: .5rem;

    &__icon {
      width: 1.6rem;
      height: 1.6rem;
      position: absolute;
      right: 2.4rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.input,
.select,
.PhoneInputInput {
  // iOS shadow
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
  // box-shadow: none;
  box-shadow: 0 -.1rem 0rem 0rem rgba(47, 43, 67, 0.10) inset, 0 0 .3rem 0 rgba(47, 43, 67, 0.10);

  padding: 1rem 2.5rem;
  width: 100%;
  font-size: $input-font-size;
  border: .1rem solid rgba(67, 66, 97, 0.15);
  outline: none;
  background: $white;
  font-family: inherit;
  transition: all .3s;
  color: $black;
  line-height: 1.3;

  border-radius: 1.2rem;

  &:hover {
    // border-color: #ccc;
  }
  
  &:focus {
    // border-color: #ccc;
    // border-color: $input-border-focus;
    // box-shadow: $input-focus-shadow;
  }
}

.select {
  padding-right: 4rem;
  background-repeat: no-repeat;
  background-position: right 1.5rem center;
  background-size: 1.2rem;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.27637 6.70703C5.03027 6.70703 4.81152 6.625 4.64746 6.46094L0.272461 2.08594C-0.0830078 1.75781 -0.0830078 1.18359 0.272461 0.855469C0.600586 0.5 1.1748 0.5 1.50293 0.855469L5.27637 4.60156L9.02246 0.855469C9.35059 0.5 9.9248 0.5 10.2529 0.855469C10.6084 1.18359 10.6084 1.75781 10.2529 2.08594L5.87793 6.46094C5.71387 6.625 5.49512 6.70703 5.27637 6.70703Z' fill='%2350585E'/%3E%3C/svg%3E%0A");
  // opacity: 0.4;
}

.select:invalid {
  color: rgba(#30414C, .4);
}

.select option {
  color: #30414C;
}

button,
button:focus {
  outline: none;
  font-family: var(--inter-font), Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.label {
  user-select: none;
  cursor: pointer;
  font-size: $input-font-size;
  margin-bottom: .5rem;
  display: inline-block;

  span {
    color: $primary-color;
  }
}

fieldset {
  border: none;
}

/* 
 *
 * Checkbox
 *
 */
.checkbox-label {
  &__main {
    font-size: 1.4rem;
    cursor: pointer;
    color: $black;
    padding-left: 2rem;
    position: relative;
    user-select: none;
    line-height: 1.15;
    display: flex;
    align-items: center;

    min-width: 1.2rem;
    min-height: 1.2rem;

    &:hover {
      &:after {
        opacity: 1;
      }
    }

    &::after {
      content: '';
      width: 2.2rem;
      height: 2.2rem;
      position: absolute;
      top: 50%;
      left: -0.5rem;
      transform: translateY(-50%);
      background-color: rgba($black, 0.05);
      border-radius: 50%;
      transition: all 0.3s linear;
      opacity: 0;
    }

    &::before {
      content: "";
      width: 1.2rem;
      height: 1.2rem;
      border: .1rem solid #50585E;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: .2rem;
      transition: all .3s;
    }
  }

  &__input {
    @include hide-element;
  }
  &__selected {
    font-weight: 600;
  }

  &__input:checked + .checkbox-label__main::before {
    content: "";
    background-color: $primary-color;
    position: absolute;
    border-color: $primary-color;

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    background-size: 1.2rem;
    background-position: center;
  }

  &__input[disabled] + .checkbox-label__main {
    opacity: .5;
    cursor: default;
    pointer-events: none;
  }

  &__amount {
    color: $gray;
    font-size: 1.4rem;
    margin-left: .5rem;
  }
}

/* 
 *
 * Radio
 *
 */
.radio-label {
  &__main {
    font-size: $input-font-size;
    cursor: pointer;
    color: $font-base-color;
    height: 100%;
    padding: 0 0 0 3rem;
    position: relative;
    user-select: none;
    line-height: 1.15;
    display: flex;
    align-items: center;
    
    min-width: 2rem;
    min-height: 2rem;

    &:hover {
      &::before {
        border-color: $input-border-hover;
      }
    }

    &::before {
      content: "";
      width: 2rem;
      height: 2rem;
      border: .2rem solid $input-border-normal;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      background: $white;
      transition: all .3s;
    }

    &::after {
      content: "";
      width: 2rem;
      height: 2rem;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      background-color: transparent;
      border: .2rem solid transparent;
      transition: all .3s;
    }
  }

  &__input {
    @include hide-element;
  }

  &__input:checked + .radio-label__main::before {
    border-color: $primary-color;
  }

  &__input:checked + .radio-label__main::after {
    background-color: $primary-color;
    box-shadow: 0 0 0 .3rem $white inset;
  }

  &__input[disabled] + .radio-label__main {
    opacity: .5;
    cursor: default;
    pointer-events: none;
  }

  &__amount {
    color: $dark--medium;
    font-size: 1.4rem;
    margin-left: .5rem;
  }
}
/* Radio */

// type search hide cross
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

// type number hide arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  // display: none;          // <- Crashes Chrome on hover
  -webkit-appearance: none;
  margin: 0;                 // <-- Apparently some margin are still there even though it's hidden
}

input[type=number] {
  -moz-appearance: textfield; // Firefox
}
