.section-info {

  .section__header {
    max-width: 84rem;
    margin: 0 auto 2rem;
    
    @include media($md) {
      margin: 0 auto 4rem;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    max-width: 92rem;
    margin: 0 auto;
  }

  &__item {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 2rem;
      
      @include media($md) {
        margin-bottom: 3rem;
      }
    }
  }

  &__img-wrap {
    width: 3rem;
    height: 3rem;
    flex: none;
    position: relative;
    margin-right: 1.5rem;

    @include media($md) {
      width: 4rem;
      height: 4rem;
      margin-right: 3rem;
    }
  }

  &__img {
    width: 1.8rem;
    height: 1.5rem;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include media($md) {
      width: 2.4rem;
      height: 2.1rem;
    }
  }

  &__desc {
    color: #6D7880;
  }
}