.error-section {
  position: relative;
  padding-top: 19.5rem;
  padding-bottom: 9.5rem;

  .container-fluid {
    max-width: 79rem;
  }

  .section {
    &__header {
      margin-bottom: 0;
    }
  }

  .section-header__btns {
    flex-wrap: wrap;

    @include media(360px) {
      flex-wrap: nowrap;
    }
  }

  .section-header__btn {
    width: 100%;

    @include media(360px) {
      width: auto;
    }
  }
}