@media print {
  * {
    -webkit-print-color-adjust: exact !important; // Chrome, Safari, Edge
    print-color-adjust: exact !important;               // Firefox
  }

  @page {
    margin-top: 0cm;
    margin-bottom: 0cm;
    margin-left: 0cm;
    margin-right: 0cm;
    size: letter portrait;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}