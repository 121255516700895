.informer-block {
  $self: &;

  &__header {
    margin-bottom: 3.5rem;
  }

  &__pagination {
    margin-top: 2.5rem;

    &.blog-pagination {
      .blog-pagination__item {
        padding-left: 0;


      }
    }
  }

  .filter-alphabet {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1.5rem;
    border: 1px solid $air-blue;
    border-radius: .8rem;

    &__field {
      width: 3.2rem;
      height: 3.2rem;
      flex: none;
      position: relative;

      @include media($md) {
        width: 4rem;
        height: 4rem;
      }
    }

    &__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &__label {
      z-index: 2;

      @include media($md, false) {
        font-size: 1.3rem;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem;
    margin-top: 2.5rem;

    @include media(360px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include media($md) {
      gap: 3.5rem;
    }
  }
}

.plants-item {
  $self: &;

  animation-name: fadeIn;
  animation-duration: .5s;
  animation-fill-mode: forwards;

  &__link {
    display: inline-flex;
    align-items: center;
    color: $black;
    transition: color .3s linear;
    text-decoration: none;

    &:hover {
      color: $primary-color;
    }
  }

  &__icon {
    width: 1rem;
    height: 1rem;
    display: block;
    flex: none;
    color: $secondary-color;
    margin-right: 1rem;
    margin-left: .5rem;
  }

  &__title {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.5;

    @include media($md) {
      font-size: 1.4rem;
    }
  }

  &.is-active {
    #{$self}__link {
      color: $primary-color;
    }
  }
}

.rte {
  .informer-block__pagination {
    ul {
      li {
        &:before {
          display: none;
        }
      }
    }
  }
}