.team-intro {
  padding-top: 4rem;
  position: relative;
  margin-bottom: 9rem;

  @include media(500px) {
    
    margin-bottom: 10rem;
  }
  
  @include media($md) {
    padding-top: 8rem;
    margin-bottom: 20rem;
  }
  .heading-section__description {
    max-width: 73rem;
  }
}
