.section-compare {
  &__header {
    margin-bottom: 5rem;
  }

  &__heading {
    max-width: 73rem;
    margin: 0 auto;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include media($md) {
      gap: 2.5rem;
    }
  }
}

.compare-area {
  &__cards {
    display: grid;
    gap: 1.5rem;

    grid-template-columns: repeat(3, minmax(0, 1fr));

    @include media($md) {
      grid-template-columns: minmax(0, 1fr) repeat(3, minmax(0, 20rem));
    }

    @include media($md) {
      padding: 0 2.5rem;
      gap: 2.5rem;
    }
  }

  &__card {
    &:first-child {
      @include media($md) {
        grid-column-start: 2;
      }
    }
  }
}

.compare-card {
  padding: 1.5rem 1rem;
  position: relative;
  display: flex;
  flex-direction: column;

  @include media($md) {
    padding: 2.5rem 1.2rem;
  }

  &__img {
    width: 100%;
    height: auto;
    display: block;
  }

  &__content {
    padding: 1.5rem 0 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;

    @include media($md) {
      padding: 1.5rem 1.3rem 0;
    }
  }

  &__title {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 1rem;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__prices {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    margin-top: auto;

    @include media($xs, false) {
      flex-direction: column;
    }
  }

  &__old-price {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    text-decoration: line-through;
  }

  &__new-price {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.4;
    color: $primary-color;
  }

  &__btns {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__btn {
    width: 100%;

    @include media($md, false) {
      padding: .7rem .8rem;
    }

    span {
      &:first-child {
        display: none;

        @include media($md) {
          display: block;
        }
      }

      &:last-child {
        @include media($md) {
          display: none;
        }
      }
    }
  }

  &.best-seller {
    border: 1px solid #605BFF33;
    box-shadow: 0 0 3rem 0 #0000000D;
    border-radius: 1.6rem;

    @include media($md) {
      border-radius: 2.4rem;
    }
  }

  &__badge {
    position: absolute;
    top: -1.2rem;
    right: -1.2rem;
  }
}

.compare-table {
  $self: &;

  border-radius: 1.6rem;
  border: 1px solid $air-blue;
  overflow: hidden;

  &__row {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: center;
    gap: 2.5rem;
    padding: 2rem;
    border-bottom: 1px solid $air-blue;

    @include media($md) {
      grid-template-columns: minmax(0, 1fr) repeat(3, minmax(0, 20rem));
    }

    &:first-child {
      background-color: #F8F9FB;

      #{$self}__title {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }

    &:first-child,
    &:last-child {
      border-bottom: none;
    }
  }

  &__title {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    grid-column: 1 / -1;
    text-align: center;
    //display: flex;
    //flex-wrap: wrap;
    gap: 1rem;
    align-items: center;

    @include media($md) {
      text-align: start;
      grid-column: auto;
    }
  }

  &__check,
  &__cross {
    margin: 0 auto;
    display: inline-flex;
  }

  &__check {
    width: 1.6rem;
    height: 1.6rem;

    #{$self}__icon {
      color: #43BF84;
    }
  }

  &__cross {
    width: 1.2rem;
    height: 1.2rem;

    #{$self}__icon {
      color: #FF395A;
    }
  }

  &__icon {
    width: 100%;
    height: 100%;
    flex: none;
  }

  &__tooltip-wrap {
    margin-left: 1rem;
    transform: translateY(.2rem);
  }
}

.badge-rounded {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  width: 5.2rem;
  height: 5.2rem;
  background-color: #fdcb4a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 1rem;

  @include media($md) {
    font-size: 1.2rem;
    width: 6rem;
    height: 6rem;
  }
}

.tooltip {
  $self: &;

  width: 1.4rem;
  height: 1.4rem;
  display: inline-flex;
  cursor: pointer;

  &__icon {
    width: 100%;
    height: 100%;
    color: $dark-accent--default;
    flex: none;
  }

  &__content {
    &.rte {
      p {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5;
        color: #F8F9FB;
      }
    }
  }
}