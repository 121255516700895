.latest-news-section {
  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
  }

  &__card {
    &:not(:last-child) {
      margin-bottom: 1.5rem;

      @include media($md) {
        margin-bottom: 2.5rem;
      }
    }
  }
}

.latest-news-card {
  padding: 2.5rem;
  border-radius: 2.4rem;
  background: #F8F9FB;

  @include media($md) {
    padding: 3rem;
    padding: 4.5rem;
    border-radius: 3.2rem;
  }

  &__heading,
  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    line-height: 1.3;
    color: $black;
  }

  &__heading {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;

    @include media($md) {
      font-size: 2.3rem;
      margin-bottom: 2.5rem;
    }

    &.is-sm {
      font-size: 1.6rem;
      margin-bottom: 1rem;

      @include media($md) {
        font-size: 1.9rem;
      }
    }
  }

  &__title {
    font-size: 1.8rem;
    margin-bottom: .5rem;

    @include media($md) {
      font-size: 2.3rem;
    }
  }

  &__event {
    font-weight: 300;
    font-size: 1.8rem;

    @include media($md) {
      font-size: 2.3rem;
    }
  }

  &__post {
    &:not(:last-child) {
      margin-bottom: 1rem;

      @include media($md) {
        margin-bottom: 2.5rem;
      }
    }
  }

  &__more {
    color: var(--primary-color);

    &::after {
      background: rgba(96, 91, 255, 0.20);
    }
  }

  &__item {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 1rem;

      @include media($md) {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__link {
    max-width: 100%;
    // color: var(--primary-color);
    
    // &::before {
    //   background: rgba(96, 91, 255, 0.20);
    // }

    // &:hover {
    //   &::before {
    //     background: transparent;
    //   }
    // }
    
    .btn-link {
      &__text {
        min-width: 0;

        @include trim;
      }
    }
  }

  &__timetable {
    margin: 1.8rem 0;
    
    @include media($md) {
      margin: 2.5rem 0;
    }
  }
}

.timetable {
  display: flex;
  line-height: 1.15;

  &__col-data {
    flex: 0 0 33%;
    max-width: 33%;
    margin-right: 3.5rem;

    @include media($md) {
      flex: 0 0 31%;
      max-width: 31%;
    }
  }

  &__col-time {
    flex: 0 0 33%;
    max-width: 33%;
    
    @include media($md) {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  &__title {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    color: $gray;
    
    @include media($md) {
      font-size: 1.4rem;
    }
  }

  &__time {
    font-weight: 600;
    font-size: 1.4rem;
    color: $black;

    @include media($md) {
      font-size: 1.6rem;
    }
  }
}
