.product-info {
  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 3rem;

    @include media($md) {
      column-gap: 2rem;
      //flex-wrap: nowrap;
    }
  }

  &__main {
    width: 100%;

    @include media($md) {
      flex: 0 0 66%;
      max-width: 66%;
    }
  }

  &__info-box {
    @include media($md) {
      max-width: 69rem;
    }
  }

  &__info-box.rte {
    &:not(:last-child) {
      margin-bottom: 5rem;
    }

    h2,
    h3 {
      font-family: var(--codec-pro-font);
      font-weight: 500;
      line-height: 1.3;
      margin-bottom: 1rem;
      color: $black;
      margin-top: 2.5rem;
    }

    h2 {
      font-size: 1.8rem;

      @include media($md) {
        font-size: 2.3rem;
      }
    }

    h3 {
      font-size: 1.6rem;
      
      @include media($md) {
        font-size: 1.9rem;
      }
    }

    p {
      font-size: 1.3rem;
      line-height: 1.3;
      
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      
      @include media($md) {
        font-size: 1.4rem;
      }
      
      @include media($xl) {
        font-size: 1.6rem;
      }
    }

    li {
      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }

      &::before {
        color: $black;
        font-size: 3rem;
        line-height: .5;

        @include media($md) {
          // top: 0.9rem;
        }
      }
    }

    dl {
      display: flex;
      flex-wrap: wrap;

      >:nth-child(4n-2),
      >:nth-child(4n-3) {
        background-color: #F8F9FB;
      }
    }

    dt,
    dd {
      font-size: 1.3rem;
      padding: 1.1rem 1.5rem;
      flex: 0 0 50%;
      max-width: 50%;
      
      @include media($md) {
        font-size: 1.4rem;
      }
    }

    dt {
      font-weight: 600;
      color: $gray;
      padding-right: 1rem;
    }
    
    dd {
      text-align: end;
      padding-left: 1rem;
    }
  }

  &__sidebar {
    width: 100%;

    @include media($md) {
      flex: 1;
      min-width: 0;
    }
  }

  .faq-section {
    @include media($md) {
      order: 1;
      width: 100%;
    }
  }
}

.link-info {
  &__heading {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 1rem;
    color: $black;
    font-size: 1.8rem;
    
    @include media($md) {
      font-size: 2.3rem;
    }
  }
  
  &__item {
    padding-left: 0;
    display: flex;
    
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &::before {
      display: none;
    }
  }

  &__link {
    max-width: 100%;

    .btn-link__text {
      min-width: 0;
      
      @include trim;
    }
  }
}