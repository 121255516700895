.intro-section {
  $self: &;

  --section-color: #{$air-blue};
  --ellipse-height: 6rem;

  background-color: var(--section-color);
  position: relative;
  margin-bottom: calc(var(--ellipse-height) / 2 + 3rem);
  border: .1rem solid $air-blue;

  padding-top: calc(var(--announcementBarHeight, 0px) + 10.5rem);

  @include media(480px) {
    --ellipse-height: 10rem;
  }

  @include media($sm) {
    --ellipse-height: 13rem;
  }

  @include media($md) {
    --ellipse-height: 15rem;

    padding-bottom: 0;
  }

  @include media($lg) {
    --ellipse-height: 20rem;

    padding-bottom: 0;
  }

  @include media($xl) {
    --ellipse-height: 30rem;

    padding-top: calc(var(--announcementBarHeight, 0px) + 8rem + 2rem);
  }

  @include media($xl) {
    margin-bottom: calc(var(--ellipse-height) / 2 + 5rem);
  }

  &__header {
    position: relative;
    text-align: center;
    max-width: 25.4rem;
    margin: 0 auto;
    z-index: 2;

    @include media(370px) {
      max-width: 73rem;
    }

    @include media($md) {
      transform: translateY(2rem);
    }

    @include media($xl) {
      transform: translateY(6rem);
    }
  }

  &__caption {
    background: $white;
    padding: 1rem;
    border-radius: .6rem;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    align-items: baseline;
    line-height: 1.15;
    flex-wrap: wrap;
    justify-content: center;
    gap: .5rem;

    display: none;

    @include media($md) {
      display: inline-flex;
    }

    &-text {
      margin-right: .5rem;
    }

    &-more {
      font-size: inherit;
    }
  }

  &__content {
    margin-top: 1rem;
    max-width: 26rem;
    margin-left: auto;
    margin-right: auto;

    @include media($sm) {
      max-width: none;
    }

    &.rte {
      font-weight: 300;
      
      p {
        font-size: 1.6rem;
        line-height: 1.35;
        
        @include media($md) {
          font-size: 1.9rem;
          line-height: 1.4;
        }
        
        @include media($xl) {
          font-size: 2rem;
        }
      }
    }
  }

  &__switchers {
    display: inline-flex;
    margin-top: 2rem;
    white-space: nowrap;

    @include media($md) {
      margin-top: 3rem;
    }
  }

  &__decor {
    fill: var(--section-color);
    left: 0;
    right: 0;
    top: 100%;
    transform: translateY(-50%);
    height: var(--ellipse-height);
  }

  &.is-narrow {
    #{$self}__header {
      max-width: 66rem;
    }
  }
}
