.quizz {
  box-shadow: 0 0 5rem rgba(0, 0, 0, 0.03);
  border-radius: 2.4rem;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;

  @include media($md) {
    min-height: 48rem;
  }

  &__header {
    text-align: center;
    padding: 1.6rem;
    line-height: 1.2;
    margin-bottom: 2rem;
    max-width: 56rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__caption {
    font-size: 1.2rem;
    font-weight: 600;
    display: block;
    margin-bottom: 1rem;
  }

  &__heading {
    font-size: 2rem;
    font-weight: 500;
    font-family: var(--codec-pro-font);
    color: #30414C;

    @include media($md) {
      font-size: 2.8rem;
    }

    &.is-leading {
      font-size: 2.5rem;

      @include media($md) {
        font-size: 4rem;
      }
    }
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__intro {
    margin-bottom: 3rem;
    text-align: center;
  }

  &__preview {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &__options {
    display: flex;
    gap: 2.5rem;

    @include media($md, false) {
      gap: 2rem;
    }

    &.has-three-more-opts {
      gap: 1.5rem;

      @include media($md, false) {
        --flex-items: 3;
        --flex-gap: .5rem;

        flex-wrap: wrap;
        gap: var(--flex-gap);

        > * {
          width: calc((100% / var(--flex-items)) - (((var(--flex-items) - 1) / var(--flex-items)) * var(--flex-gap)));
          gap: .8rem .5rem;
          flex: initial;
        }
      }
    }
  }

  &__option {
    flex: 1;
  }

  &__results {
    display: flex;
    gap: 2.5rem;
    flex-wrap: wrap;

    @include media($md) {
      flex-wrap: nowrap;
    }
  }

  &__result {
    flex: 0 0 100%;
    min-width: 0;

    @include media($md) {
      flex: 1;
    }
  }

  &__breed {
    max-width: 38rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  &__dropdown {
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__breed-result {
    max-width: 58rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__footer {
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;

    &.text-center {
      justify-content: center;
    }

    .btn {
      gap: 1rem;

      &__text {
        transition: none;
      }

      .icon {
        width: 1.3rem;
        height: 1.3rem;
      }
    }
  }

  &__btn {
    min-width: 10.5rem;

    &.is-loading {
      &::before {
        content: '';
        width: 1.8rem;
        height: 1.8rem;
        transition-timing-function: ease-in;
        transform-origin: 50% 50%;
        animation: spin 1s infinite linear;
        border: .3rem solid currentColor;
        border-left-color: transparent;
        border-radius: 50%;
        align-self: center;
      }
    }
  }

  &__preloader {
    align-self: center;
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.quizz-option {
  border: .2rem solid #F0F2F7;
  border-radius: 1.2rem;
  padding: 2.7rem 1.5rem;
  transition: border-color .3s ease-in-out;
  cursor: pointer;
  text-align: center;
  font-size: 1.2rem;
  color: #50585E;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:has(:checked) {
    border-color: var(--primary-color);
    color: var(--primary-color);
  }

  &__icon {
    margin-bottom: 1.5rem;
    /*width: 2.4rem;
    height: 2.4rem;*/
    font-size: 2.6rem;
  }

  &__text {
    display: block;
    margin: auto 0;
  }

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;

    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;

    outline: 0;
    outline-offset: 0;
  }
}

.quizz-result {
  padding: 1.6rem;
  background: #F8F9FB;
  text-align: center;
  border-radius: 1.2rem;

  @include media($md) {
    padding: 5rem 2.5rem 7rem;
  }

  &__heading {
    line-height: 1.15;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    font-weight: 500;
    color: #50585E;
    font-family: var(--codec-pro-font);

    @include media($md) {
      font-size: 2.3rem;
      margin-bottom: 2rem;
    }
  }
  
  &__desc {
    font-size: 1.2rem;
    color: #50585E;
    line-height: 1.45;
  }
}

.quizz-subscribe {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  &__caption {
    font-weight: 600;
    font-size: 1.4rem;
    display: inline-block;
    margin-bottom: 1rem;
    line-height: 1.1;
    cursor: pointer;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__input {
    text-align: center;

    &:hover {
      border-color: rgba(67, 66, 97, 0.25)
    }

    &:focus {
      border-color: rgba(67, 66, 97, 0.45)
    }

    &::placeholder {
      opacity: .4;
    }
  }
}

.conversion-product {
  border: .1rem solid rgba(96, 91, 255, 0.2);
  border-radius: 1.2rem;
  padding: 1.2rem;
  display: flex;
  gap: 1.2rem;
  align-items: center;

  &__img-wrap {
    flex: 0 0 8.5rem;
    max-width: 8.5rem;
  }

  &__img {
    max-width: 100%;
    height: auto;
    display: block;
    image-rendering: -webkit-optimize-contrast;
  }

  &__info {
    flex: 1;
  }

  &__heading {
    font-size: 1.2rem;
    color: #30414C;
    line-height: 1.1;
  }

  &__btn {
    padding: 0;
  }

  &__more {
    font-size: 1.2rem;
    font-weight: 600;
    color: $primary-color;
    display: inline-flex;
    text-decoration: underline;
    text-underline-offset: .5rem;
    text-decoration-color: rgba($primary-color, .2);
    transition: text-decoration-color .3s ease-in-out;
    padding: 0;

    &:hover {
      text-decoration-color: rgba($primary-color, 1);
      text-decoration: underline;
    }
  }
}

.breed-dropdown {
  &__img-wrap {
    width: 8rem;
    height: 8rem;
    margin: 0 auto 1.2rem;
  }

  &__img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }

  .filter-dropdown {
    font-size: 1.4rem;
    position: relative;

    @include media($md) {
      font-size: 1.6rem;
    }

    &__dropdown {
      border: .2rem solid #F0F2F7;
      border-radius: 1.2rem;
      padding: 1.1rem 1.5rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      cursor: pointer;
      color: #30414C;
    }

    &__icon {
      margin-left: auto;
      width: 1.2rem;
      height: 1.2rem;
      flex: none;
    }

    &__body {
      top: 0;
      max-height: 18rem;
      border: .2rem solid #F0F2F7;
      padding: 1.1rem 1.5rem;
      border-radius: 1.2rem;

      display: none;

      @at-root .filter-dropdown.is-active {
        .filter-dropdown__body {
          display: block;
        }
      }
    }

    &__item {
      padding-left: 0;

      &:before {
        display: none;
      }
    }

    &__list {
      padding: 0;
    }

    &__link {
      font-size: 1.4rem;
      padding: .8rem 0;
      cursor: pointer;

      @include media($md) {
        font-size: 1.6rem;
      }
    }
  }
}

.breed-result {
  &__top {
    margin-bottom: 2.5rem;

    @include media($md) {
      margin-bottom: 3.5rem;
      display: flex;
      gap: 3.5rem;
    }
  }
  
  &__img-wrap {
    flex: 0 0 14.4rem;
    max-width: 14.4rem;
    height: 14.4rem;
    border-radius: .8rem;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.5rem;
    width: 100%;

    @include media($md) {
      flex: 0 0 18rem;
      max-width: 18rem;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
      width: auto;
      height: 18rem;
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__result {
    margin-bottom: 2.5rem;
    text-align: center;

    @include media($md) {
      text-align: left;
    }
  }

  &__heading {
    font-weight: 500;
    font-family: var(--codec-pro-font);
    font-size: 1.8rem;
    color: #50585E;
    margin-bottom: .5rem;

    @include media($md) {
      font-size: 2.3rem;
    }
  }

  &__note {
    font-size: 1.2rem;
    color: #8B9DA9;
    font-weight: 600;
  }

  &__conversion {
    margin-top: auto;
  }

  &__desc {
    font-size: 1.3rem;
    color: #50585E;
    line-height: 1.5;

    @include media($md) {
      font-size: 1.4rem;
    }
  }
}

@keyframes spin {
   0% {
     transform: rotate(0);
     animation-timing-function: cubic-bezier(.5856,.0703,.4143,.9297);
   }

   to {
     transform: rotate(360deg);
   }
 }