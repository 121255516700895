.header {
  // transition: transform .3s ease-in-out, margin .3s ease-in-out background-color .3s ease-in-out;
  transition: margin-top .3s ease-in-out;
  position: fixed;
  left: 0;
  right: 0;
  top: -1px;
  z-index: $dropdownAboveZIndex;
  //background: transparent;
  background: $air-blue;
  border-bottom: 1px solid $air-blue;
  
  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0;
    
    @include media($md) {
      justify-content: flex-start;
      min-height: 10rem;
      padding: 0;
    }
  }

  &__panel {
    z-index: 11;
  }

  &__logo {
    @include media($md) {
      max-width: 8.2rem;
    }
  }

  + * {
    padding-top: calc(var(--announcementBarHeight, 0px) + 6.5rem + 4rem);

    @include media($md) {
      padding-top: calc(var(--announcementBarHeight, 0px) + 8rem + 6rem);

      //padding-top: calc( 8rem + 6rem);
    }
  }

  + .section.blog-section {
    padding-top: calc(var(--announcementBarHeight, 0px) + 6.5rem + 2.5rem);

    @include media($md) {
      padding-top: calc(var(--announcementBarHeight, 0px) + 8rem + 4.5rem);

      //padding-top: calc(8rem + 4.5rem);
    }
  }

  &__controls {
    @include media($md, false) {
      justify-content: flex-end;
    }
  }
}

.logo {
  display: flex;
  align-items: center;

  &__wrap {
    max-width: 6.5rem;

    @include media($md) {
      max-width: 8.1rem;
    }
  }

  &__img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.header-controls {
  $self: &;

  position: relative;
  align-items: center;
  display: none;

  @include media($md) {
    display: flex;
  }

  &__auth {
    display: flex;
    align-items: center;

    #{$self}__btn {
      &:first-child {
        display: none;

        @include media($md) {
          display: flex;
        }
      }

      &:last-child {
        @include media($md, false) {
          display: none;
        }
      }

      &.is-authenticated {
        @include media($xl, false) {
          padding: 1rem;
        }

        @include media($md, false) {
          padding: .7rem;
          display: inline-flex;

          .btn__text {
            display: none;
          }
        }
      }
    }
  }

  &__btn {
    background-color: transparent;

    &:focus {
      background-color: transparent;
    }
  }

  &__btn:not(:last-child) {
    margin-right: .7rem;
  }

  &__cart {
    position: relative;
    min-width: 7rem;
    text-align: right;

    @include media($md) {
      min-width: 0;
    }

    &.is-opened {
      #{$self}__mini-cart {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
        z-index: 102;
      }
    }
  }

  &__cart-btn {
    margin-left: .7rem;
  }

  &__mini-cart {
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;

    top: 0;
    position: fixed;
    bottom: 0;
    transform: translate3d(100%, 0, 0);

    width: 100%;

    @include media($sm) {
      max-width: 48rem;
    }
  }

  &__cart-count {
    position: absolute;
    top: -1rem;
    right: -1rem;

    @include media($md) {
      top: -1.3rem;
      right: -1.3rem;
    }
  }
}

.cart-count {
  color: $white;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: $primary-color;
  border-radius: 50%;
  min-width: 2.2rem;
  min-height: 2.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $white;

  @include media($md) {
    min-width: 2.5rem;
    min-height: 2.5rem;
  }
}

.badge {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1;
  padding: 0.4rem 1rem;
  border-radius: 0.6rem;
  color: $white;
  display: inline-block;
  
  @include media($md) {
    padding: 0.7rem 1rem;
  }
  
  &.is-air-blue {
    background: $air-blue;
    color: $black;
  }
}

.navbar__badge {
  margin-left: .7rem;
}

.is-sticky {
  .header {
    background: $white;
    box-shadow: 0 .1rem 1rem rgba($black, .3);

    // transform: translateY( calc(-1 * var(--announcementBarHeight)) );
    margin-top: calc(-1 * var(--announcementBarHeight));
  }
}

.is-dropdown-opened {
  .header {
    &::before {
      background: rgba(#222, .6);
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      content: '';
      z-index: 2;
    }
  }

  .header-controls {
    &__mini-cart {
      z-index: 102;
    }
  }
}