.switcher {
  $self: &;

  background-color: #F8F9FB;
  border-radius: 9.6rem;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  
  &__items {
    display: flex;
    gap: 0.5rem;
  }

  &__btn {
    padding: 0.8rem 1.2rem;
    border-radius: 9.6rem;
    font-size: 1.4rem;
    color: $gray;
    display: block;
    transition: all .3s ease-in-out;

    @include media($md) {
      font-size: 1.6rem;
      padding: .9rem 1.5rem;
    }

    &:hover {
      color: $black;
    }

    &.is-active {
      cursor: default;
      color: $black;
      font-weight: 600;
      background: $white;
      box-shadow: 0rem -.1rem 0rem 0rem rgba(47, 43, 67, 0.10) inset, 0rem 0rem .3rem 0rem rgba(47, 43, 67, 0.10);
    }
  }
}

.compare-section {
  margin-bottom: 5rem;

  &__cards-wrap {
    display: grid;
    column-gap: 2.5rem;
    
    @include media($md) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  .product-card {
    border: 1px solid $air-blue;
    box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, 0.03);
    // padding: 2.5rem;

    &:hover {
      border: 1px solid $air-blue;
      box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, 0.03);
    }
    
    // @include media($md) {
    //   padding: 2.5rem 3rem;
    // }
    
    // @include media($lg) {
    //   padding: 2.5rem 5rem 5rem;
    // }

    @include media($md, false) {
      border: none;
      max-width: none;
      width: 100%;
    }
  }

  .product-card__content {
    &:first-child {
      @include media($md) {
        min-height: 17.5rem;
      }
    }

    &:last-child {
      border-top: 1px solid $air-blue;
      padding-top: 1.5rem;
      margin-top: auto;
      
      @include media($md) {
        padding-top: 3.5rem;
      }
    }
  }

  .product-card__price {
    font-size: 1.8rem;

    @include media($md) {
      font-size: 2.3rem;
    }
  }

  .product-card__description {
    margin-bottom: 0;
  }

  &__switcher {
    margin-bottom: 5.5rem;

    @include media($md) {
      display: none;
    }
  }

  &__cards-wrap {
    @include media($md, false) {
      .product-card {
        &:not(.is-active) {
          display: none;
        }
      }
    }
  }
}