.informer {
  .section__header {
    margin: 0 auto 3rem;
    max-width: 26.4rem;

    @include media($xs) {
      max-width: 66rem;
      margin: 0 auto 5rem;
    }
  }

  .container-fluid {
    max-width: 51rem;

    @include media($md) {
      max-width: 95rem;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
  }

  &__img-col {
    flex: 0 0 100%;
    max-width: 100%;

    @include media($md) {
      flex: 0 0 48.4%;
      max-width: 48.4%;
    }
  }

  &__info {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    &.is-order-1 {
      @include media($md) {
        padding-right: 12.3rem;
      }
    }

    &.is-order-2 {
      @include media($md) {
        padding-left: 12.3rem;
      }
    }

    @include media($md) {
      flex: 0 0 51.6%;
      max-width: 51.6%;
    }
  }

  &__suptitle {
    font-weight: 600;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 1.2;
    margin-bottom: 1.5rem;

    @include media($md) {
      font-size: 3rem;
    }
  }

  &__desc-wrap {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__icon {
    width: 1.3rem;
    height: 1.8rem;
    margin-right: 1rem;
    flex: none;
    color: $secondary-color;
  }

  &__desc {
    font-size: 1.4rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__list {
    margin-top: 1.5rem;
    max-width: 32rem;
  }

  &__item {
    padding-left: 2.2rem;
    position: relative;
    font-size: 1.4rem;
    
    @include media($md) {
      padding-left: 2.5rem;
      font-size: 1.6rem;
    }

    &::before {
      content: '';
      width: 0.4rem;
      height: 0.4rem;
      background-color: $black;
      border-radius: 50%;
      position: absolute;
      top: 0.9rem;
      left: 0.9rem;
      
      @include media($md) {
        left: 1.3rem;
      }
    }
  }

  &__bottom {
    align-self: flex-start;
    margin-top: 1rem;
    max-width: 100%;
  }

  &__btn {
    align-self: flex-start;
    margin-top: 3rem;
  }

  &__img-wrap {
    width: 100%;
  }

  &__img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.section {
  &.informer {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

.list {
  &__item {
    display: flex;
    padding-left: 2rem;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &::before {
      content: '';
      width: 1.3rem;
      height: 1.3rem;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.9766 0.648438C13.332 0.976562 13.332 1.55078 12.9766 1.87891L5.97656 8.87891C5.64844 9.23438 5.07422 9.23438 4.74609 8.87891L1.24609 5.37891C0.890625 5.05078 0.890625 4.47656 1.24609 4.14844C1.57422 3.79297 2.14844 3.79297 2.47656 4.14844L5.34766 7.01953L11.7461 0.648438C12.0742 0.292969 12.6484 0.292969 12.9766 0.648438Z' fill='%231F6DB2'/%3E%3C/svg%3E%0A");
      position: absolute;
      top: 0.5rem;
      left: 0;
      // transform: translateY(-50%);
    }
  }

  // &__icon {
  //   width: 1.3rem;
  //   height: 1.8rem;
  //   color: $secondary-color;
  //   margin-right: 1rem;
  //   flex: none;
  // }

  &__text {
    font-weight: 600;
    color: $secondary-color;
    font-size: 1.4rem;

    @include media($md) {
      font-size: 1.6rem;
    }
  }
}