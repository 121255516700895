.library-section {
  $self: &;

  &__btn-back {
    margin-bottom: 1rem;
  }

  &__top {
    margin-bottom: 3rem;
    
    @include media($md) {
      margin-bottom: 5.5rem;
    }
  }

  &__header {
    max-width: 100%;
    text-align: center;
    
    @include media($md) {
      text-align: start;
    }

    &:not(:last-child) {
      margin: 0 0 3rem 0;

      @include media($md) {
        margin: 0 0 5rem 0;
      }
    }

    &.has-conversion {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      @include media($md) {
        flex-direction: row;
        align-items: flex-start;

        #{$self}__header-info {
          flex: 1;
          align-self: center;
        }
      }

      #{$self}__conversion {
        text-align: left;

        @include media($md) {
          flex: 0 0 28rem;
          max-width: 28rem;
        }
      }
    }
  }

  &__heading {
    text-transform: capitalize;
    font-family: "Codec Pro", Arial, sans-serif;
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 110%;

    @include media($md) {
      font-size: 5.6rem;
    }

    @include media($xl) {
      font-size: 6.4rem;
    }
  }

  &__description {
    font-size: 1.9rem;
    font-weight: 300;
    line-height: 1.4;
    margin-top: 1rem;
  }

  &__caption {
    margin-bottom: 1rem;
    display: inline-block;
  }

  .section-suptitle, 
  .section-caption {
    font-size: 1.4rem;
    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 12.5rem;

    @include media ($md) {
      flex-wrap: nowrap;
    }
  }

  &__searc-info {
    text-align: center;

    @include media($md) {
      text-align: start;
    }
  }

  &__search-title {
    font-family: 'Codec Pro', Arial, sans-serif;
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 1.3;
    color: #50585E;

    @include media($md) {
      font-size: 3.4rem;
    }
  }

  &__search-description {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
    margin-top: 0.5rem;
    color: #50585E;

    @include media($md) {
      font-size: 1.6rem;
    }
  }
  
  &__card-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    flex: 1;
    
    @include media($md) {
      gap: 2.4rem;
    }
  }

  &__col {
    display: flex;
    justify-content: center;
    flex: 0 0 100%;
    max-width: 100%;

    @include media($sm) {
      flex: 0 0 calc((100% / 2) - 1.2rem);
      max-width: calc((100% / 2) - 1.2rem);
    }
    
    @include media($md) {
      flex: 0 0 calc((100% / 3) - 1.6rem);
      max-width: calc((100% / 3) - 1.6rem);
    }
  }

  &__content {
    flex: 0 0 100%;
    max-width: 100%;
    
    @include media($md) {
      flex: 0 0 57.5%;
      max-width: 57.5%;
    }
  }

  &__info {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }

    &.rte {
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 0;
        font-size: 1.8rem;
        
        @include media($md) {
          font-size: 2.3rem;
        }
      }

      p {
        font-size: 1.4rem;
        max-width: 100%;
      }

      a {
        font-weight: 600;
        border-bottom: 1px solid rgb(96, 91, 255, 0.2);
        transition: all 0.3s linear;
        max-width: 100%;
        text-decoration: none;

        @include trim;
        @include word-wrap();

        display: inline-block;
        
        &:hover {
          text-decoration: none;
          border-color: rgb(96, 91, 255, 1);
        }

        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  &__sidebar {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 4rem;
    
    @include media($md) {
      flex: 1;
      min-width: 0;
      margin-top: 0;
    }
  }

  &__controls {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    display: none;

    @include media($md) {
      display: flex;
    }
  }

  &__title {
    margin-bottom: 3rem;
    
    @include media($md) {
      margin-bottom: 9.5rem;
    }
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }

  &__search-wrap {
    width: 100%;
    
    @include media($md) {
      max-width: 38.5rem;
      margin-bottom: 0;
    }

    .search {
      padding: 0.9rem 1.5rem 1rem;
    }
  }

  &__dropdown-wrap {
    position: relative;
    width: 100%;

    @include media($md) {
      margin-left: auto;
      width: auto;
    }
    
    .filter-dropdown__dropdown {
      padding: 1.1rem 1.8rem;

      @include media($md) {
        padding: 1rem 2rem;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .library-picture {
    border-radius: 2.4rem;
    overflow: hidden;

    &__img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .sidebar__img-wrap {
    max-width: 38.5rem;
  }
}

/**
 * Conversion Offer Card
 */
.conversion-offer {
  border-radius: 1.2rem;
  padding: 1.5rem;
  background: #F8F9FB;

  display: flex;
  align-items: center;
  gap: 1rem;

  @include media($md) {
    padding: 2rem;
    display: block;
  }

  &__img-wrap {
    max-width: 9rem;
    width: 100%;

    @include media($sm) {
      max-width: 11.5rem;
    }

    @include media($md) {
      margin-bottom: .5rem;
    }
  }

  &__img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  &__heading {
    font-weight: 600;
    color: #30414C;
    font-size: 1.3rem;

    @include media($md) {
      font-size: 1.4rem;
    }
  }

  &__more {
    font-size: 1.3rem;
    font-weight: 600;
    color: $primary-color;
    margin-top: .5rem;
    display: inline-flex;
    text-decoration: underline;
    text-underline-offset: .5rem;
    text-decoration-color: rgba($primary-color, .2);
    transition: text-decoration-color .3s ease-in-out;

    @include media($md) {
      font-size: 1.4rem;
    }

    &:hover {
      text-decoration-color: rgba($primary-color, 1);
      text-decoration: underline;
    }
  }
}