.terms-service {
  padding-bottom: 5rem;

  .container-fluid {
    max-width: 99.5rem;
  }
  
  .rte {
    color: $black;
    
    * + h2 {
      margin-top: 3rem;

      @include media($md) {
        margin-top: 4rem;
      }
    }

    h2 {
      font-size: 1.8rem;
      // padding-left: 1.5rem;

      @include media($md) {
        font-size: 2.3rem;
      }
    }

    dl,
    dd {
      margin-top: 2rem;
    }

    dl {
      padding-left: 1.5rem;
      
      // @include media($md) {
      //   padding-left: 3rem;
      // }

      p {
        margin-top: 2rem;
      }
    }

    ol {
      list-style-type: lower-latin;
      padding-left: 3.8rem;
      
      li {
        // padding-left: 1.5rem;
        // list-style-position: inside;
        padding-left: 0;

        &:not(:first-child) {
          margin-top: 1.5rem;
        }

        &::marker {
          font-weight: 600;
          color: $black;
        }

        &::before {
          display: none;
        }

        // @include media($md) {
        //   padding-left: 1.5rem;
        // }
      }

      ol {
        list-style-type: lower-roman;
        margin-top: 2rem;

        li {
          padding-left: 1.5rem;
        }
      }
    } 

    a {
      font-weight: 600;
    }
  }

  &__bottom {
    margin-top: 3rem;
  }
}