.sitemap-section {
  $self: &;

  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    
    @include media($md) {
      font-size: 2.3rem;
      margin-bottom: 2.5rem;

    }
  }

  &__subsection {
    &:not(:last-child) {
      margin-bottom: 4.5rem;
    }

    @include media($md) {
      &:not(:last-child) {
        margin-bottom: 6.5rem;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    
    @include media($md) {
      column-gap: 2.5rem;
      row-gap: 2.5rem;
    }
  }

  .col-4 {
    flex: 0 0 calc((100% / var(--flex-items)) - (((var(--flex-items) - 1) / var(--flex-items)) * var(--flex-gap)));
    max-width: calc((100% / var(--flex-items)) - (((var(--flex-items) - 1) / var(--flex-items)) * var(--flex-gap)));
    --flex-gap: 2rem;
    --flex-items: 1;
    
    @include media(360px) {
      --flex-items: 2;
    }
  
    @include media($md) {
      --flex-items: 3;
      --flex-gap: 3rem;
    }
  }

  &__link {
    font-weight: 600;
    font-size: 1.4rem;
    text-decoration-line: underline;
    color: $primary-color;
    display: inline-block;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.section {
  &.sitemap-section {
    padding: 4rem 0 5rem;
    
    @include media($md) {
      padding: 5rem 0 8rem;
    }
  }
}