.team-card {
  display: flex;

  border-radius: 1.6rem;
  background: $white;
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  align-items: flex-start;

  @include media($md) {
    padding: 2.5rem;
    align-items: center;
  }

  &__img-wrap {
    position: relative;
    border-radius: 1.2rem;
    overflow: hidden;

    align-self: flex-start;

    max-width: 7.2rem;
    flex: 0 0 7.2rem;
    margin-right: 1.5rem;

    @include media($md) {
      max-width: 11.2rem;
      flex: 0 0 11.2rem;
      margin-right: 2.5rem;
    }

    &::before {
      padding-top: 100%;
      content: '';
      display: block;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;

    @include media($md) {
      justify-content: center;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.15;
    font-family: var(--codec-pro-font);
    margin-bottom: .5rem;
    color: $black;

    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__position {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.2;
    color: $gray;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__btn {
    margin-top: .5rem;

    @include media($md) {
      margin-top: 1.5rem;
    }
  }
}

.is-bio-showed {
  overflow: hidden;

  .bio-panel {
    transform: translate3d(0, 0, 0);
  }
}

.bio-panel {
  right: 0;
  top: 0;
  bottom: 0;
  z-index: $overlayAboveZIndex;
  position: fixed;
  transform: translate3d(100%, 0, 0);
  transition: transform .3s ease-in-out;
  max-width: 100%;
  width: 100%;
  background: $white;

  @include media($sm) {
    max-width: 48rem;
  }

  &__main {
    overflow-y: auto;
    height: 100%;
  }

  &__header {
    position: sticky;
    top: 0;
    padding: 3rem;
    background: linear-gradient(180deg, #ffffff 68%, rgba(255, 255, 255, 0) 100%);
    padding: 4rem 3rem 3rem 2rem;
    z-index: 2;

    @include media($md) {
      padding: 4rem 4.5rem 3rem 4.5rem;
    }
  }

  &__body {
    padding: 0 3rem 3rem 3rem;

    @include media($md) {
      padding: 0 4.5rem 4.5rem 4.5rem; 
    }
  }

  &__btn {
    // padding: 1rem 0;
    color: $dark-accent--default;
    font-size: 1.4rem;
  }
}

.bio-card {
  &__img-wrap {
    max-width: 11.2rem;
    margin-bottom: 3rem;
    border-radius: 1.2rem;
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      padding-top: 100%;
      display: block;
    }
  }

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__heading {
    font-weight: $headings-weight;
    font-family: var(--codec-pro-font);
    font-size: 2.6rem;
    color: $black;
    line-height: 1.15;
    margin-bottom: 1.5rem;

    @include media($md) {
      font-size: 3.4rem;
    }
  }

  &__content {
    font-weight: 300;
    color: $gray;
    font-size: 1.4rem;

    @include media($md) {
      font-size: 1.6rem;
    }

    @include media($lg) {
      font-size: 1.9rem;
    }
  }
}