.features-section {
  $self: &;

  &__row {
    display: grid;
    gap: 2.5rem;
    align-items: center;

    @include media($md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &.is-grid {
      align-items: start;

      @include media($sm) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include media($md) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }

    &.is-layout {
      align-items: flex-start;
      
      @include media($md) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    &.is-list {
      align-items: flex-start;
    }

    .section-header {
      align-self: start;
      min-width: 0;

      @include media($md) {
        max-width: 48.6rem;
        margin: 0;
      }

      @include media($md, false) {
        text-align: center;
      }

      .btn {
        white-space: normal;
        text-align: left;
      }
    }
  }

  &__info {
    min-width: 0;
  }

  &__preview {
    &:not(:first-child) {
      #{$self}__img-wrap {
        @include media($md) {
          margin-left: auto;
        }
      }
    }
  }

  &__img-wrap {
    position: relative;
    max-width: 48.6rem;
  }

  &__img {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 3.2rem;

    @include media($md) {
      width: 100%;
    }

    /*
    @include media($md) {
      width: 100%;
      max-width: 48.6rem;
    }
    */
  }

  &__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    width: 4rem;
    height: 4rem;

    @include media($md) {
      width: 4.8rem;
      height: 4.8rem;
    }

    &:hover {
      padding: 1.7rem;
      
      .content-section__btn-play {
        transform: scale(1.2);
      }
    }
  }

  &__btn-play {
    width: 1.4rem;
    height: 1.4rem;
    flex: none;
    transition: all 0.3s linear;
    // transform: translateX(.1rem);

    @include media($md) {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 2rem;

      @include media($md) {
        margin-bottom: 3rem;
      }
    }
  }
}

.features-section {
  $self: &;

  &__info {
    > #{$self}__info {
      &:not(:last-child) {
        margin-bottom: 2rem;

        @include media($md) {
          margin-bottom: 3rem;
        }
      }
    }
  }
}

.info-item {
  $self: &;

  display: flex;
  // align-items: center;
  align-items: flex-start;

  &__img-wrap {
    margin-right: 2.5rem;
    width: 7rem;
    max-width: 7rem;
    flex: none;
    position: relative;

    border-radius: 1.6rem;
    background: $white;
    box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.05);

    @include media($md) {
      width: 7.8rem;
      max-width: 7.8rem;
    }

    &::before {
      content: '';
      padding-top: 100%;
      display: block;
    }

    &.is-centered {
      align-self: center;
    }
  }

  &__main {
    min-height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media($md) {
      min-height: 7.8rem;
    }
  }

  &__img {
    // width: 5rem;
    // height: 5rem;

    object-fit: contain;
    max-width: 5rem;
    height: auto;
    max-height: 5rem;

    @include absolute-center;
  }

  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.15;
    margin-bottom: 0.5rem;
    color: $black;

    @include media($md) {
      font-size: 2.3rem;
    }
  }

  &__desc {
    font-size: 1.4rem;
    color: $gray;
    
    @include media($md) {
      font-size: 1.6rem;
    }
    
    @include media($xl) {
      font-size: 1.8rem;
    }
  }

  a {
    text-decoration: underline;
    color: inherit;
    text-decoration-color: rgba(80,88,94,.6);
    text-underline-offset: 0.2rem;
    line-height: inherit;
    font-size: inherit;
    border: none;
    font-weight: inherit;
    transition: text-decoration-color .3s ease-in-out;

    &:hover {
      text-decoration-color: transparent;
    }
  }

  &__info {
    color: $black;
    font-size: 1.6rem;
    font-weight: 300;
    
    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__subinfo {
    color: $black;
    font-weight: 600;
    margin-top: 0.5rem;
    font-size: 1.4rem;
    
    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__more {
    margin-top: 1.5rem;
    color: var(--primary-color);
    font-weight: 600;
    font-size: 1.4rem;
  }

  &.is-col {
    flex-direction: column;
    text-align: center;
    align-items: center;

    #{$self}__img-wrap {
      margin-right: 0;
      margin-bottom: 3rem;
    }

    #{$self}__title {
      @include media($md) {
        font-size: 3.4rem;
      }
    }

    #{$self}__main {
      display: block;
      min-height: 0;
    }
  }
}