.product-detail {
  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__col--left {
    flex: 0 0 100%;
    max-width: 100%;

    @include media($md) {
      flex: 0 0 40.1%;
      max-width: 40.1%;
    }
  }

  &__col--right {
    flex: 0 0 100%;
    max-width: 100%;
    min-width: 0;

    @include media($md) {
      flex: 1;
      margin-left: 4rem;
    }

    @include media($lg) {
      margin-left: 6rem;
    }

    @include media($xl) {
      margin-left: 12.5rem;
    }
  }

  &__btn-back {
    margin-bottom: 2.5rem;
    color: $dark-accent--default;

    @include media($md) {
      margin-bottom: 5rem;
    }
  }

  &__promo {
    max-width: 35rem;
    margin: 0 auto 8rem;

    @include media($md) {
      max-width: 100%;
      margin: 0;
    }
  }

  &__bottom-carousel-wrap {
    @include media($md) {
      margin-top: 2.5rem;
    }

    .swiper-wrapper {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 2.5rem;
      box-sizing: border-box;
      transform: none !important;
    }

    .swiper-slide {
      width: 100% !important;
    }
  }

  &__bottom-carousel {
    user-select: none;
  }

  &__suptitle {
    font-family: Codec Pro, Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.3;
    margin-bottom: 0.5rem;
    color: $gray;

    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__title {
    font-family: Codec Pro, Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 1.1;
    color: $black;
    margin-bottom: 1.5rem;

    @media (min-width: $md) {
      font-size: 7.2rem;
      margin-bottom: 1rem;
    }
  }

  &__rating {
    margin-bottom: 2.5rem;
    display: flex;
    align-items: center;
  }

  &__stars-wrap {
    margin-top: -.2rem;
    margin-right: 1.5rem;
  }

  &__price-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    gap: 2rem;
    
    @include media($md) {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }
  }

  &__prices {
    display: flex;
    align-items: center;
    gap: 1rem;
    min-height: 3.2rem;

    @include media($md) {
      min-height: 4.8rem;
    }
  }

  &__old-price,
  &__new-price {
    font-weight: 500;
    font-family: Codec Pro, Arial, Helvetica, sans-serif;
    line-height: 1.2;
    color: $gray;
  }
  
  &__old-price {
    font-size: 2.4rem;
    text-decoration: line-through;

    @include media($md) {
      font-size: 2.8rem;
    }

    &.text-decoration-none {
      text-decoration: none;
    }
  }

  &__new-price {
    font-size: 2.7rem;
    color: $primary-color;

    @include media($md) {
      font-size: 4rem;
    }
  }

  &__offer-wrap {
    margin-bottom: 2rem;

    @media (min-width: $md) {
      margin-bottom: 2.5rem;
    }
  }

  &__content {
    &.rte {
      p {
        font-size: 1.2rem;
        margin-bottom: 2.5rem;
      }
    }
  }

  &__subscribe {
    margin-bottom: 2.5rem;

    @include media($md) {
      margin-bottom: 3.5rem;
    }
  }

  &__counter {
    max-width: 11.2rem;
  }

  &__list {
    margin-bottom: 2rem;

    @media (min-width: $md) {
      margin-bottom: 3.5rem;
    }
  }

  &__fields {
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    column-gap: 2.5rem;
    row-gap: 1.5rem;

    @include media($xs) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__offer-description {
    font-size: 1.3rem;
    line-height: 1.3;
    color: $black;
    margin-bottom: 1rem;

    @media (min-width: $md) {
      margin-right: 1rem;
      margin-bottom: 0;
      font-size: 1.4rem;
    }
  }

  &__radio-button-wrap {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 2.5rem;

    &.product-multipack {
      @include media($md) {
        margin-bottom: 3.5rem;
      }
    }
  }

  &__radio-button {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    span {
      font-weight: 600;
      color: $secondary-color;
    }
  }

  &__label {
    font-size: 1.4rem;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__button-wrap {
    margin-bottom: 2.5rem;
  }

  &__button {
    padding: 1.3rem 8.5rem;
  }

  &__controls {
    display: flex;
    margin-bottom: 2rem;

    @include media($md) {
      margin-bottom: 3.5rem;
    }

    > *:not(:last-child) {
      margin-right: 1.5rem;

      @include media($md) {
        margin-right: 2.5rem;
      }
    }
  }

  &__upsell-widget {
    margin-bottom: 2.5rem;
    max-width: 59.5rem;
  }

  .upsell-widget {
    &__title {
      max-width: 35rem;
      margin-left: auto;
      margin-right: auto;

      @include media($sm) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &__btn {
      margin-top: 15px;

      @include media($lg) {
        margin-top: 30px;
      }
    }
  }

  &__information {
    margin-bottom: 2.5rem;

    @include media($md) {
      margin-bottom: 3.5rem;
      font-size: 1.4rem;
    }

    @include media($xl) {
      font-size: 1.6rem;
    }
  }

  &__accordion-wrap {
    margin-bottom: 2rem;
  }

  &__accordion-description {
    font-size: 16px;
    line-height: 1.5;
    color: #25292e;
    margin-bottom: 0.5rem;
  }

  .accordion__heading {
    padding: 1rem 0 1rem 3.5rem;

    &::before {
      left: 1rem;
      right: auto;
    }

    &::after {
      content: '';
      left: 1.4rem;
      right: auto;
    }
  }

  &__total-details {
    font-size: 1.2rem;
  }

  &__carousel-wrap {
    position: relative;

    .swiper-pagination {
      position: absolute;
      left: 50%;
      bottom: -4.5rem;
      transform: translateX(-50%);
      z-index: 2;
      width: 100%;

      @include media($md) {
        display: none;
      }
    }

    .swiper-pagination-bullets {
      margin-top: 0;

      .swiper-pagination-bullet {
        width: 1rem;
        height: 1rem;
        background-color: $air-blue;
        border: 1px solid rgba(96, 91, 255, 0.2);
        margin: 0;
        opacity: 1;

        &:not(:last-child) {
          margin: 0 1.5rem 0 0;
        }

        &::after {
          display: none;
        }

        &-active {
          background-color: $primary-color;
        }

        @include media($md) {
          margin: 0 0.5rem;
        }
      }
    }
  }

  &__feature-product {
    margin-bottom: 2.5rem;

    @include media($md) {
      margin-bottom: 3.5rem;
    }

    &.rte {
      p {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5;
        color: $black;
      }
    }
  }

  .product-multipack {
    width: 100%;

    &__wrap {
      display: flex;
      flex-direction: column;
      gap: .8rem;
    }

    .radio-button {
      $self: &;
      color: $black;

      &:last-child {
        .radio-button__input {
          &:checked {
            + .radio-button__label {
              .radio-button__info-wrap {
                color: $primary-color;
              }
            }
          }
        }
      }

      &__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: .8rem;
        padding: 1rem 1.6rem 1rem 4.4rem;
        border-radius: 1.2rem;
        border: 2px solid $air-blue;
        min-height: 5.4rem;

        &:before {
          left: 16px;
          border: 2px solid #8B9DA9;
          width: 1.7rem;
          height: 1.7rem;
        }

        @include media($md) {
          min-height: 6.4rem;
        }
      }

      &__input {
        &:checked {
          + .radio-button__label {
            border: 2px solid $primary-color;

            &:before {
              border-color: $primary-color;
              background-color: $primary-color;
              box-shadow: 0 0 0 2.5px $white inset;
              background-image: none;
            }

            .offertag {
              background-color: $primary-color;
              color: #fff;
            }
          }
        }
      }

      &__info-wrap {
        display: flex;
        align-items: center;
        gap: .8rem;
      }

      &__info {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.1;

        @include media($md) {
          font-size: 1.6rem;
        }
      }
    }

    .offertag {
      font-size: 1.2rem;
      padding: .4rem .8rem;
      transform: none;
    }

    .counter {
      .input {
        max-width: 10.1rem;
        padding: .5rem 1.2rem;
        border-radius: .8rem;
        border-color: #43426126;
        box-shadow: none;
        font-size: 1.4rem;
        font-weight: 400;
      }
    }

    &__btn {
      margin-top: .8rem;
      min-height: 6.4rem;
      font-size: 1.6rem;
    }

    &--v2 {
      .radio-button__input {
        &:checked {
          + .radio-button__label {
            .radio-button__info-wrap {
              color: $primary-color;
            }
          }
        }
      }
    }
  }

  .is-promo-on {
    .offertag {
      background-color: #F8F9FB;
    }
  }
}

.label-price {
  display: flex;
  gap: .3rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
}

.product-features {
  &__item {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

@media (min-width: 768px) {
   .product-detail__bottom-carousel {
     grid-template-columns: repeat(4, minmax(0, 1fr));
     gap: 2.5rem;
     box-sizing: border-box;
     transform: none !important;
   }
}

@media (max-width: 767px) {
  .product-detail__bottom-carousel-wrap {
    display: none;
  }
}

.product-feature {
  $self: &;

  display: flex;
  align-items: center;

  &__ben-icon {
    width: 1.8rem;
    height: 1.8rem;
    color: $primary-color;
    margin-right: 0.8rem;
    flex: none;
    align-self: flex-start;
  }

  &__text {
    font-size: 1.4rem;
    line-height: 1.2;
    color: $black;
    margin-right: 0.5rem;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__total-details {
    font-size: 1.2rem;
  }
}

.product-detail-carousel {
  $self: &;

  position: relative;
  z-index: 10;
  border-radius: 3.2rem;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid $air-blue;
  overflow: hidden;

  &__youtube {
    width: 100%;
  }

  &__img-wrap,
  &__video-wrap {
    width: 100%;
    position: relative;
    display: block;
    user-select: none;
    cursor: pointer;

    border-radius: 3.2rem;
    background-color: $white;

    &::before {
      content: '';
      width: 100%;
      display: block;
      padding-top: 100%;
    }
  }

  .swiper-wrapper {
    align-items: center;
  }

  &__preview {
    position: relative;
  }

  &__img,
  &__video,
  iframe {
    width: 100%;
    height: 100%;
    max-height: inherit;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__preview-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #30414c;
    transform: translate3d(-50%, -50%, 0);
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: #605bff;
    }
  }

  &__video-wrap {
    &:hover {
      #{$self}__btn-play {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }

  &__btn-play {
    background-color: $white;
    border-color: $primary-color;
    width: 8rem;
    height: 8rem;
  }

  .btn-play__icon {
    color: $white;
  }
}

.product-detail-bottom-carousel {
  $self: &;

  position: relative;
  display: none;

  @include media($md) {
    display: grid;
  }

  &__img-wrap {
    width: 100%;
    position: relative;
    border-radius: 1.6rem;
    border: 2px solid transparent;
    background-color: #f8f9fb;
    overflow: hidden;

    &::before {
      content: '';
      width: 100%;
      display: block;
      padding-top: 100%;
    }

    &:not(.active) {
      cursor: pointer;
    }

    &.active {
      border: 2px solid #21D7FF;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    backface-visibility: hidden;
    // border-radius: inherit;
  }

  &__btn-play {
    background-color: #ef4e4c;
    border-color: #ef4e4c;
    width: 2rem;
    height: 2rem;
  }

  .btn-play__icon {
    color: $white;
    width: 1rem;
    height: 1rem;
  }

  &.swiper {
    .swiper-slide {
       transition: all 0.3s linear;
      border: none;

      &:not(.swiper-slide-thumb-active) {
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }

  }
  
  &.is-grid {
    @include media($md) {
      display: flex;
    }
  }
}

.offer {
  font-size: 1.3rem;

  @include media($md) {
    font-size: 1.4rem;
  }

  span {
    font-weight: 600;
  }

  p {
    display: inline-block;
  }

  &__offer-logo-wrap {
    display: inline-block;
  }

  &__offer-logo {
    margin: 0 .5rem;
    vertical-align: text-bottom;
  }
}

.product-detail-section {
  position: relative;
  z-index: 1;

  &__decor {
    position: static;
    bottom: auto;

    height: 6rem;

    @include media($md) {
      height: 10rem;
    }

    @include media($xxl) {
      height: 14rem;
    }
  }
}

.btn-play {
  background-color: #ef4e4c;
  border-color: #ef4e4c;
}

.modal-win {
  .product-detail-carousel__img-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    @include media($md) {
      padding: 10rem 2rem;
    }

    .product-detail-carousel__img-wrap {
      max-width: 100%;
      height: 100%;
    }
  }

  &__main--carousel {
    border-radius: 1.6rem 1.6rem 0 0;
    padding: 9rem 0 2.5rem;
    transition: transform 0.3s ease-in-out;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    opacity: 1;
    min-height: 58rem;

    position: absolute;
    bottom: 0;

    @include media($xs) {
      max-width: 80%;
      position: relative;
      height: calc(100% - 1rem);
      margin-top: 1rem;
    }
    
    @include media($md) {
      padding: 0;
      border-radius: 2.4rem 2.4rem 0 0;
      max-width: 94rem;
    }

    .modal-content,
    .modal-win__body {
      height: 100%;
    }

    .product-detail__promo {
      display: grid;
      max-width: 100%;
      margin: 0;
      height: 100%;
      align-items: center;
      
      @include media($md) {
        grid-template-columns: 11.5rem 1fr 11.5rem;
        min-height: 70rem;
        background: #fff;
        border-radius: 2.5rem 2.5rem 0 0;
      }
    }

    .product-detail-carousel {
      border: none;
      box-shadow: none;
    }

    .product-detail__bottom-carousel {
      padding: 2.5rem 0 2.5rem 2.5rem;
      display: flex;
      order: 1;
      
      @include media($md) {
        padding: 2rem;
        order: 0;
      }
    }

    .product-detail-bottom-carousel__img-wrap {
      border-radius: 0.8rem;

      @include media($md) {
        border-radius: 1.6rem;
      }
    }

    .product-detail-carousel__img-wrap, 
    .product-detail-carousel__video-wrap {
      -webkit-user-drag: none;
      user-select: none;
      touch-action: none;

      @include media($md, false) {
        max-width: 36rem;
        margin: 0 auto;
      }
    }

    .product-detail__carousel {
      border-top: 1px solid #F0F2F7;
      border-bottom: 1px solid #F0F2F7;
      //padding-top: 2rem;
      //padding-bottom: 2rem;
      border-radius: 0;
      
      @include media($md) {
        border-left: 1px solid #F0F2F7;
        border-right: 1px solid #F0F2F7;
        padding-left: 2rem;
        padding-right: 2rem;

        .swiper-slide {
          display: flex;flex-direction: column; justify-content: center;
        }

        .product-detail-carousel__video-wrap {
          max-height: 100%;
        }
      }
    }

    .swiper {
      margin: 0;
    }

    .swiper-wrapper {
      display: flex !important;
    }

    .product-detail__bottom-carousel,
    .product-detail__carousel {
      @include media($md) {
        height: 100%;
      }
    }

    .product-detail__navigation-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2.5rem;
      order: 2;
      padding: 0 2.4rem;
      
      @include media($md) {
        justify-content: center;
        flex-direction: column;
      }
    }

    .product-detail__btn-prev,
    .product-detail__btn-next,
    .modal-win__close {
      padding: 1.1rem;
      border: 1px solid $primary-color;
      border-radius: 0.8rem;
      display: flex;
      transition: all 0.3s linear;
      cursor: pointer;
      
      @include media($md) {
        border-radius: 1.2rem;
        padding: 1.5rem;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    .product-detail__icon-prev,
    .product-detail__icon-next,
    .modal-win__close-icon {
      color: $primary-color;
      width: 1.6rem;
      height: 1.6rem;
      
      @include media($md) {
        width: 1.8rem;
        height: 1.8rem;
      }
    }

    .product-detail__icon-prev,
    .product-detail__icon-next {
      @include media($md, false) {
        transform: rotate(-90deg);
      }
    }

    .swiper-button-disabled {
      display: flex;
      opacity: 0.3;
      cursor: default;

      &:hover {
        transform: scale(1);
      }
    }

    .modal-win__close {
      padding: 1.9rem;
      right: 2.4rem;
      top: 2.4rem;
      border-color: rgb(96, 91, 255, 0.3);
      
      &-icon {
        fill: $primary-color;
        width: 1.1rem;
        height: 1.1rem;
      }
      
      @include media($md) {
        right: 3.25rem;
        padding: 2.4rem;
      }

      &:hover {
        transform: translate(0)
        scale(1.05);
        
        .modal-win__close-icon {
          fill: $primary-color;
        }
      }
    }
  }
}

@keyframes slideUp {
  from {
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0%, 0);
  }
}

@keyframes slideDown {
  from {
    transform: translate3d(0, 0%, 0);
  }

  to {
    transform: translate3d(0, 100%, 0);
  }
}

.swiper-free-mode>.swiper-wrapper{transition-timing-function:ease-out;margin:0 auto}

@include media($md) {
  .modal-win__main--carousel .product-detail__bottom-carousel {
    height: auto;
    align-self: stretch;
    min-height: 0;
  }

  .modal-win__main--carousel,
  .modal-win__main--carousel .product-detail__promo {
    min-height: 0 !important;
  }

  .modal-win__main--carousel .product-detail__promo .swiper-slide {
    height: 7.1rem;
  }
}

@media (max-height: 479px) and (min-width: 768px) {
  .modal-win__main--carousel .product-detail__navigation-wrap {
    align-self: end;
    padding-bottom: 2rem;
  }
}