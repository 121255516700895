.symptoms-block {
  &__header {
    margin-bottom: 3.5rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem;


    @include media($md) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 3.5rem;
    }
  }
}

.symptoms-item {
  display: flex;
  align-items: center;

  &__icon {
    width: 3.6rem;
    height: 3.6rem;
    display: block;
    object-fit: cover;
    flex: none;
    margin-right: 1rem;

    @include media($md) {
      width: 4.8rem;
      height: 4.8rem;
      margin-right: 1.5rem;
    }
  }

  &__title {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.5;

    @include media($md) {
      font-size: 1.4rem;

    }
  }
}