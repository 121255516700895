/** 
 * Aka Helpers
 */
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.fluid-embed {
  background: #f5f5f5;

  &::before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
/* Misc Global Classes
------------------------------------------ */

/**
 * Animation UTILS
 */
.fade-in {
  animation: fadeIn 1000ms forwards;
}

.fade-out {
  animation: fadeOut 1000ms forwards;
}