.featured-media {
  overflow: hidden;

  @include media($md, false) {
    margin-right: -1.5rem;

    .section-header {
      padding-right: 1.5rem;
    }

    .swiper-slide {
      &:last-child {
        padding-right: 1.5rem;
      }
    }
  }

  .section__header {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    
    @include media(370px) {
      max-width: 100%;
      margin-bottom: 5rem;
    }
  }

  &__controls {
    margin-top: 5rem;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  &__btn {
    display: flex;
    align-items: center;
  }

  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;

    &:not(.swiper-slide-visible) {
      opacity: 0;
      visibility: hidden;
    }

    .media-card {
      height: 100%;
    }
  }

  // .swiper-pagination {
  //   position: absolute;
  //   left: 50%;
  //   bottom: 1rem;
  //   transform: translateX(-50%);
  //   z-index: 2;

  //   @include media($md) {
  //     display: none;
  //     bottom: 1.5rem;
  //   }
  // }

  // .swiper-pagination-bullets {
  //   margin-top: 3rem;

  //   .swiper-pagination-bullet {
  //     width: 1rem;
  //     height: 1rem;
  //     border: none;
  //     background-color: $primary-color;
  //     border: none;
  //     opacity: 0.3;
  //     margin: 0 0.2rem;

  //     &::after {
  //       display: none;
  //     }
  
  //     &-active {
  //       opacity: 1;
  //     }

  //     @include media($md) {
  //       margin: 0 0.5rem;
  //     }
  //   }
  // }

  // .swiper-pagination {
  //   position: absolute;
  //   left: 50%;
  //   bottom: 1rem;
  //   transform: translateX(-50%);
  //   z-index: 2;

  //   @include media($md) {
  //     display: none;
  //     bottom: 1.5rem;
  //   }
  // }

  // .swiper-pagination-bullets {
  //   margin-top: 3rem;

  //   .swiper-pagination-bullet {
  //     width: 1rem;
  //     height: 1rem;
  //     border: none;
  //     background-color: $primary-color;
  //     border: none;
  //     opacity: 0.3;
  //     margin: 0 0.2rem;

  //     &::after {
  //       display: none;
  //     }
  
  //     &-active {
  //       opacity: 1;
  //     }

  //     @include media($md) {
  //       margin: 0 0.5rem;
  //     }
  //   }
  // }
}