.counter  {
  position: relative;
  color: $black;
  border-radius: 1.2rem;
  // overflow: hidden;
  // border: 1px solid rgba(67, 66, 97, 0.15);


  &__btn {
    width: 1.4rem;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    color: $dark-accent--default;

    @include media($md) {
      width: 1.6rem;
    }

    &-icon {
      transition: all .3s;
      width: 1.6rem;
      height: 1.6rem;
      fill: currentColor;
      
      @include media($md) {
        width: 1.8rem;
        height: 1.8rem;
      }

      @include absolute-center;
    }

    &--remove {
      left: 1.2rem;
    }

    &--add {
      right: 1.2rem;
    }
  }

  .input {
    text-align: center;
    font-weight: 600;
    padding: 0.9rem 3rem;
    font-size: 1.4rem;
    
    @include media($md) {
      padding: 1.3rem 3rem;
      font-size: 1.6rem;
    }

    &[readonly] {
      pointer-events: none;
    }
  }
}

