.filter-dropdown {
  &__dropdown {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    @include media($md) {
      padding: 1rem 2rem;
      width: auto;
    }
  }

  &__icon {
    width: 1rem;
    height: 1rem;
    margin-left: 1rem;
    transition: all 0.3s linear;
    flex: none;
  }

  &__body {
    position: absolute;
    top: calc(100% + 1.2rem);
    right: 0;
    background: #FFFFFF;
    box-shadow: 0 .5rem 2rem rgba(0, 0, 0, 0.05);
    width: 100%;
    z-index: 2;
    max-height: 38rem;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__list {
    padding: 2rem 1.5rem;
  }

  &__item {
    &.is-active {
      .filter-dropdown__link {
        font-weight: 600;
        color: $primary-color;
      }

      .filter-dropdown__check-icon {
        opacity: 1;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    padding: 1rem 0;
    transition: all 0.3s linear;
    color: $gray;

    > *:first-child {
      margin-right: 1rem;
    }

    &:hover {
      color: $primary-color;
      font-weight: 600;
    }
  }

  // &__text {
  //   white-space: nowrap;
  // }

  &__check-icon {
    width: 1.5rem;
    height: 1.5rem;
    color: $primary-color;
    opacity: 0;
    flex: none;
  }
}