.product-card {
  $self: &;

  max-width: 48rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 2.4rem;
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.05);
  border: 1px solid transparent;
  transition: all 0.3s linear;
  overflow: hidden;
  padding-top: 3rem;

  @include media($md) {
    max-width: 100%;
    margin: 0;
  }
  
  &:hover {
    border: 1px solid $purple;
    box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.1);

    .product-card__title-link {
      color: $primary-color;
    }
  }

  &__discount {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__img-wrap {
    position: relative;
    width: 100%;
    z-index: 0;

    &::before {
      content: '';
      padding-top: 52%;
      display: block;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s linear;
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $white;
    padding: 1rem 2rem 1.7rem 2rem;

    @include media($md) {
      padding: 1rem 2rem 4.5rem;
    }

    @include media($xl) {
      padding: 1rem 4.5rem 4.5rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;

    + #{$self}__content {
      flex: none;
    }
  }

  &__badge {
    align-self: flex-start;
    margin-bottom: 1.5rem;
  }

  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.3;
    margin-bottom: 1rem;
    max-width: 38rem;
    color: $black;
    display: block;
    
    @include media($md) {
      font-size: 2.4rem;
    }

    @include media($xl) {
      font-size: 2.8rem;
    }
  }

  &__title-link {
    color: inherit;
    transition: all 0.2s linear;
    display: inline-block;
  }

  &__description {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    color: $gray;
    max-width: 44rem;
    
    @include media($md) {
      font-size: 1.6rem;
      margin-bottom: 1.5rem;
    }
    
    @include media($xl) {
      font-size: 1.8rem;
    }
  }

  &__price {
    font-family: var(--codec-pro-font), Arial, sans-serif;
    font-weight: 500;
    font-size: 2.1rem;
    line-height: 1.2;
    margin-bottom: 2rem;
    color: $primary-color;
    white-space: nowrap;

    @include media($md) {
      font-size: 1.8rem;
    }

    @include media($xl) {
      font-size: 2.3rem;
    }
  }

  &__controls {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    > * {
      min-width: 0;
      flex: 1;
    }
  }

  &__btn-view {
    margin-top: 1.5rem;
  }

  &__list {
    margin-bottom: 2.4rem;

    @include media($md) {
      margin-bottom: 3.5rem;
    }
  }

  &__compare {
    font-size: 1.4rem;
    color: #30414C;

    margin-bottom: 2.5rem;

    @include media($md) {
      margin-bottom: 3.5rem;
      font-size: 1.6rem;
    }

    > * {
      margin-bottom: .5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    > p {
      &:first-child {
        font-size: 1.4rem;

        @include media($md) {
          font-size: 1.6rem;
        }

        @include media($xl) {
          font-size: 1.8rem;
        }

        @include media($md) {
          min-height: 12.5rem;
        }
      }
    }

    u {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.4;
      color: #30414C;
      margin-bottom: 1.5rem;
      padding: 0.4rem 1rem;
      background-color: #F0F2F7;
      border-radius: 0.6rem;
      display: inline-block;
      text-decoration: none;

      @include media($md) {
        margin-bottom: 2.5rem;
      }
    }

    ul {
      color: #50585E;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      li {
        position: relative;

        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }

        &::before {
          display: none;
        }

        &::after {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4983 0.939451C13.8079 1.21464 13.8358 1.68869 13.5606 1.99828L5.56056 10.9983C5.41824 11.1584 5.21424 11.25 5.00001 11.25C4.78578 11.25 4.58178 11.1584 4.43945 10.9983L0.439451 6.49828C0.164263 6.18869 0.192148 5.71464 0.501735 5.43945C0.811322 5.16426 1.28538 5.19215 1.56056 5.50173L5.00001 9.37111L12.4395 1.00174C12.7146 0.692148 13.1887 0.664263 13.4983 0.939451Z' fill='%2343BF84'/%3E%3C/svg%3E%0A");
          background-size: contain;
          background-repeat: no-repeat;
          width: 1.4rem;
          height: 1rem;
          position: absolute;
          top: 0.6rem;
          left: 0.5rem;
        }
      }
    }
  }

  &.product-card--row {
    @include media($md) {
      flex-direction: row;
    }

    #{$self}__img-wrap {
      @include media($md) {
        order: 1;
      }
    }

    #{$self}__main {
      @include media($md) {
        max-width: 48rem;
        width: 100%;
      }
    }

    #{$self}__title {
      font-size: 2.5rem;

      @include media($md) {
        font-size: 4rem;
      }
    }

    #{$self}__controls {
      //max-width: 23rem;
      width: 100%;
      margin: 0 auto;

      @include media($md) {
        max-width: 31.8rem;
        margin: 0;
      }
    }

    #{$self}__btn {
      @include media($md) {
        min-height: 4.8rem;
      }
    }

    .card-price {
      align-items: center;

      &__old-price {
        font-size: 1.9rem;

        @include media($md) {
          font-size: 3.4rem;
        }
      }

      &__price {
        font-size: 2.5rem;

        @include media($md) {
          font-size: 4rem;
        }
      }
    }

    .product-content {
      display: flex;
      flex-wrap: wrap;

      &__item {
        &:not(:last-child) {
          margin-right: 1.2rem;
        }
      }
    }
  }
}

.product-content {
  color: $gray;
  font-size: 1.3rem;

  @include media($md) {
    font-size: 1.4rem;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__description {
    display: flex;
    align-items: center;
    padding-left: 2.5rem;
    position: relative;

    &::before {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.86084L4 6.86084L10 0.86084' stroke='%2343BF84' stroke-width='2'/%3E%3C/svg%3E%0A");
      background-size: 1.5rem;
      position: absolute;
      top: 60%;
      left: 0.3rem;
      transform: translateY(-50%);
    }
  }
}

.features-list {
  padding: 2.5rem 0;
  
  @include media($md) {
    padding: 3.5rem 0;
  }

  &__title {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.4;
    color: $black;
    margin-bottom: 1.5rem;
    padding: 0.4rem 1rem;
    background-color: $air-blue;
    border-radius: 0.6rem;
    display: inline-block;
    
    @include media($md) {
      margin-bottom: 2.5rem;
    }
  }

  &__subtitle {
    color: $black;
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    
    @include media($md) {
      font-size: 1.4rem;
    }
  }

  &__items {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__item {
    display: flex;
    color: $gray;
    font-size: 1.3rem;
    
    @include media($md) {
      font-size: 1.4rem;
    }

    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }

  &__icon {
    width: 1.2rem;
    height: 1.2rem;
    color: $success;
    margin-right: 0.5rem;
    flex: none;
    margin-top: 0.4rem;
    
    @include media($md) {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
}

.card-price {
  display: flex;
  align-items: flex-end;
  gap: .8rem;
  flex-wrap: wrap;

  &__old-price {
    color: $gray;
    font-size: 1.6rem;
    font-weight: 500;
    text-decoration: line-through;

    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__price {
    font-weight: 500;
  }
}
