.research-tabs {
  padding-top: 0;

  &__header {
    margin-top: 2rem;
    // margin-bottom: 2.5rem;

    @include media($md) {
      margin-top: 3.5rem;
      // margin-bottom: 2rem;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
  }

  &__item {
    display: flex;
    align-items: flex-end;

    @include media($md) {
      flex-grow: 0;
    }
  }

  &__link {
    padding: 1rem 1rem;
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.2;
    opacity: 0.7;
    color: #6D7880;
    position: relative;
    text-align: center;
    flex: 1;

    @include media($md) {
      padding: 1.4rem 3.35rem;
      font-size: 1.8rem;
    }

    &::after {
      content: '';
      width: 0;
      height: 0.3rem;
      background-color: $primary-color;
      position: absolute;
      left: 50%;
      bottom: 0;
      transition: all 0.3s ;
      transform: translateX(-50%);
      opacity: 0;
    }

    &:hover {
      color: $black;

      &::after {
        opacity: 1;
        width: 100%;
      }
    }

    &.is-active {
      color: $black;

      &::after {
        opacity: 1;
        width: 100%;
      }
    }
  }

  &__tab-pane {
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: all 0.3s linear;
    overflow: hidden;

    &.is-active {
      overflow: visible;
      opacity: 1;
      visibility: visible;
      height: auto;
    }
  }

  .section {
    &:last-child:not(.featured-media) {
      padding-bottom: 0;
    }
  }
}
