.informer-section {
  &__main {
    background: #F8F9FB;
    border-radius: 3.2rem;
    display: grid;
    gap: 2.5rem;

    @include media($md) {
      gap: 3rem;
      grid-template-columns: minmax(0, 55%) minmax(0, 1fr);
    }
  }

  &__info {
    padding: 2.5rem;

    @include media($md) {
      align-self: center;
      padding: 7.2rem 0 7.2rem 10.2rem;
    }
  }

  &__preview {
    padding: 0 2.5rem;
    
    @include media($md) {
      padding: 2.5rem 2.5rem 0;
    }
  }

  &__preview-wrap {
    position: relative;
    width: 100%;
    height: 100%;

    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;

    @include media($md) {
      max-width: none;
      margin: 0;
    }

    &::before {
      content: '';
      padding-top: 100%;
      display: block;

      @include media($md) {
        display: none;
      }
    }
  }

  &__preview-img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: contain;
    object-position: bottom;
  }

  &__heading {
    margin-bottom: 1rem;
    font-size: 2.2rem;
    font-weight: 500;
    font-family: var(--codec-pro-font);
    line-height: 1.2;
    color: #30414C;

    @include media($md) {
      margin-bottom: 1.5rem;
      font-size: 3.4rem;
    }
  }

  &__content {
    font-size: 1.6rem;
    font-weight: 300;
    color: rgba(47, 43, 67, 0.60);

    @include media($md) {
      font-size: 1.9rem;
    }

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__bottom {
    margin-top: 2.5rem;

    @include media($md) {
      margin-top: 4rem;
    }
  }

  &__btn {
    white-space: normal;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.section {
  &.informer-section {
    padding: 0;
    margin: 4.2rem 0;
  }
}