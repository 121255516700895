.invitation-popup {
  display: flex;
  flex-direction: column;

  @include media($md) {
    flex-direction: row;
  }

  &__content {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media($md) {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 3rem 6rem;
    }
  }

  &__title-secondary {
    font-family: var(--codec-pro-font), Arial, sans-serif;
    font-size: 2.8rem;
    font-weight: 500;
    line-height: 1.2;

    @include media($md) {
      font-size: 5.6rem;
      font-weight: 600;
    }

    @include media($lg) {
      font-size: 7.2rem;
    }

    &:not(:last-child) {
      margin-bottom: 1.2rem;
    }
  }

  &__rte-content {
    &:not(:last-child) {
      margin-bottom: 1rem;

      @include media($md) {
        margin-bottom: 2rem;
      }
    }

    &.rte {
      b {
        font-size: 3.2rem;
        font-weight: 600;
        line-height: 1.1;
      }

      h1,h2,h3,h4,h5,h6 {
        font-size: 2.8rem;
        font-weight: 500;
        line-height: 1.3;
        margin-bottom: 1.5rem;

        @include media($md) {
          font-size: 3.4rem;
          margin-bottom: 3rem;
        }
      }

      ul {
        li {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 1.4;
          color: $black;
          position: relative;
          padding-left: 3rem;

          &:before {
            display: none;
          }

          &:after {
            content: '';
            width: 2rem;
            height: 2rem;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 2C6.08172 2 2.5 5.58172 2.5 10C2.5 14.4183 6.08172 18 10.5 18C14.9183 18 18.5 14.4183 18.5 10C18.5 5.58172 14.9183 2 10.5 2ZM0.5 10C0.5 4.47715 4.97715 0 10.5 0C16.0228 0 20.5 4.47715 20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97715 20 0.5 15.5228 0.5 10ZM15.1644 6.75259C15.5771 7.11951 15.6143 7.75158 15.2474 8.16436L9.91408 14.1644C9.72431 14.3779 9.4523 14.5 9.16667 14.5C8.88103 14.5 8.60903 14.3779 8.41926 14.1644L5.75259 11.1644C5.38567 10.7516 5.42285 10.1195 5.83564 9.75259C6.24842 9.38567 6.88049 9.42285 7.24741 9.83564L9.16667 11.9948L13.7526 6.83564C14.1195 6.42285 14.7516 6.38567 15.1644 6.75259Z' fill='%2343BF84'/%3E%3C/svg%3E%0A");
            position: absolute;
            object-fit: contain;
            top: .1rem;
            left: 0;
          }

          &:not(:last-child) {
            margin-bottom: 1.2rem;
          }
        }
      }
    }
  }

  &__bottom {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
  }

  &__btn {
    border-bottom-color: #434261;
  }

  &__agreement {
    margin-top: 3.5rem;

    &.rte {
      p {
        font-size: 1.2rem;
      }
    }
  }

  &__preview {
    flex: 0 0 100%;
    max-width: 100%;
    order: -1;
    aspect-ratio: 1 / 0.66;
    border-radius: 1rem;
    overflow: hidden;

    @include media($md) {
      aspect-ratio: 1 / 1.39;
      flex: 0 0 50%;
      max-width: 50%;
      order: 1;
      border-radius: 0;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.invitation-form {
  &__row {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__caption {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  .radio-button__label {
    color: $black;

    &:before {
      border-color: #50585E;
    }
  }

  .radio-button__input:checked + .radio-button__label::before {
    background-color: #50585E;
  }

  &__radio-buttons {
    display: flex;
    gap: 3rem;
  }

  .validated--error {
    .input {
      border: 1px solid #FF395A80;
      background-color: #FF395A0D;
    }
  }

  .error-message {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.4;
    color: #FF395A80;
    margin-top: .8rem;
    margin-left: 1.4rem;
    display: block;
  }
}

.invitation-subscribe {
  &__field {
    flex: 1;
    position: relative;
  }

  &__label {
    font-size: 16px;
    font-weight: 600;
    color: $black;
  }

  &__input {
    padding: 1.3rem 6rem 1.3rem 1.5rem;
    box-shadow: 0 -.1rem 0 0 #2F2B431A inset;
    box-shadow: 0 0 .3rem 0 #2F2B431A;

    &::placeholder {
      color: rgba($black, .7);
    }
  }

  &__btn {
    padding: 1rem 1.2rem;
    min-width: 4rem;
    min-height: 4rem;

    position: absolute;
    top: 50%;
    right: .4rem;
    transform: translate3d(0, -50%, 0);

    svg {
      fill: currentColor;
    }
  }

  .error-message {
    margin-left: 1.4rem;
  }
}

.modal-win {
  &__main-wrap {
    min-height: 100%;
    display: flex;
    align-items: center;
  }

  &__main--invitation {
    border-radius: 1rem;
    padding: 8rem 2.8rem 2.8rem 3rem;
    transition: transform 0.3s ease-in-out;
    width: 100%;
    opacity: 1;
    min-height: 58rem;
    overflow: hidden;
    background: #F8F9FB;
    max-width: 94%;

    position: relative;
    transform: translate3d(0, 100%, 0);

    @include media($md, false) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    @include media($md) {
      padding: 0;
      max-width: 96rem;
    }

    //.modal-content,
    //.modal-win__body {
    //  height: 100%;
    //}

    .modal-win__btn-close {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: auto;
      height: auto;
      padding: 0.9rem;

      position: absolute;
      top: 3.5rem;
      right: 2.8rem;

      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.4;

      @include media($md) {
        padding: 1rem 2rem 1rem 1.5rem;
      }

      span {
        display: none;

        @include media($md) {
          display: block;
        }
      }

      &-icon {
        fill: #fff;
        width: 1.3rem;
        height: 1.3rem;
        position: static;
      }
    }

    .modal-win__btn-back {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: auto;
      height: auto;
      padding: 0.9rem;

      position: absolute;
      top: 3.5rem;
      left: 2.8rem;

      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.4;

      @include media($md) {
        padding: 1rem 2rem 1rem 1.5rem;
      }

      span {
        display: none;

        @include media($md) {
          display: block;
        }
      }

      &-icon {
        fill: #fff;
        width: 1.3rem;
        height: 1.3rem;
        position: static;
      }
    }
  }
}
