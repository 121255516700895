.faq-section {
  &__main {
    border-radius: 3.2rem;
    border: 1px solid $air-blue;
    background: $white;

    padding: 3.2rem 1.5rem 4.5rem;

    box-shadow: 0 0 5rem 0 rgba($black, 0.03);

    @include media($md) {
      padding: 6.5rem 3rem;
    }
  }

  &__items {
    max-width: 98rem;
    margin: 0 auto;
    padding: 0 1rem;
    border-bottom: .1rem solid $air-blue;
    @include media($md) {
      padding: 0;
    }
  }

  .accordion {
    &.is-active {
      .accordion {
        &__title {
          &::after {
            transform: translate(0.65rem, -50%) rotate(90deg)
          }
        }
      }
    }

    &.is-hidden {
      overflow: hidden;
      line-height: 0;
    }

    &__title {
      font-family: var(--codec-pro-font);
      font-weight: 500;
      font-size: 1.6rem;
      color: $black;

      padding-right: 0;
      padding-left: 4.5rem;

      @include media($md) {
        font-size: 1.9rem;
        padding-left: 5rem;
      }

      &::before,
      &::after {
        top: 50%;

        right: auto;
        left: 1rem;

        transform: none;
        
        @include media($md) {
          top: 2.5rem;
        }
      }

      &::after {
        transform: translate(0.65rem, -50%);
        margin-top: .1rem;
      }
    }

    &__body {

      > *:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }

  p {
    font-size: 1.4rem;

    @include media($md) {
      font-size: 1.6rem;
    }

    @include media($xl) {
      font-size: 1.8rem;
    }

    strong {
      font-weight: 600;
    }
  }
}
