.article-section {
  &__cover-wrap {
    width: 100%;
    position: relative;
    margin-bottom: 2.5rem;
    overflow: hidden;
    border-radius: 1.6rem;
    
    @include media($md) {
      border-radius: 3.2rem;
      margin-bottom: 6.5rem;
    }
    
    &::after {
      content: "";
      width: 100%;
      padding-top: 36.4%;
      display: block;

      @include media($md) {
        padding-top: 33.3%; // aspect ratio 3 / 1
      }
    }
  }

  &__cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &__main {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    
    @include media($md) {
      gap: 3rem;
    }
  }

  .faq-section {
    @include media($md) {
      order: 1;
    }
  }

  &__sidebar {
    width: 100%;
    
    @include media($md) {
      flex: 1;
      min-width: 0;
    }

    > *:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }

  &__content {
    min-width: 0;
    
    @include media($md) {
      padding-top: 2.5rem;
    }

    .card-widget {
      &__desc {
        display: none;

        @include media($md) {
          display: block;
        }
      }
    }
  }
}

.blog-article {
  width: 100%;

  @include media($md) {
    flex: 0 0 65.5%;
    max-width: 65.5%;
  }

  &__suptitle {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;

    &.is-date {
      .blog-article__data {
        font-weight: 600;
        color: #50585E;
        display: flex;
        align-items: center;
        gap: .5rem;

        &::before {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 0.667969C5.96024 0.667969 6.33333 1.04106 6.33333 1.5013V2.33464H9.66667V1.5013C9.66667 1.04106 10.0398 0.667969 10.5 0.667969C10.9602 0.667969 11.3333 1.04106 11.3333 1.5013V2.33464H13.8333C14.7538 2.33464 15.5 3.08083 15.5 4.0013V14.8346C15.5 15.7551 14.7538 16.5013 13.8333 16.5013H2.16667C1.24619 16.5013 0.5 15.7551 0.5 14.8346V4.0013C0.5 3.08083 1.24619 2.33464 2.16667 2.33464H4.66667V1.5013C4.66667 1.04106 5.03976 0.667969 5.5 0.667969ZM4.66667 4.0013H2.16667V6.5013H13.8333V4.0013H11.3333V4.83464C11.3333 5.29487 10.9602 5.66797 10.5 5.66797C10.0398 5.66797 9.66667 5.29487 9.66667 4.83464V4.0013H6.33333V4.83464C6.33333 5.29487 5.96024 5.66797 5.5 5.66797C5.03976 5.66797 4.66667 5.29487 4.66667 4.83464V4.0013ZM13.8333 8.16797H2.16667V14.8346H13.8333V8.16797Z' fill='%238B9DA9'/%3E%3C/svg%3E%0A");
          width: 1.6rem;
          height: 1.6rem;
          background-size: contain;
          flex: none;
        }
      }

      .blog-article__author {
        font-weight: 400;
        margin-right: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  &__author {
    margin-right: 4.5rem;
    position: relative;

    &::after {
      content: '';
      width: 0.8rem;
      height: 0.8rem;
      display: block;
      background-color: $light-green;
      border-radius: 50%;
      position: absolute;
      top: 36%;
      right: -3rem;
      transform: translateY(-50%);
    }
  }

  &__author,
  &__data {
    font-family: var(--inter-font), Arial, sans-serif;
    font-size: 1.4rem;
    line-height: 1.4;
    
    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__author {
    font-weight: 600;
    color: $gray;
  }

  &__data {
    font-weight: 400;
  }

  &__heading {
    text-align: start;
    margin-bottom: 1.5rem;
    max-width: 69rem;
    line-height: 1.2;
  }

  &__content {
    @include media($md) {
      max-width: 69rem;
    }

    &.rte {
      > p {
        &:first-child {
          font-size: 1.6rem;
          color: #000;
          font-weight: 300;
          // line-height: 1.35;
          margin-bottom: 4.5rem;
          
          @include media($md) {
            font-size: 1.9rem;
          }
          
          @include media($xl) {
            font-size: 2rem;
          }
        }
      }
    }

    .card-widget {
      margin: 3rem 0;

      a {
        text-decoration: none;
      }
      
      .btn--primary {
        color: #fff;
      }
    }
  }

  &__controls {
    margin-top: 3.5rem;
    
    @include media($md) {
      margin-top: 6.5rem;
    }
  }

  &__btn {
    display: block;
  }

  &__disclaimer {
    margin-bottom: 5rem;

    @include media($md) {
      margin-bottom: 6rem;
    }
  }

  &__symptoms-block {
    margin-bottom: 3.5rem;

    @include media($md) {
      margin-bottom: 6rem;
    }
  }

  &__informer-block {
    margin-bottom: 3.5rem;

    @include media($md) {
      margin-bottom: 8rem;
    }
  }

  &__quizz {
    margin-bottom: 4rem;

    @include media($md) {
      margin-bottom: 6rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__conversion {
    &.conversion-offer {
      display: flex;
      flex-wrap: wrap;
    }

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__about-breeds {
    &:not(:last-child) {
      margin-bottom: 5rem;
    }

    @include media($md) {
      &:not(:last-child) {
        margin-bottom: 6rem;
      }
    }
  }
}

.article-sidebar {
  $self: &;
  position: relative;
  
  &__info {
    position: relative;
    text-align: center;
    box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.03);
    border-radius: 1.6rem;
    padding: 3.5rem 0;
    margin-top: 3rem;

    @include media($md) {
      margin-top: 0;
    }
  }
}

.disclaimer {
  border-left: .4rem solid #FFC4AC;
  padding: 2.5rem;
  border-radius: .4rem;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.05);
  
  &__heading {
    font-weight: 500;
    color: #30414C;
    font-size: 1.6rem;
    font-family: var(--codec-pro-font);
    margin-bottom: .5rem;

    @include media($md) {
      font-size: 1.9rem;
    }
  }

  &__content {
    font-size: 1.4rem;
    line-height: 1.35;
    max-width: 640px;

    @include media($md) {
      font-size: 1.6rem;
    }
  }

  &__bottom {
    margin-top: 2.5rem;
  }
}

.article-intro {
  background: #F8F9FB;
  padding-bottom: 3.5rem;

  @include media($md) {
    padding-bottom: 7.2rem;
  }

  &__breadcrumbs {
    padding-top: 0;
  }

  &__main {
    display: grid;
    gap: 3rem;
    grid-template-columns: minmax(0, 1fr);

    @include media($md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  &__heading {
    line-height: 1.15;
    margin-bottom: 1rem;
  }

  &__meta {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;
    color: #50585E;
    gap: .5rem 1rem;

    @include media($md) {
      font-size: 1.6rem;
    }

    > *:not(:last-child) {
      position: relative;
      padding-right: 2rem;

      &::before {
        content: '';
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: #D1EACA;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__author {
    font-weight: 600;
  }

  &__preview {
    order: -1;
    margin-top: .5rem;

    @include media($md) {
      order: 0;
      margin-top: 0;
    }

    &-img {
      border-radius: 1.6rem;
      display: block;
      height: auto;
      width: 100%;

      @include media($md) {
        border-radius: 2.4rem;
      }
    }
  }
}