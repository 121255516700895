.about-section {
  .container-fluid {
    max-width: 51rem;

    @include media($md) {
      max-width: 95rem;
    }
  }

  .section__header {
    margin-bottom: 2rem;

    @include media($md) {
      margin-bottom: 4rem;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
  }

  &__img-col {
    flex: 0 0 100%;
    max-width: 100%;

    @include media($md) {
      flex: 0 0 48.4%;
      max-width: 48.4%;
    }
  }

  &__info {
    flex: 0 0 100%;
    max-width: 100%;

    @include media($md) {
      flex: 0 0 51.6%;
      max-width: 51.6%;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 2rem;
  
      @include media($md) {
        margin-bottom: 4rem;
      }
    }
  }

  &__suptitle {
    font-family: var(--codec-pro-font);
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.2;
    margin-bottom: 1.5rem;
  }

  &__title {
    font-family: var(--codec-pro-font);
    font-weight: 600;
    font-size: 4.8rem;
    line-height: 1;
    margin-bottom: 1.5rem;
  }

  &__description {
    font-weight: 300;
    font-size: 2rem;
    line-height: 1.2;
    color: #6D7880;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__img-wrap {
    width: 100%;
  }

  &__img {
    width: 100%;
    height: auto;
    display: block;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media($md) {
      padding-left: 12.5rem;
    }
  }

  &__info.is-order-1 {
    @include media($md) {
      padding-right: 12.5rem;
      padding-left: 0;
    }
  }
  
  a {
    font-weight: 600;
    color: $black;
  }

  strong {
    font-weight: 600;
    color: $black;
  }

  &__btn {
    align-self: flex-start;
  }
}
