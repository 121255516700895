.share {
  &__title {
    color: $gray;
    font-size: 1.3rem;
    margin-bottom: 1.2rem;
    text-align: center;
    
    @include media($md) {
      font-size: 1.4rem;
    }
  }

  &__soc-wrap {
    margin-bottom: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    
    
    @include media($md) {
      gap: 0.6rem;
      margin-bottom: 2.5rem;
    }
  } 
  
  &__soc-link {
    color: $primary-color;
    display: block;
    padding: 0.8rem;
    border: 1px solid $purple;
    border-radius: 0.8rem;
    transition: all 0.3s linear;
    
    @include media($md) {
      border-radius: 1.2rem;
      padding: 1.1rem;
    }

    &:hover {
      border-color: $primary-color;
    }
  }

  &__soc-icon {
    width: 1.4rem;
    height: 1.4rem;
    transition: all 0.3s linear;
    display: block;
    
    // @include media($md) {
    //   width: 2rem;
    //   height: 2rem;
    // }
  }
}