.subscribe {
  $self: &;

  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  border-radius: 3.2rem;
  padding: 2.5rem;
  background-color: #f8f9fb;
  
  @include media($md) {
    align-items: center;
    flex-direction: row;
    padding: 5rem 5rem 5rem 10rem;
  }

  &__badge {
    margin-bottom: 2.5rem;
  }

  &__title {
    font-family: var(--codec-pro-font), sans-serif;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 1.3;
    margin-bottom: 1.5rem;
    color: $dark-accent--default;
    text-align: center;
    
    @include media($md) {
      padding-right: 2rem;
      margin-bottom: 0;
      font-size: 2.8rem;
      flex: 0 0 50%;
      text-align: start;
      max-width: 46rem;
    }
  }

  &__form-wrap {
    @include media($md) {
      flex: 0 0 50%;
      margin-left: auto;
      max-width: 50rem;
    }
  }

  &__form {
    &:hover {
      .subscribe__field-wrap {
        border: 1px solid #ccc;
      }
    }
    
    &.is-validated {
      #{$self}__icon {
        display: block;
        margin-right: 0.5rem;

        @include media($md) {
          margin-right: 0;
        }
      }
    }

    &.is-submitting {
      opacity: 0.4;
      background-color: $air-blue;
      pointer-events: none;

      .input {
        background-color: transparent;
      }

      #{$self}__icon {
        display: block;
        color: $gray;
        margin-right: 0.5rem;
      }
    }

    &.is-submitted {
      //#{$self}__field-wrap {
      //  display: none;
      //}

      #{$self}__description {
        display: block;
      }

      #{$self}__description-subscribe {
        display: flex;
      }

      #{$self}__icon {
        display: block;
        width: 2rem;
        height: 2rem;
        margin-bottom: 0.5rem;
        
        @include media($md) {
          margin-right: 0.5rem;
          // margin-top: 0.2rem;
          margin-bottom: 0;
        }
      }
    }

    &.is-error {
      #{$self}__field-wrap {
        border: 1px solid rgba(255, 57, 90, 0.50);
        background: linear-gradient(0deg, rgba(255, 57, 90, 0.05) 0%, rgba(255, 57, 90, 0.05) 100%), #FFF;
        box-shadow: 0 -.1rem 0 0 rgba(47, 43, 67, 0.10) inset, 0 0 3rem 0 rgba(47, 43, 67, 0.10);
      }

      #{$self}__description-error {
        display: flex;
      }

      .input {
        background-color: transparent;
      }
    }

    &.is-error-process {
      #{$self}__description-process {
        display: flex;
      }
    }

    &.is-error,
    &.is-error-process {
      #{$self}__description {
        /*display: flex;
        margin-top: 1rem;*/
        height: 0;
        transform: translateY(1rem);
      }

      #{$self}__field {
        #{$self}__icon {
          display: none;
        }
      }

      #{$self}__icon {
        margin-right: 0.5rem;
        // margin-top: 0.2rem;
        color: $error;
        display: block;
      }
    }

    &.is-empty {
      #{$self}__btn {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }

  &__field-wrap {
    padding: 0.4rem;
    border-radius: 1.6rem;
    border: 1px solid $air-blue;
    background: $white;
    box-shadow: 0 -0.1rem 0 0 rgba(47, 43, 67, 0.10) inset, 0 0.1rem 0.3rem 0 rgba(47, 43, 67, 0.10);
    text-align: center;
    transition: border-color .3s ease-in-out;
    display: flex;
    flex-wrap: wrap;
    
    &:focus-within {
      border-color: #ccc;
    }
  }

  &__field {
    display: flex;
    align-items: center;
    text-align: center;
    flex: 1;

    @include media ($md) {
      margin-right: 1rem;
    }
  }
  
  &__input {
    font-size: 1.4rem;
    padding: 0.9rem 1.1rem;
    text-align: center;
    box-shadow: none;
    border-color: transparent;

    &::placeholder {
      opacity: 0.8;
    }

    @include media($md) {
      text-align: left;
      font-size: 1.6rem;
    }
  }

  &__icon {
    width: 1.6rem;
    height: 1.6rem;
    color: #43BF84;
    flex: none;
    transition: all 0.3s linear;
    display: none;

    @include media($md) {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__description {
    color: $gray;
    font-size: 1.4rem;
  }

  &__description-error,
  &__description-process,
  &__description-subscribe {
    display: none;
  }

  &__description-error,
  &__description-process {
     margin-left: 1.5rem;
  }

  &__description-subscribe {
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include media($md) {
      text-align: start;
      flex-direction: row;
      align-items: normal;
    }
  }

  &__btn {
    width: 100%;
    padding: 1rem 2.5rem;
    
    @include media($md) {
      width: auto;
    }
  }

  &.is-gray {
    background: #F8F9FB;
  }

  &.subscribe--sm {
    .is-submitted {
      .subscribe__title {
        @include media($md) {
          padding-top: 0;
        }
      }

      .subscribe__icon {
        @include media(360px) {
          margin-right: 0.5rem;
          margin-bottom: 0;
        }
      }
    }
  }

  &.is-secondary {
    display: flex;

    @include media($md) {
      padding-left: 6rem;
    }

    #{$self}__info {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 2rem;
      margin-bottom: 3.5rem;
      text-align: center;

      @include media($md) {
        gap: 3.5rem;
        text-align: left;
        margin-bottom: 0;
        flex-direction: row;
        max-width: 49rem;
      }
    }

    #{$self}__img {
      flex: none;
      width: 3.6rem;

      @include media($md) {
        width: 5rem;
      }
    }

    #{$self}__title {
      line-height: 1.15;
      font-size: 1.8rem;
      margin-bottom: .5rem;

      @include media($md) {
        font-size: 2.3rem;
      }
    }
    
    #{$self}__desc {
      color: #50585E;
      font-size: 1.4rem;
      max-width: 32rem;
      margin: 0 auto;

      @include media($md) {
        font-size: 1.6rem;
        margin: 0;
      }
    }

    #{$self}__form-wrap,
    #{$self}__field-wrap {
      @include media($md) {
        max-width: 56rem;
      }
    }

    #{$self}__input {
      text-align: left;
    }

    #{$self}__btn,
    #{$self}__btn-load {
      padding-left: 0;
      padding-right: 0;
      min-width: 4rem;
      width: auto;

      svg {
        fill: currentColor;

        &.is-stroked {
          fill: none;
          stroke: currentColor;
        }
      }
    }

    .is-submitting {
      .subscribe {
        &__field-wrap {
          box-shadow: none;
          border-color: transparent;
        }
      }
    }

    .is-error {
      .subscribe__description-error,
      .subscribe__description-process {
        display: flex;
      }
    }

    .is-sent {
      pointer-events: none;

      .subscribe {
        &__field-wrap {
          border-color: rgba(67, 191, 132, .5);
          background: rgba(67, 191, 132, .05);
        }

        &__input {
          background: inherit;
        }

        &__btn {
          background: #43BF84;
        }
      }
    }
  }
}